/// IMPORTS
import {
  WorkspaceBasicGetQuery,
  WorkspaceBasicGetResponse,
  WorkspaceBasicPostBody,
  WorkspaceBasicPostResponse,
  WorkspaceBasicPutBody,
  WorkspaceBasicPutResponse,
  WorkspaceBasicDeleteQuery,
  WorkspaceBasicDeleteResponse,
  WorkspaceBasicsGetQuery,
  WorkspaceBasicsGetResponse,
  WorkspaceBasicOrderPutBody,
  WorkspaceBasicOrderPutResponse,
} from 'generatedInterfaces';
import { BasicModel } from 'src/app/classes/models/basicModel';
import { Workspace } from '../workspace';
import { Observable } from 'rxjs/internal/Observable';

export class BasicPresenter {
  /// PROPERTIES
  private model: BasicModel = new BasicModel();

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS
  public setSelectedRequested(id: string): void {
    this.model.setSelected(id);
  }
  public getSelectedRequested(): string {
    return this.model.getSelected();
  }
  public removeSelectedRequested(): void {
    this.model.removeSelected();
  }

  // workspaceBasicOrderPut
  public changeOrderRequested(
    obj: WorkspaceBasicOrderPutBody
  ): Observable<WorkspaceBasicOrderPutResponse> {
    return this.model.changeOrder(obj);
  }

  // workspaceBasicsGet
  public getManyRequested(obj: WorkspaceBasicsGetQuery): {
    local: Workspace['basic'][];
    promise: Observable<WorkspaceBasicsGetResponse>;
  } {
    return this.model.getMany(obj);
  }

  // workspaceBasicDelete
  public deleteOneRequested(
    obj: WorkspaceBasicDeleteQuery
  ): Observable<WorkspaceBasicDeleteResponse> {
    return this.model.deleteOne(obj);
  }

  // workspaceBasicPut
  public putOneRequested(
    obj: WorkspaceBasicPutBody
  ): Observable<WorkspaceBasicPutResponse> {
    return this.model.putOne(obj);
  }

  // workspaceBasicPost
  public postOneRequested(
    obj: WorkspaceBasicPostBody
  ): Observable<WorkspaceBasicPostResponse> {
    return this.model.postOne(obj);
  }

  // workspaceBasicGet
  public getOneRequested(obj: WorkspaceBasicGetQuery): {
    local: Workspace['basic'];
    promise: Observable<WorkspaceBasicGetResponse>;
  } {
    return this.model.getOne(obj);
  }

  /// CUSTOM METHODS

  ///
}
