<ng-container *ngIf="!isLoading">
  <div class="cb-dialog-form-container">
    <ng-container *ngFor="let row of form.rows">
      <div class="cb-dialog-form-row">
        <ng-container *ngFor="let field of row.columns">
          <ng-container *ngIf="field.show!==false">
            <ng-container [ngTemplateOutlet]="FormFieldTemplate" [ngTemplateOutletContext]="{ field: field }">
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="form.otherButtons && form.otherButtons.length>0">
    <div class="cb-dialog-form-buttons">
      <ng-container *ngFor="let btn of form.otherButtons">
        <ng-container *ngIf="btn.show">
          <cb-button [type]="btn.type" (click)="buttonClick(btn)">{{btn.label | translate}}</cb-button>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="showButtons">
  <ng-container *ngIf="form.deleteButton && form.deleteButton.show">
    <cb-button type="danger" (click)="deleteClick()"
      [disabled]="(form.deleteButton.disabledOnError ?? false) && hasErrors">{{form.deleteButton.label | translate}}
    </cb-button>
  </ng-container>
  <ng-container *ngIf="form.submitButton && form.submitButton.show">
    <cb-button type="success" (click)="submitClick()"
      [disabled]="(form.submitButton.disabledOnError ?? false) && hasErrors">{{form.submitButton.label | translate}}
    </cb-button>
  </ng-container>
</ng-container>

<ng-template #FormFieldTemplate let-field="field">
  <div class="cb-dialog-form-field">
    <cb-form-field [field]="field" [(ngModel)]="values[field.field]" (change)="change()"></cb-form-field>
  </div>
</ng-template>