/// IMPORTS
import {
  WorkspaceCompletitionPostBody,
  WorkspaceCompletitionPostResponse,
} from 'generatedInterfaces';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { getUrl, authApi, deleteAuthApi, objectToQueryString } from '../utils';
import { HttpService } from 'src/app/services/http.service';

export class CompletitionOnlineEndpoint {
  /// PROPERTIES
  private http: HttpClient = HttpService.instance.http;

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  // workspaceInputCompletitionPost
  public postOne(
    obj: WorkspaceCompletitionPostBody
  ): Observable<WorkspaceCompletitionPostResponse> {
    return this.http.post<WorkspaceCompletitionPostResponse>(
      getUrl('/ai/completition'),
      obj,
      authApi(true)
    );
  }

  /// CUSTOM METHODS

  ///
}
