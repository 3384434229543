/// IMPORTS
import {
  WorkspaceLinkGetQuery,
  WorkspaceLinkGetResponse,
  WorkspaceLinkPostBody,
  WorkspaceLinkPostResponse,
  WorkspaceLinkPutBody,
  WorkspaceLinkPutResponse,
  WorkspaceLinkDeleteQuery,
  WorkspaceLinkDeleteResponse,
  WorkspaceLinksGetQuery,
  WorkspaceLinksGetResponse,
  WorkspaceLinkPostsBody,
  WorkspaceLinkPostsResponse,
  WorkspaceLinkPutsBody,
  WorkspaceLinkPutsResponse,
  WorkspaceLinkDeletesQuery,
  WorkspaceLinkDeletesResponse,
  WorkspaceLinkOrderPutBody,
  WorkspaceLinkOrderPutResponse,
} from 'generatedInterfaces';
import { LinksOfflineEndpoint } from 'src/app/classes/offlineEndpoints/linksOfflineEndpoint';
import { LinksOnlineEndpoint } from 'src/app/classes/onlineEndpoints/linksOnlineEndpoint';
import { HttpErrorResponse } from '@angular/common/http';
import { Workspace } from '../workspace';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

export class LinksModel {
  /// PROPERTIES
  private onlineEndpoint: LinksOnlineEndpoint = new LinksOnlineEndpoint();
  private offlineEndpoint: LinksOfflineEndpoint = new LinksOfflineEndpoint();

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  // workspaceLinkOrderPut
  public changeOrder(
    obj: WorkspaceLinkOrderPutBody
  ): Observable<WorkspaceLinkOrderPutResponse> {
    return this.onlineEndpoint.changeOrder(obj).pipe(
      map(
        (response: WorkspaceLinkOrderPutResponse) => {
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceLinkDeletes
  public deletesMany(
    obj: WorkspaceLinkDeletesQuery
  ): Observable<WorkspaceLinkDeletesResponse> {
    return this.onlineEndpoint.deletesMany(obj).pipe(
      map(
        (response: WorkspaceLinkDeletesResponse) => {
          const objs = obj.ids.map((val) => {
            return { id: val };
          });
          for (let obj of objs)
            this.offlineEndpoint.delete(obj as Workspace['links'][0]);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceLinkPuts
  public putsMany(
    obj: WorkspaceLinkPutsBody
  ): Observable<WorkspaceLinkPutsResponse> {
    return this.onlineEndpoint.putsMany(obj).pipe(
      map(
        (response: WorkspaceLinkPutsResponse) => {
          const objs = obj.bodies;
          for (let obj of objs)
            this.offlineEndpoint.pushOrUpdate(obj as Workspace['links'][0]);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceLinkPosts
  public postsMany(
    obj: WorkspaceLinkPostsBody
  ): Observable<WorkspaceLinkPostsResponse> {
    return this.onlineEndpoint.postsMany(obj).pipe(
      map(
        (response: WorkspaceLinkPostsResponse) => {
          const objs = obj.bodies;
          for (let obj of objs)
            this.offlineEndpoint.pushOrUpdate(obj as Workspace['links'][0]);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceLinksGet
  public getMany(obj: WorkspaceLinksGetQuery): {
    local: Workspace['links'];
    promise: Observable<WorkspaceLinksGetResponse>;
  } {
    return {
      local: this.offlineEndpoint.getMany(obj).filter((item) => {
        for (let key of Object.keys(obj)) {
          if ((<any>item)[key] != (<any>obj)[key]) {
            return false;
          }
        }
        return true;
      }),
      promise: this.onlineEndpoint.getMany(obj).pipe(
        map(
          (response: WorkspaceLinksGetResponse) => {
            let mains = response.main;
            if (mains)
              this.offlineEndpoint.setEntities(mains as Workspace['links']);

            return response;
          },
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
        )
      ),
    };
  }

  // workspaceLinkDelete
  public deleteOne(
    obj: WorkspaceLinkDeleteQuery
  ): Observable<WorkspaceLinkDeleteResponse> {
    return this.onlineEndpoint.deleteOne(obj).pipe(
      map(
        (response: WorkspaceLinkDeleteResponse) => {
          this.offlineEndpoint.delete(obj as Workspace['links'][0]);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceLinkPut
  public putOne(
    obj: WorkspaceLinkPutBody
  ): Observable<WorkspaceLinkPutResponse> {
    return this.onlineEndpoint.putOne(obj).pipe(
      map(
        (response: WorkspaceLinkPutResponse) => {
          this.offlineEndpoint.pushOrUpdate(obj as Workspace['links'][0]);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceLinkPost
  public postOne(
    obj: WorkspaceLinkPostBody
  ): Observable<WorkspaceLinkPostResponse> {
    return this.onlineEndpoint.postOne(obj).pipe(
      map(
        (response: WorkspaceLinkPostResponse) => {
          this.offlineEndpoint.pushOrUpdate(obj as Workspace['links'][0]);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceLinkGet
  public getOne(obj: WorkspaceLinkGetQuery): {
    local: Workspace['links'][0];
    promise: Observable<WorkspaceLinkGetResponse>;
  } {
    return {
      local: this.offlineEndpoint.getOne(obj),
      promise: this.onlineEndpoint.getOne(obj).pipe(
        map(
          (response: WorkspaceLinkGetResponse) => {
            this.offlineEndpoint.pushOrUpdate(
              response.main as Workspace['links'][0]
            );

            return response;
          },
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
        )
      ),
    };
  }

  /// CUSTOM METHODS

  ///
}
