import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CbDialogComponent } from './cb-dialog/cb-dialog.component';
import { CbFormWidgetsModule } from '../cb-form-widgets/cb-form-widgets.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { MatDialogModule } from '@angular/material/dialog';
import { CbDialogService } from './cb-dialog-service/cb-dialog.service';
import { CbDialogConfirmComponent } from './cb-dialog-confirm/cb-dialog-confirm.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    CbDialogComponent,
    CbDialogConfirmComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    IonicModule,
    MatDialogModule,
    CbFormWidgetsModule,
    IonicModule,
  ],
  exports: [CbDialogComponent, CbDialogConfirmComponent],
  providers: [CbDialogService],
})
export class CbDialogModule { }
