import { CbFormRow } from 'src/app/widgets/cb-form/cb-form-interfaces';

export const createRows: CbFormRow[] = [
  // { columns: [{ field: "name", placeholder: "PRODUCTS.FIELDS.PLACEHOLDERS.NAME", label: "PRODUCTS.FIELDS.LABELS.NAME", type: "text", required: true }]},
  // ID FORM CREATE
  {
    columns: [
      {
        field: 'id',
        placeholder: 'LINK.FIELDS.PLACEHOLDERS.ID',
        label: 'LINK.FIELDS.LABELS.ID',
        type: 'id',
        defaultValue: undefined,
        disabled: true,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: false,
        required: true,
      },
    ],
  },
  // TITLE FORM CREATE
  {
    columns: [
      {
        field: 'title',
        placeholder: 'LINK.FIELDS.PLACEHOLDERS.TITLE',
        label: 'LINK.FIELDS.LABELS.TITLE',
        type: 'text',
        defaultValue: undefined,
        disabled: false,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: true,
        required: true,
      },
    ],
  },
  // URL FORM CREATE
  {
    columns: [
      {
        field: 'url',
        placeholder: 'LINK.FIELDS.PLACEHOLDERS.URL',
        label: 'LINK.FIELDS.LABELS.URL',
        type: 'url',
        defaultValue: undefined,
        disabled: false,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: true,
        required: false,
      },
    ],
  },
  // KEYWORDS FORM CREATE
  {
    columns: [
      // TODO MANAGE KEYWORDS ARRAY
    ],
  },
  // ORDER FORM CREATE
  {
    columns: [
      {
        field: 'order',
        placeholder: 'LINK.FIELDS.PLACEHOLDERS.ORDER',
        label: 'LINK.FIELDS.LABELS.ORDER',
        type: 'number',
        defaultValue: undefined,
        disabled: true,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: false,
        required: false,
      },
    ],
  },
  // CREATEDAT FORM CREATE
  {
    columns: [
      {
        field: 'createdAt',
        placeholder: 'LINK.FIELDS.PLACEHOLDERS.CREATEDAT',
        label: 'LINK.FIELDS.LABELS.CREATEDAT',
        type: 'text',
        defaultValue: undefined,
        disabled: true,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: false,
        required: false,
      },
    ],
  },
  // UPDATEDAT FORM CREATE
  {
    columns: [
      {
        field: 'updatedAt',
        placeholder: 'LINK.FIELDS.PLACEHOLDERS.UPDATEDAT',
        label: 'LINK.FIELDS.LABELS.UPDATEDAT',
        type: 'text',
        defaultValue: undefined,
        disabled: true,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: false,
        required: false,
      },
    ],
  },
  // DELETEDAT FORM CREATE
  {
    columns: [
      {
        field: 'deletedAt',
        placeholder: 'LINK.FIELDS.PLACEHOLDERS.DELETEDAT',
        label: 'LINK.FIELDS.LABELS.DELETEDAT',
        type: 'text',
        defaultValue: undefined,
        disabled: true,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: false,
        required: false,
      },
    ],
  },
  // ATTRIBUTES FOR CREATE FORM:
];

export const editRows: CbFormRow[] = [
  // { columns: [{ field: "name", placeholder: "PRODUCTS.FIELDS.PLACEHOLDERS.NAME", label: "PRODUCTS.FIELDS.LABELS.NAME", type: "text", required: true }]},
  // ID FORM UPDATE
  {
    columns: [
      {
        field: 'id',
        placeholder: 'LINK.FIELDS.PLACEHOLDERS.ID',
        label: 'LINK.FIELDS.LABELS.ID',
        type: 'id',
        defaultValue: undefined,
        disabled: true,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: false,
        required: true,
      },
    ],
  },
  // TITLE FORM UPDATE
  {
    columns: [
      {
        field: 'title',
        placeholder: 'LINK.FIELDS.PLACEHOLDERS.TITLE',
        label: 'LINK.FIELDS.LABELS.TITLE',
        type: 'text',
        defaultValue: undefined,
        disabled: false,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: true,
        required: true,
      },
    ],
  },
  // URL FORM UPDATE
  {
    columns: [
      {
        field: 'url',
        placeholder: 'LINK.FIELDS.PLACEHOLDERS.URL',
        label: 'LINK.FIELDS.LABELS.URL',
        type: 'url',
        defaultValue: undefined,
        disabled: false,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: true,
        required: false,
      },
    ],
  },
  // KEYWORDS FORM UPDATE
  {
    columns: [
      // TODO MANAGE KEYWORDS ARRAY
    ],
  },
  // ORDER FORM UPDATE
  {
    columns: [
      {
        field: 'order',
        placeholder: 'LINK.FIELDS.PLACEHOLDERS.ORDER',
        label: 'LINK.FIELDS.LABELS.ORDER',
        type: 'number',
        defaultValue: undefined,
        disabled: true,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: false,
        required: false,
      },
    ],
  },
  // CREATEDAT FORM UPDATE
  {
    columns: [
      {
        field: 'createdAt',
        placeholder: 'LINK.FIELDS.PLACEHOLDERS.CREATEDAT',
        label: 'LINK.FIELDS.LABELS.CREATEDAT',
        type: 'text',
        defaultValue: undefined,
        disabled: true,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: false,
        required: false,
      },
    ],
  },
  // UPDATEDAT FORM UPDATE
  {
    columns: [
      {
        field: 'updatedAt',
        placeholder: 'LINK.FIELDS.PLACEHOLDERS.UPDATEDAT',
        label: 'LINK.FIELDS.LABELS.UPDATEDAT',
        type: 'text',
        defaultValue: undefined,
        disabled: true,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: false,
        required: false,
      },
    ],
  },
  // DELETEDAT FORM UPDATE
  {
    columns: [
      {
        field: 'deletedAt',
        placeholder: 'LINK.FIELDS.PLACEHOLDERS.DELETEDAT',
        label: 'LINK.FIELDS.LABELS.DELETEDAT',
        type: 'text',
        defaultValue: undefined,
        disabled: true,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: false,
        required: false,
      },
    ],
  },
  // ATTRIBUTES FOR UPDATE FORM:
];
