import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { routes } from './routes';
import { Interceptor } from './interceptors/interceptor';
import { APP_INITIALIZER } from '@angular/core';
import { InitializerService } from './services/initializer.service';
import { HttpService } from './services/http.service';
import { AuthGuard } from './guards/auth.guard';
import { CbToastModule } from './widgets/cb-toast/cb-toast.module';
import { CbFormWidgetsModule } from './widgets/cb-form-widgets/cb-form-widgets.module';
import { Translate } from './services/translate.service';
import { CbToastService } from './widgets/cb-toast/cb-toast.service';
import { UploaderService } from './services/uploader.service';
import { CbGlobalLoaderModule } from './widgets/cb-global-loader/cb-global-loader.module';
import { PrivilegesService } from './services/privileges.service';
import { CbDialogModule } from './widgets/cb-dialog/cb-dialog.module';
import { CbUtilityWidgetsModule } from './widgets/cb-utility-widgets/cb-utility-widgets.module';
import { SharedModule } from './modules/shared/shared.module';
import { CbSidebarModule } from './widgets/cb-sidebar/cb-sidebar.module';
import { BgUtilityWidgetsModule } from './widgets/bg-utility-widgets/bg-utility-widgets.module';
import { CbIconModule } from './widgets/cb-icon/cb-icon.module';
import { FormsModule } from '@angular/forms';
import { HawkErrorHandlerService } from './services/hawk-error-handler.service';
import { TranslateCustomService } from './services/translate-custom.service';
import { NgxClarityModule } from 'ngx-clarity';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    CbFormWidgetsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    CbDialogModule,
    IonicModule.forRoot(),
    CbToastModule,
    CbGlobalLoaderModule,
    CbUtilityWidgetsModule,
    BgUtilityWidgetsModule,
    CbIconModule,
    FormsModule,
    NgxClarityModule.forRoot({
      enabled: environment.production,
      projectId: 'gfkb1cxxg6',
    }),
  ],
  providers: [
    HttpService,
    { provide: LOCALE_ID, useValue: 'it-IT' },
    InitializerService,
    {
      provide: APP_INITIALIZER,
      useFactory: (app: InitializerService) => () => app.load(),
      deps: [InitializerService],
      multi: true,
    },
    CbToastService,
    Translate,
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    TranslateCustomService,
    PrivilegesService,
    UploaderService,
    AuthGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: (app: PrivilegesService) => () => app.load(),
      deps: [PrivilegesService],
      multi: true,
    },
    { provide: ErrorHandler, useClass: HawkErrorHandlerService }],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule { }

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}