import { toDoc, toHTML } from "ngx-editor";

export function fromBlockGetText(block: any): string {
    try {
        let text = "";
        if (!block)
            return text;
        if (block.content && block.content.length > 0) {
            for (let subBlock of block.content)
                text += fromBlockGetText(subBlock);
            text += "\n";
        } else if (block.text) {
            text += block.text;
        }
        return text;
    } catch (error) {
        console.error(error);
        return "";
    }

}

export function fromBlocksGetHtml(blocks: any[]): string {
    try {

        let html = "";

        if (!blocks)
            return html;

        html = toHTML({ type: "doc", content: blocks });

        return html;
    } catch (error) {
        console.error(error);
        return "<p></p>";
    }

}

export function fromBlockGetHtml(block: any): string {
    try {

        let html = "";

        if (!block)
            return html;

        html = toHTML({ type: "doc", content: [block] })

        return html;
    } catch (error) {
        console.error(block);
        console.error(error);
        return "<p></p>";
    }

}

export const emptyBlock = {
    type: "text",
    text: "",
};
export function fromHtmlGetBlocks(html: string): any {

    try {

        const doc = toDoc(html) as any;

        if (!doc)
            return [];

        if (!doc.content)
            return [];

        return doc.content;
    } catch (error) {
        return [emptyBlock]
    }

}

