<cb-dialog [title]="title | translate" [width]="width">
  <ng-container Content>
    <cb-form [showButtons]="false" [form]="form" [data]="data"></cb-form>
  </ng-container>

  <ng-container LeftButtons>
    <ng-container *ngIf="form.deleteButton && form.deleteButton.show">
      <cb-button type="danger" (click)="deleteClick(data)"
        [disabled]="(form.deleteButton.disabledOnError ?? false) && hasErrors">{{form.deleteButton.label | translate}}
      </cb-button>
    </ng-container>
  </ng-container>
  <ng-container RightButtons>
    <ng-container *ngIf="form.submitButton && form.submitButton.show">
      <cb-button type="success" (click)="submitClick(data)"
        [disabled]="(form.submitButton.disabledOnError ?? false) && hasErrors">{{form.submitButton.label | translate}}
      </cb-button>
    </ng-container>
  </ng-container>
</cb-dialog>