/// IMPORTS
import {
  WorkspaceBasicGetQuery,
  WorkspaceBasicGetResponse,
  WorkspaceBasicPostBody,
  WorkspaceBasicPostResponse,
  WorkspaceBasicPutBody,
  WorkspaceBasicPutResponse,
  WorkspaceBasicDeleteQuery,
  WorkspaceBasicDeleteResponse,
  WorkspaceBasicsGetQuery,
  WorkspaceBasicsGetResponse,
  WorkspaceBasicOrderPutBody,
  WorkspaceBasicOrderPutResponse,
} from 'generatedInterfaces';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { getUrl, authApi, deleteAuthApi, objectToQueryString } from '../utils';
import { HttpService } from 'src/app/services/http.service';

export class BasicOnlineEndpoint {
  /// PROPERTIES
  private http: HttpClient = HttpService.instance.http;

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  // workspaceBasicOrderPut
  public changeOrder(
    obj: WorkspaceBasicOrderPutBody
  ): Observable<WorkspaceBasicOrderPutResponse> {
    return this.http.put<WorkspaceBasicOrderPutResponse>(
      getUrl('/workspace/basics/order'),
      obj,
      authApi(true)
    );
  }

  // workspaceBasicsGet
  public getMany(
    obj: WorkspaceBasicsGetQuery
  ): Observable<WorkspaceBasicsGetResponse> {
    return this.http.get<WorkspaceBasicsGetResponse>(
      getUrl('/workspace/basics') + objectToQueryString(obj),
      authApi(true)
    );
  }

  // workspaceBasicDelete
  public deleteOne(
    obj: WorkspaceBasicDeleteQuery
  ): Observable<WorkspaceBasicDeleteResponse> {
    return this.http.delete<WorkspaceBasicDeleteResponse>(
      getUrl('/workspace/basic') + objectToQueryString(obj),
      deleteAuthApi(true, obj)
    );
  }

  // workspaceBasicPut
  public putOne(
    obj: WorkspaceBasicPutBody
  ): Observable<WorkspaceBasicPutResponse> {
    return this.http.put<WorkspaceBasicPutResponse>(
      getUrl('/workspace/basic'),
      obj,
      authApi(true)
    );
  }

  // workspaceBasicPost
  public postOne(
    obj: WorkspaceBasicPostBody
  ): Observable<WorkspaceBasicPostResponse> {
    return this.http.post<WorkspaceBasicPostResponse>(
      getUrl('/workspace/basic'),
      obj,
      authApi(true)
    );
  }

  // workspaceBasicGet
  public getOne(
    obj: WorkspaceBasicGetQuery
  ): Observable<WorkspaceBasicGetResponse> {
    return this.http.get<WorkspaceBasicGetResponse>(
      getUrl('/workspace/basic') + objectToQueryString(obj),
      authApi(true)
    );
  }

  /// CUSTOM METHODS

  ///
}
