<div class="cb-input-wrapper" [class.disabled]="disabled" [class.error]="error || regexError">
  <label *ngIf="label">{{label}}</label>
  <div class="cb-input-container">
    <div #prefix class="cb-input-prefix" [class.has-children]="prefix.children.length">
      <ng-content select="[prefix]"></ng-content>
    </div>
    <div class="cb-input">
      <input [autocomplete]="autocomplete" [placeholder]="placeholder" [(ngModel)]="value" [type]="inputType"
        (input)="input()" (change)="change()" [attr.max]="max != null ? max : null"
        [attr.min]="min != null ? min : null" [attr.step]="step ? step : null" />
    </div>
    <div #suffix class="cb-input-suffix" [class.has-children]="suffix.children.length">
      <ng-content select="[suffix]"></ng-content>
    </div>

    <div class="cb-input-password-button" *ngIf="type==='password'">
      <ion-icon (click)="togglePassword()" [name]="(showPassword)? 'eye-off' : 'eye'"></ion-icon>
    </div>
  </div>

  <p class="cb-input-error" *ngIf="regexError">{{"COMMON.VALUE_NOT_VALID" | translate}}</p>
</div>