import { Injectable } from '@angular/core';
import { CbDialogOptions, CbDialogService } from '../../cb-dialog/cb-dialog-service/cb-dialog.service';
import { CbStandardForm } from '../../cb-form/cb-form-interfaces';
import { CbDialogFormComponent } from '../cb-dialog-form/cb-dialog-form.component';

@Injectable({
  providedIn: 'root'
})
export class CbDialogFormService {

  constructor(
    private readonly dialogService: CbDialogService
  ) { }

  public openFormDialog(
    form: CbStandardForm,
    {
      options,
      data,
      afterClose,
    }: {
      options?: CbDialogOptions;
      data?: any;
      afterClose?: (result: { [key: string]: any }) => void;
    } = {}
  ): void {
    this.dialogService.openDialog(
      {
        component: CbDialogFormComponent,
        data: { form: form, width: options?.width || '450px', data: data },
      },
      {
        afterClose: (result) => {
          if (afterClose) afterClose(result ?? false);
        },
      }
    );
  }
}
