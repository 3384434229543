import { Routes } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "/articles",
    pathMatch: "full",
  },
  {
    path: "home",
    redirectTo: "/articles",
    pathMatch: "full",
  },
  // AUTHENTICATION
  {
    path: 'auth',
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule),
    data: { roles: [] }
  },
  // ARTICLE
  {
    path: 'articles',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/articles/articles.module').then(m => m.ArticlesModule),
    data: { roles: [] }
  },
  // SETTINGS
  {
    path: 'subscription',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/subscription/subscription.module').then(m => m.SubscriptionModule),
    data: { roles: [] }
  },
  // SETTINGS 
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
    data: { roles: [] }
  },

  // PRODUCT
  /* {
    path: 'products',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/products/products.module').then(m => m.ProductsModule),
    data: { roles: [] }
  },
  // BASIC
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/basic/basic.module').then(m => m.BasicModule),
    data: { roles: [] }
  },
  // USER
  {
    path: 'users',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
    data: { roles: [] }
  },
  // LINK
  {
    path: 'links',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/links/links.module').then(m => m.LinksModule),
    data: { roles: [] }
  },
  // PROMPT
  {
    path: 'prompts',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/prompts/prompts.module').then(m => m.PromptsModule),
    data: { roles: [] }
  }, */
  // ADD ROUTES HERE
];
