import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

const noop = () => { };

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CbSelectComponent),
  multi: true,
};

@Component({
  selector: 'cb-select',
  templateUrl: './cb-select.component.html',
  styleUrls: ['./cb-select.component.scss', '../cb-form-style.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class CbSelectComponent implements ControlValueAccessor, OnInit {
  @Input() public appendTo?: string;
  @Input() public placeholder?: string = '';
  @Input() public label?: string = '';
  @Input() public options: { label: string; value: any }[] = [];
  @Input() public disabled: boolean = false;
  @Input() public autofocus: boolean = true;
  @Input() public error: boolean = false;
  @Input() public translateLabels: boolean = false;

  @Output('change') public onChange: EventEmitter<any> =
    new EventEmitter<any>();

  private innerValue!: { label: string; value: any };

  constructor(
    private translate:TranslateService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    if (this.translateLabels) {
      this.options = this.options.map((option) => ({
        label: this.translate.instant(option.label),
        value: option.value,
      }));
    }
  }

  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  // get accessor
  public get value(): any {
    return this.innerValue;
  }

  // Set accessor including call the onchange callback
  public set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
      this.cdr.detectChanges();
    }
  }

  // From ControlValueAccessor interface
  public writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
      this.cdr.detectChanges();
    }
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
