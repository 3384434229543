<div class="cb-input-wrapper" [class.disabled]="disabled" [class.error]="error">
  <label>{{label}}</label>
  <div class="cb-input-container">
    <div class="cb-input-prefix">
      <ng-content select="[prefix]"></ng-content>
    </div>
    <div class="cb-input">
      <p-calendar [(ngModel)]="value" [appendTo]="appendTo" dateFormat="dd/mm/yy" (change)="change()"
        [readonlyInput]="true" [placeholder]="placeholder ?? 'dd/mm/yyy'"></p-calendar>
    </div>
    <div class="cb-input-suffix">
      <ng-content select="[suffix]"></ng-content>
    </div>
  </div>
</div>
