/// IMPORTS
import {
  WorkspacePromptGetQuery,
  WorkspacePromptGetResponse,
  WorkspacePromptPostBody,
  WorkspacePromptPostResponse,
  WorkspacePromptPutBody,
  WorkspacePromptPutResponse,
  WorkspacePromptDeleteQuery,
  WorkspacePromptDeleteResponse,
  WorkspacePromptsGetQuery,
  WorkspacePromptsGetResponse,
  WorkspacePromptPostsBody,
  WorkspacePromptPostsResponse,
  WorkspacePromptPutsBody,
  WorkspacePromptPutsResponse,
  WorkspacePromptDeletesQuery,
  WorkspacePromptDeletesResponse,
  WorkspacePromptOrderPutBody,
  WorkspacePromptOrderPutResponse,
} from 'generatedInterfaces';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { getUrl, authApi, deleteAuthApi, objectToQueryString } from '../utils';
import { HttpService } from 'src/app/services/http.service';

export class PromptsOnlineEndpoint {
  /// PROPERTIES
  private http: HttpClient = HttpService.instance.http;

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  // workspacePromptOrderPut
  public changeOrder(
    obj: WorkspacePromptOrderPutBody
  ): Observable<WorkspacePromptOrderPutResponse> {
    return this.http.put<WorkspacePromptOrderPutResponse>(
      getUrl('/workspace/prompts/order'),
      obj,
      authApi(true)
    );
  }

  // workspacePromptDeletes
  public deletesMany(
    obj: WorkspacePromptDeletesQuery
  ): Observable<WorkspacePromptDeletesResponse> {
    return this.http.delete<WorkspacePromptDeletesResponse>(
      getUrl('/workspace/prompts') + objectToQueryString(obj),
      deleteAuthApi(true, obj)
    );
  }

  // workspacePromptPuts
  public putsMany(
    obj: WorkspacePromptPutsBody
  ): Observable<WorkspacePromptPutsResponse> {
    return this.http.put<WorkspacePromptPutsResponse>(
      getUrl('/workspace/prompts'),
      obj,
      authApi(true)
    );
  }

  // workspacePromptPosts
  public postsMany(
    obj: WorkspacePromptPostsBody
  ): Observable<WorkspacePromptPostsResponse> {
    return this.http.post<WorkspacePromptPostsResponse>(
      getUrl('/workspace/prompts'),
      obj,
      authApi(true)
    );
  }

  // workspacePromptsGet
  public getMany(
    obj: WorkspacePromptsGetQuery
  ): Observable<WorkspacePromptsGetResponse> {
    return this.http.get<WorkspacePromptsGetResponse>(
      getUrl('/workspace/prompts') + objectToQueryString(obj),
      authApi(true)
    );
  }

  // workspacePromptDelete
  public deleteOne(
    obj: WorkspacePromptDeleteQuery
  ): Observable<WorkspacePromptDeleteResponse> {
    return this.http.delete<WorkspacePromptDeleteResponse>(
      getUrl('/workspace/prompt') + objectToQueryString(obj),
      deleteAuthApi(true, obj)
    );
  }

  // workspacePromptPut
  public putOne(
    obj: WorkspacePromptPutBody
  ): Observable<WorkspacePromptPutResponse> {
    return this.http.put<WorkspacePromptPutResponse>(
      getUrl('/workspace/prompt'),
      obj,
      authApi(true)
    );
  }

  // workspacePromptPost
  public postOne(
    obj: WorkspacePromptPostBody
  ): Observable<WorkspacePromptPostResponse> {
    return this.http.post<WorkspacePromptPostResponse>(
      getUrl('/workspace/prompt'),
      obj,
      authApi(true)
    );
  }

  // workspacePromptGet
  public getOne(
    obj: WorkspacePromptGetQuery
  ): Observable<WorkspacePromptGetResponse> {
    return this.http.get<WorkspacePromptGetResponse>(
      getUrl('/workspace/prompt') + objectToQueryString(obj),
      authApi(true)
    );
  }

  /// CUSTOM METHODS

  ///
}
