//
// ───────────────────────────────────────────────── DEPENDENCIES AND COSTANT ─────
//
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  static instance: HttpService;

  constructor(public http: HttpClient) {
    HttpService.instance = this;
  }
}
