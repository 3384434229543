import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CbFileFormColumn, CbListFormColumn, CbOptionsFormColumn, CbSliderFormColumn, CbStandardFormColumn, CbTextareaFormColumn } from '../cb-form-interfaces';


const noop = () => { };

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CbFormFieldComponent),
  multi: true,
};

@Component({
  selector: 'cb-form-field',
  templateUrl: './cb-form-field.component.html',
  styleUrls: ['./cb-form-field.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class CbFormFieldComponent
  implements ControlValueAccessor, OnInit {
  @Input() public field!:
    | CbStandardFormColumn
    | CbTextareaFormColumn
    | CbOptionsFormColumn
    | CbListFormColumn
    | CbFileFormColumn
    | CbSliderFormColumn;

  @Input("hideLabel") public hideLabel = false;

  @Input() public error = false;

  @Output('change') public onChange: EventEmitter<any> =
    new EventEmitter<any>();

  //The internal data model
  private innerValue: any;
  public errorMessage = "";

  constructor(
    private readonly translate: TranslateService,
    private readonly cdr: ChangeDetectorRef
  ) {

  }

  ngOnInit(): void { }

  public get minRows(): number {
    return 'minRows' in this.field
      ? typeof this.field.minRows == 'number'
        ? this.field.minRows
        : 1
      : 1;
  }

  public get maxRows(): number {
    return 'maxRows' in this.field
      ? typeof this.field.maxRows == 'number'
        ? this.field.maxRows
        : -1
      : -1;
  }

  public get hasMultipleFiles(): boolean {
    return 'multiple' in this.field ? this.field.multiple ?? false : false;
  }

  public get sliderMin(): number {
    return 'min' in this.field ? this.field.min || 0 : 0;
  }

  public get sliderMax(): number {
    return 'max' in this.field ? this.field.max || 100 : 100;
  }

  public get sliderStep(): number {
    return 'step' in this.field ? this.field.step || 1 : 1;
  }

  public get sliderStepLabels(): string[] {
    return 'stepLabels' in this.field ? this.field.stepLabels || [] : [];
  }

  public get textareaRows(): number {
    return 'rows' in this.field ? this.field.rows || 2 : 2;
  }

  public get options(): { value: string; label: string }[] {
    if ('options' in this.field) return this.field.options || [];
    else
      return [];
  }

  public get canAddRows(): boolean {
    return (
      !this.maxRows ||
      this.maxRows < 0 ||
      (this.value && this.value.length < this.maxRows)
    );
  }

  public get listChildren() {
    if ('listChildren' in this.field) return this.field.listChildren;
    return undefined;
  }

  public get label(): string {
    return (this.field.label) ?
      ((this.translate.instant(this.field.label)
        + ((this.field.required) ? "*" : '')))
      : '';
  }

  public async checkForErrors(values: { [key: string]: any }): Promise<boolean> {
    if (this.field.required && !values[this.field.field]) {
      this.error = true;
      this.errorMessage = "COMMON.ERRORS.FIELD_REQUIRED";
    } else if (this.field.validator) {
      this.error = await !this.field.validator(values[this.field.field], values);
      this.errorMessage = this.field.validatorError || "COMMON.ERRORS.FIELD_INVALID";
    } else {
      this.error = false;
    }

    return this.error;
  }

  public canRemoveRow(idx: number): boolean {
    return (
      this.value && this.value.length > this.minRows && this.value.length > idx
    );
  }

  private initListType(): void {
    // If the type of the field is list
    // then init the value as a "matrix" of values
    if (this.field.type == 'list') {
      this.value = [];

      if (this.minRows && this.minRows >= 0)
        for (let i = 0; i < this.minRows; i++) {
          this.addNewListRow();
        }
      else this.addNewListRow();
    }
  }

  public addNewListRow(): void {
    if (this.value != undefined) {
      this.value.push({});
      if (this.listChildren?.fields) {
        for (const [i, field] of this.listChildren?.fields.entries()) {
          this.value[this.value.length - 1][field.field] = undefined;
        }
      }
    } else {
      this.initListType();
    }
  }

  public removeRow(idx: number): void {
    if (this.canRemoveRow(idx)) {
      this.value.splice(idx, 1);
    }
  }

  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  // get accessor
  public get value(): any {
    return this.innerValue;
  }

  // Set accessor including call the onchange callback
  public set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  // From ControlValueAccessor interface
  public writeValue(value: any) {
    if (value !== this.innerValue) {
      if (value == undefined || value == null) {
        if (this.field.type == 'list') this.initListType();
        else this.innerValue = value;
      } else {
        this.innerValue = value;
      }
      this.cdr.detectChanges();
      this.onChangeCallback(value);
    }
  }

  public change(): void {
    this.onChange.emit(this.value);
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
