export const defaultPromptsContent: { [key: string]: { [key: string]: string } } = {
        'tldr': {
                it: `Scrivimi un riassunto del seguente contenuto: {{content}}.`,
                en: `Write me here a short summary of the following content: {{content}}.`,
                fr: `Écrivez-moi ici un résumé court du contenu suivant: {{content}}.`,
                de: `Schreiben Sie mir hier eine kurze Zusammenfassung des folgenden Inhalts: {{content}}.`,
                es: `Escriba aquí un resumen corto del siguiente contenido: {{content}}.`,
                nl: `Schrijf hier een korte samenvatting van de volgende inhoud: {{content}}.`,
                pl: `Napisz mi tutaj krótkie podsumowanie następującej treści: {{content}}.`,
                pt: `Escreva aqui um resumo curto do seguinte conteúdo: {{content}}.`,
                ja: `次の内容の短い要約をここに書いてください：{{content}}.`,
                ko: `다음 내용의 간단한 요약을 여기에 적어주십시오. {{content}}.`,
                ru: `Напишите здесь краткое изложение следующего содержимого: {{content}}.`,
                zh: `在这里写一个以下内容的简短摘要：{{content}}.`,
                th: `โปรดเขียนสรุปย่อของเนื้อหาต่อไปนี้ที่นี่: {{content}}.`,
                hi: `यहां निम्नलिखित सामग्री के लिए एक संक्षिप्त सारांश लिखें: {{content}}.`,
                tr: `Buraya aşağıdaki içeriğin kısa bir özetini yazın: {{content}}.`,
                id: `Tulis di sini ringkasan singkat dari konten berikut: {{content}}.`,
                other: `Write me here a short summary of the following content (use {{language}} language): {{content}}.`,
        },
        'description': {
                it: `Scrivimi una breve descrizione di massimo 160 caratteri ottimizzata per l'indicizzazione sui motori di ricerca: {{content}}.`,
                en: `Write me here a short (max 160 char) description optimized for the google search. Of the following content: {{content}}.`,
                fr: `Écrivez-moi ici une courte description (max 160 caractères) optimisée pour la recherche Google. Du contenu suivant: {{content}}.`,
                de: `Schreiben Sie hier eine kurze (max. 160 Zeichen) Beschreibung, die für die Google-Suche optimiert ist. Des folgenden Inhalts: {{content}}.`,
                es: `Escriba aquí una descripción corta (máximo 160 caracteres) optimizada para la búsqueda de Google. Del siguiente contenido: {{content}}.`,
                nl: `Schrijf hier een korte (max. 160 tekens) beschrijving geoptimaliseerd voor de Google-zoekopdracht. Van de volgende inhoud: {{content}}.`,
                pl: `Napisz mi tutaj krótki (maksymalnie 160 znaków) opis zoptymalizowany pod kątem wyszukiwania w Google. Z następującej treści: {{content}}.`,
                pt: `Escreva aqui uma descrição curta (máximo 160 caracteres) otimizada para a pesquisa do Google. Do seguinte conteúdo: {{content}}.`,
                ja: `Googleの検索を最適化した最大160文字の短い説明をここに書いてください。次の内容： {{content}}.`,
                ko: `Google 검색을 위해 최적화 된 최대 160 글자의 짧은 설명을 여기에 작성하십시오. 다음 내용: {{content}}.`,
                ru: `Здесь напишите краткое описание (максимум 160 символов), оптимизированное для поиска в Google. Следующего содержания: {{content}}.`,
                zh: `在此处编写一个最多160个字符的简短描述，以便优化Google搜索。以下内容：{{content}}.`,
                th: `พิมพ์ที่นี่คำอธิบายสั้นๆ(สูงสุด 160 ตัวอักษร) ที่ได้รับการปรับแต่งให้เหมาะสมสำหรับการค้นหาของ Google ของเนื้อหาต่อไปนี้: {{content}}.`,
                hi: `गूगल खोज के लिए अनुकूलित अधिकतम 160 वर्ण का एक छोटा विवरण यहां लिखें। निम्नलिखित सामग्री: {{content}}.`,
                tr: `Buraya Google araması için en fazla 160 karakterlik kısa bir açıklama yazın. Aşağıdaki içerikten: {{content}}.`,
                id: `Tulis di sini deskripsi singkat (maksimal 160 karakter) yang dioptimalkan untuk pencarian Google. Dari konten berikut: {{content}}.`,
                other: `Write me here a short (max 160 char) description optimized for the google search. Of the following content (use {{language}} language): {{content}}.`,
        },
        'keywords': {
                it: `Scrivimi una lista numerata di parole chiave per il seguente contenuto: {{content}}.`,
                en: `Write me here a short numbered list of keywords of the following content: {{content}}.`,
                fr: `Écrivez-moi ici une courte liste numérotée de mots-clés du contenu suivant: {{content}}.`,
                de: `Schreiben Sie mir hier eine kurze nummerierte Liste von Schlüsselwörtern des folgenden Inhalts: {{content}}.`,
                es: `Escriba aquí una corta lista numerada de palabras clave del siguiente contenido: {{content}}.`,
                nl: `Schrijf hier een kort genummerd lijstje met trefwoorden van de volgende inhoud: {{content}}.`,
                pl: `Napisz mi tutaj krótką, numerowaną listę słów kluczowych następującej treści: {{content}}.`,
                pt: `Escreva aqui uma curta lista numerada de palavras-chave do seguinte conteúdo: {{content}}.`,
                ja: `次の内容のキーワードの短い番号付きリストをここに書いてください：{{content}}.`,
                ko: `다음 내용의 키워드의 단축 번호 리스트를 여기에 작성하십시오 : {{content}}.`,
                ru: `Напишите здесь короткий нумерованный список ключевых слов следующего содержимого: {{content}}.`,
                zh: `在这里写出以下内容的关键字编号列表：{{content}}.`,
                th: `เขียนที่นี่รายการคำสำคัญของเนื้อหาต่อไปนี้ที่มีหมายเลข: {{content}}.`,
                hi: `निम्नलिखित सामग्री के लिए एक छोटा सूचीबद्ध कीबोर्ड की सूची यहाँ लिखें: {{content}}.`,
                tr: `Şu içerik için kısa bir numaralı anahtar kelime listesi yazın: {{content}}.`,
                id: `Tulis di sini daftar kata kunci berangka pendek dari konten berikut: {{content}}.`,
                other: `Write me here a short numbered list of keywords of the following content (use {{language}} language): {{content}}.`,
        },
        'instructions': {
                it: `Tenendo conto delle seguenti informazioni aggiuntive: {{additionalInformation}}. 
Utilizza il seguente tono nella scrittura {{tone}}. 
Scrivi seguendo le prossime istruzioni {{content}}.
Formatta la risposta con tag HTML.`,
                en: `Evaluate this additional informations: {{additionalInformation}}. 
Use the following writing tone {{tone}}. 
Write and follow the next instructions {{content}}.
Format the answer with HTML tags.`,
                fr: `Tenir compte des informations supplémentaires suivantes: {{additionalInformation}}. 
Utilisez le ton d'écriture suivant {{tone}}. 
Écrivez et suivez les instructions suivantes {{content}}.
Formatez la réponse avec des balises HTML.`,
                de: `Berücksichtigen Sie die folgenden zusätzlichen Informationen: {{additionalInformation}}. 
Verwenden Sie folgenden Schreibton {{tone}}. 
Schreiben Sie und folgen Sie den folgenden Anweisungen {{content}}.
Formatieren Sie die Antwort mit HTML-Tags.`,
                es: `Teniendo en cuenta la siguiente información adicional: {{additionalInformation}}. 
Utilice el siguiente tono de escritura {{tone}}. 
Escriba y siga las siguientes instrucciones {{content}}.
Formatee la respuesta con etiquetas HTML.`,
                nl: `Rekening houdend met de volgende aanvullende informatie: {{additionalInformation}}. 
Gebruik de volgende schrijftoon {{tone}}. 
Schrijf en volg de volgende instructies {{content}}.
Formatteer het antwoord met HTML-tags.`,
                pl: `Biorąc pod uwagę następujące dodatkowe informacje: {{additionalInformation}}.
Użyj następującego tonu pisania {{tone}}.
Napisz i postępuj zgodnie z następującymi instrukcjami {{content}}.
Sformatuj odpowiedź za pomocą tagów HTML.`,
                pt: `Considerando as seguintes informações adicionais: {{additionalInformation}}. 
Utilize o seguinte tom de escrita {{tone}}. 
Escreva e siga as seguintes instruções {{content}}.
Formate a resposta com tags HTML.`,
                ja: `次の追加情報を考慮してください。{{additionalInformation}}. 
次のライティングトーンを使用してください{{tone}}. 
書き、次の指示に従ってください{{content}}.
HTMLタグで回答をフォーマットします。`,
                ko: `다음 추가 정보를 고려하십시오. {{additionalInformation}}. 
다음 쓰기 톤을 사용하십시오 {{tone}}. 
쓰고 다음 지침에 따라 주십시오 {{content}}.
HTML 태그로 답변을 서식 지정하십시오.`,
                ru: `Учитывая следующую дополнительную информацию: {{additionalInformation}}. 
Используйте следующий письменный тон {{tone}}. 
Напишите и следуйте следующим инструкциям {{content}}.
Отформатируйте ответ с помощью тегов HTML.`,
                zh: `考虑以下附加信息：{{additionalInformation}}. 
使用以下写作语调{{tone}}. 
按照以下指示书写{{content}}.
使用HTML标签格式化答案。`,
                th: `พิจารณาข้อมูลเพิ่มเติมต่อไปนี้: {{additionalInformation}}. 
ใช้เสียงการเขียนต่อไปนี้ {{tone}}. 
เขียนและปฏิบัติตามคำแนะนำต่อไปนี้ {{content}}.
จัดรูปแบบคำตอบด้วยแท็ก HTML.`,
                hi: `निम्नलिखित अतिरिक्त जानकारी को ध्यान में रखें: {{additionalInformation}}. 
निम्नलिखित लेखन उच्चारण का उपयोग करें {{tone}}. 
लिखें और निम्नलिखित निर्देश का पालन करें {{content}}.
HTML टैग के साथ उत्तर स्वरूपित करें.`,
                tr: `Aşağıdaki ek bilgileri göz önünde bulundurarak: {{additionalInformation}}. 
Aşağıdaki yazı tonunu kullanın {{tone}}. 
Yazın ve aşağıdaki talimatları izleyin {{content}}.
HTML etiketleriyle cevabı biçimlendirin.`,
                id: `Evaluasi informasi tambahan ini: {{additionalInformation}}.
Gunakan nada tulisan berikut {{tone}}.
Tulis dan ikuti instruksi berikut {{content}}.
Format jawaban dengan tag HTML.`,
                other: `Evaluate this additional informations: {{additionalInformation}}. 
Use the following writing tone {{tone}}. 
Write and follow the next instructions {{content}}.
Format the answer with HTML tags.`,
        },
        'topics': {
                it: `Sono un business che si occupa di: {{businessType}}. 
In particolare di: {{businessDescription}}. 
Suggeriscimi una lista numerata di possibili argomenti da utilizzare nel blog.`,
                en: `I'm a {{businessType}}.
In particular: {{businessDescription}}.
Suggest a numbered list of possible topics to use in the blog.`,
                fr: `Je suis un {{businessType}}.
En particulier: {{businessDescription}}.
Proposez une liste numérotée des sujets possibles à utiliser dans le blog.`,
                de: `Ich bin ein {{businessType}}.
Insbesondere: {{businessDescription}}.
Schlagen Sie eine nummerierte Liste möglicher Themen vor, die im Blog verwendet werden sollen.`,
                es: `Soy un {{businessType}}.
En particular: {{businessDescription}}.
Sugiera una lista numerada de temas posibles para usar en el blog.`,
                pl: `Jestem {{businessType}}.
W szczególności: {{businessDescription}}.
Zaproponuj ponumerowaną listę możliwych tematów do wykorzystania w blogu.`,
                nl: `Ik ben een {{businessType}}.
Met name: {{businessDescription}}.
Stel een genummerde lijst met mogelijke onderwerpen voor die in de blog gebruikt kunnen worden.`,
                pt: `Eu sou um {{businessType}}.
Em particular: {{businessDescription}}.
Sugira uma lista numerada de tópicos possíveis para usar no blog.`,
                ja: `私は{{businessType}}です。
特に：{{businessDescription}}。
ブログで使用する可能性のある番号付きのトピックのリストを提案してください。`,
                ko: `나는 {{businessType}}입니다.
특히 : {{businessDescription}}.
블로그에서 사용할 수있는 가능한 주제의 번호 목록을 제안하십시오.`,
                ru: `Я {{businessType}}.
Конкретно: {{businessDescription}}.
Предложите нумерованный список возможных тем для использования в блоге.`,
                zh: `我是{{businessType}}。
特别是：{{businessDescription}}。
建议用于博客的可能主题的编号列表。`,
                th: `ฉันเป็น {{businessType}} นั่นเอง
โดยเฉพาะ: {{businessDescription}}.
แนะนำรายการที่เลขที่อาจใช้ในบล็อก`,
                hi: `मैं {{businessType}} हूँ।
विशेष रूप से: {{businessDescription}}.
ब्लॉग में उपयोग करने के लिए संभव विषयों की संख्यात्मक सूची की सुझाव दें।`,
                tr: `Ben bir {{businessType}}'yım.
Özellikle: {{businessDescription}}.
Blogda kullanılacak olası konuların numaralandırılmış bir listesi önerin.`,
                id: `Saya adalah {{businessType}}.
Khususnya: {{businessDescription}}.
Usulkan daftar bernomor topik yang mungkin digunakan di blog.`,
                other: `I'm a {{businessType}}.
In particular: {{businessDescription}}.
Suggest a numbered list of possible topics to use in the blog.
Use {{language}} language.`,
        },
        'titles': {
                it: `Genera una lista numerata di 5 titoli (massimo 10 parole per ogni titolo) per un articolo di blog che parla di questo contenuto: {{content}}`,
                en: `Generate a numbered list of 5 titles (max 10 words for each title) for a blog article that talks about this content: {{content}}`,
                fr: `Générez une liste numérotée de 5 titres (maximum 10 mots pour chaque titre) pour un article de blog parlant de ce contenu: {{content}}`,
                de: `Erstellen Sie eine nummerierte Liste mit 5 Titeln (maximal 10 Wörter pro Titel) für einen Blog-Artikel über diesen Inhalt: {{content}}`,
                es: `Genere una lista numerada de 5 títulos (máximo 10 palabras por título) para un artículo de blog que hable de este contenido: {{content}}`,
                nl: `Genereer een genummerde lijst van 5 titels (maximaal 10 woorden per titel) voor een blogartikel over deze inhoud: {{content}}`,
                pl: `Wygeneruj ponumerowaną listę 5 tytułów (maksymalnie 10 słów na każdy tytuł) dla artykułu na blogu, który mówi o tej treści: {{content}}`,
                pt: `Gerar uma lista numerada com 5 títulos (máximo 10 palavras por título) para um artigo de blog que fale sobre este conteúdo: {{content}}`,
                ja: `このコンテンツについて語るブログ記事のために、5つのタイトル（タイトルごとに最大10語）をナンバリングリストで生成します：{{content}}`,
                ko: `이 컨텐츠에 대해 블로그 기사를 작성하기 위해 5 개의 제목(각 제목당 최대 10 단어)을 번호 매기는 목록을 작성합니다: {{content}}`,
                ru: `Создайте нумерованный список из 5 названий (не более 10 слов для каждого названия) для блог-статьи, говорящей об этом содержимом: {{content}}`,
                zh: `为谈论这个内容的博客文章生成5个标题（每个标题最多10个单词）的编号列表：{{content}}`,
                th: `สร้างรายการที่มีหมายเลขของ 5 ชื่อเรื่อง (สูงสุด 10 คำสำหรับแต่ละชื่อเรื่อง) สำหรับบทความบล็อกที่พูดถึงเนื้อหานี้: {{content}}`,
                hi: `इस सामग्री के बारे में ब्लॉग आर्टिकल के लिए 5 शीर्षकों (प्रत्येक शीर्षक के लिए अधिकतम 10 शब्द) के लिए संख्यात्मक सूची तैयार करें: {{content}}`,
                tr: `Bu içeriğe hakkında bir blog yazısı için 5 başlık (her başlık için en fazla 10 kelime) için numaralandırılmış bir liste oluşturun: {{content}}`,
                id: `Buat daftar bernomor 5 judul (maksimal 10 kata untuk setiap judul) untuk artikel blog yang membahas konten ini: {{content}}`,
                other: `Generate a numbered list of 5 titles (max 10 words for each title) for a blog article that talks about this content: {{content}}. Use {{language}} language.`
        },
        'tocChat': {
                it: `Scrivi una bozza dell'articolo di blog utilizzando numeri (1,2 etc) per sezioni e lettere (a,b etc) per sottosezioni. 
Scrivi solo titoli e sottotitoli, non andare nei dettagli delle sottosezioni.`,
                en: `Write the blog outline use number for section and letters for subsections. 
Write only titles and subtitles, do not go into the details of the subsections.`,
                fr: `Écrivez le plan du blog en utilisant des numéros pour les sections et des lettres pour les sous-sections.
Écrivez uniquement des titres et des sous-titres, ne rentrez pas dans les détails des sous-sections.`,
                de: `Schreiben Sie den Blog-Entwurf mit Zahlen für Abschnitte und Buchstaben für Unterkapitel.
Schreiben Sie nur Titel und Untertitel, gehen Sie nicht in die Details der Unterkapitel ein.`,
                es: `Escriba el esquema del blog utilizando números para secciones y letras para subsecciones.
Escriba solo títulos y subtítulos, no entre en los detalles de las subsecciones.`,
                nl: `Schrijf de blog-ontwerp met nummers voor secties en letters voor subsecties.
Schrijf alleen titels en ondertitels, ga niet in op de details van de subsecties.`,
                pl: `Napisz szkic bloga, używając numerów dla sekcji i liter dla podsekcji.
Napisz tylko tytuły i podtytuły, nie wchodząc w szczegóły podsekcji.`,
                pt: `Escreva o esboço do blog usando números para seções e letras para subseções.
Escreva apenas títulos e subtítulos, não entre nos detalhes das subseções.`,
                ja: `セクションには番号を、サブセクションには文字を使用してブログのアウトラインを書きます。
タイトルとサブタイトルのみを書き、サブセクションの詳細には入りません。`,
                ko: `섹션에는 번호를, 하위 섹션에는 문자를 사용하여 블로그 개요를 작성하십시오.
제목과 부제목 만 작성하고 하위 섹션의 세부 사항에는 들어가지 마십시오.`,
                ru: `Напишите план блога, используя номера для разделов и буквы для подразделов.
Напишите только заголовки и подзаголовки, не входите в подробности подразделов.`,
                zh: `使用数字为部分和字母为子部分编写博客大纲。
只写标题和副标题，不要进入子部分的细节。`,
                th: `เขียนโครงร่างบล็อกโดยใช้ตัวเลขสำหรับส่วนและตัวอักษรสำหรับหัวข้อย่อย
เขียนเฉพาะชื่อและชื่อย่อยเท่านั้น ไม่ต้องเข้าไปในรายละเอียดของหัวข้อย่อย`,
                hi: `अनुभाग के लिए संख्या और उपअनुभाग के लिए अक्षर का उपयोग करके ब्लॉग आउटलाइन लिखें
केवल शीर्षक और उपशीर्षक लिखें, उपअनुभागों के विवरणों में न जाएं`,
                tr: `Bölüm için numara ve alt bölüm için harf kullanarak blog taslağını yazın
Yalnızca başlıkları ve alt başlıkları yazın, alt bölümlerin ayrıntılarına girmeyin`,
                id: `Tulis kerangka blog menggunakan nomor untuk bagian dan huruf untuk subbagian
Tulis hanya judul dan subjudul, jangan masuk ke detail subbagian`,
                other: `Write the blog outline use number for section and letters for subsections.
Write only titles and subtitles, do not go into the details of the subsections.`
        },
        'toc': {
                it: `Sto scrivendo un articolo di blog con questo titolo {{content}}. 
Scrivi una bozza del blog utilizzando numeri (1,2 etc) per sezioni e lettere (a,b etc) per sottosezioni. 
Scrivi solo titoli e sottotitoli, non andare nei dettagli delle sottosezioni.
Utilizza questo tono: {{tone}}. 
Valuta anche le seguenti informazioni aggiuntive: {{additionalInformation}}.`,
                en: `I'm writing a blog article with this title {{content}}. 
Write the blog outline use number for section and letters for subsections. 
Write only titles and subtitles, do not go into the details of the subsections.
Use this tone: {{tone}}. 
Evaluate also this additional information: {{additionalInformation}}.`,
                fr: `Je suis en train d'écrire un article de blog avec ce titre {{content}}. 
Écrivez le plan du blog en utilisant des numéros pour les sections et des lettres pour les sous-sections. 
Écrivez uniquement les titres et les sous-titres, ne rentrez pas dans les détails des sous-sections.
Utilisez ce ton: {{tone}}. 
Évaluez également ces informations supplémentaires: {{additionalInformation}}.`,

                de: `Ich schreibe einen Blog-Artikel mit diesem Titel {{content}}. 
Schreiben Sie den Blog-Outline verwenden Nummer für Abschnitt und Buchstaben für Unterabschnitte. 
Schreiben Sie nur Titel und Untertitel, gehen Sie nicht in die Details der Unterabschnitte.
Benutze diesen Ton: {{tone}}. 
Bewerten Sie auch diese zusätzlichen Informationen: {{additionalInformation}}.`,

                es: `Estoy escribiendo un artículo de blog con este título {{content}}. 
Escriba el esquema del blog utilizando números para la sección y letras para las subsecciones. 
Escriba solo títulos y subtítulos, no entre en los detalles de las subsecciones.
Utilice este tono: {{tone}}. 
Evalúe también esta información adicional: {{additionalInformation}}.`,

                nl: `Ik schrijf een blogartikel met deze titel {{content}}. 
Schrijf de blogschets met nummers voor sectie en letters voor subsecties. 
Schrijf alleen titels en subtitels, ga niet in op de details van de subsecties.
Gebruik deze toon: {{tone}}. 
Evaluat ook de volgende aanvullende informatie: {{additionalInformation}}.`,

                pl: `Piszę artykuł blogowy o tym tytule {{content}}.
Napisz szkic bloga, używając numerów dla sekcji i liter dla podsekcji.
Napisz tylko tytuły i podtytuły, nie wchodź w szczegóły podsekcji.
Użyj tego tonu: {{tone}}.
Oceń również te dodatkowe informacje: {{additionalInformation}}.`,

                pt: `Estou escrevendo um artigo de blog com este título {{content}}. 
Escreva o esboço do blog usando números para seção e letras para subseções. 
Escreva apenas títulos e subtítulos, não entre em detalhes das subseções.
Use este tom: {{tone}}. 
Avalie também esta informação adicional: {{additionalInformation}}.`,

                ja: `このタイトル {{content}} でブログ記事を書いています。
セクションに数字、サブセクションに文字を使ってブログのアウトラインを書いてください。
タイトルとサブタイトルだけを書いて、サブセクションの詳細には入らないでください。
このトーンを使用してください：{{tone}}。
また、以下の追加情報を評価してください：{{additionalInformation}}。`,
                ko: `이 제목 {{content}}을 가진 블로그 기사를 작성하고 있습니다. 
섹션에 숫자를 사용하고 하위 섹션에 문자를 사용하여 블로그 아웃라인을 작성하십시오. 
제목과 부제만 작성하고 하위 섹션의 세부 사항에는 입력하지 마십시오.
이 톤을 사용하십시오 : {{tone}}. 
또한이 추가 정보를 평가하십시오 : {{additionalInformation}}.`,
                ru: `Я пишу блог-статью с этим заголовком {{content}}. 
Напишите план блога, используя номера для раздела и буквы для подразделов. 
Пишите только заголовки и подзаголовки, не заходите в подробности подразделов.
Используйте этот тон: {{tone}}. 
Оцените также следующую дополнительную информацию: {{additionalInformation}}.`,
                zh: `我正在用这个标题 {{content}} 写博客文章。
使用数字为部分和字母为子部分编写博客大纲。
只写标题和副标题，不要进入子部分的细节。
使用这种语气：{{tone}}。
评估这些附加信息：{{additionalInformation}}。`,
                hi: `मैं इस शीर्षक के साथ एक ब्लॉग लेख लिख रहा हूं {{content}}।
अनुभागों के लिए संख्या और उपअनुभागों के लिए अक्षर का उपयोग करके ब्लॉग आउटलाइन लिखें।
केवल शीर्षक और उपशीर्षक लिखें, उपअनुभाग के विस्तार में न जाएं।
इस ध्वनि का उपयोग करें: {{tone}}।
इसके अलावा, यह अतिरिक्त जानकारी को भी मूल्यांकन करें: {{additionalInformation}}।`,
                tr: `Bu başlıkla bir blog makalesi yazıyorum {{content}}.
Bölümler için sayı ve alt bölümler için harfleri kullanarak blog özeti yazın.
Sadece başlıklar ve alt başlıklar yazın, alt bölümlerin ayrıntılarına girmeyin.
Bu tonu kullanın: {{tone}}.
Ayrıca bu ek bilgiyi de değerlendirin: {{additionalInformation}}.`,
                id: `Saya sedang menulis artikel blog dengan judul ini {{content}}.
Tulis kerangka blog menggunakan angka untuk bagian dan huruf untuk subbagian.
Tulis hanya judul dan subjudul, jangan masuk ke detail subbagian.
Gunakan nada ini: {{tone}}.
Evaluasi juga informasi tambahan ini: {{additionalInformation}}.`,
                th: `ฉันกำลังเขียนบทความบล็อกด้วยชื่อนี้ {{content}}.
เขียนโครงร่างบล็อกโดยใช้ตัวเลขสำหรับส่วนและตัวอักษรสำหรับหัวข้อย่อย
เขียนเฉพาะหัวเรื่องและหัวเรื่องย่อยเท่านั้น ไม่ต้องเข้าไปในรายละเอียดของหัวข้อย่อย
ใช้เสียงนี้: {{tone}}.
ประเมินข้อมูลเพิ่มเติมนี้ด้วย: {{additionalInformation}}.`,
                other: `I'm writing a blog article with this title {{content}}. 
Write the blog outline use number for section and letters for subsections.
Write only titles and subtitles, do not go into the details of the subsections.
Use this tone: {{tone}}. 
Evaluate also this additional information: {{additionalInformation}}. 
Use {{language}} language.`,
        },
        'contentChat': {
                en: `These are the paragraphs I've already written: "{{content}}". 
Add a paragraph that is relevant to the section "{{section}}". 
Don't reapeat the information from the previous paragraphs.
Format your answer with HTML tags.`,
                it: `Questi sono i paragrafi che ho già scritto: "{{content}}".
Aggiungi un paragrafo che sia rilevante per la sezione "{{section}}".
Non ripetere le informazioni dai paragrafi precedenti.
Formatta la tua risposta con i tag HTML.`,
                fr: `Voici les paragraphes que j'ai déjà écrits: "{{content}}".
Ajoutez un paragraphe qui est pertinent pour la section "{{section}}".
Ne répétez pas les informations des paragraphes précédents.
Formatez votre réponse avec des balises HTML.`,
                de: `Das sind die Absätze, die ich bereits geschrieben habe: "{{content}}".
Fügen Sie einen Absatz hinzu, der für den Abschnitt "{{section}}" relevant ist.
Wiederholen Sie nicht die Informationen aus den vorherigen Absätzen.    
Formatieren Sie Ihre Antwort mit HTML-Tags.`,
                es: `Estos son los párrafos que ya he escrito: "{{content}}".
Agrega un párrafo que sea relevante para la sección "{{section}}".
No repita la información de los párrafos anteriores.
Formatee su respuesta con etiquetas HTML.`,
                nl: `Dit zijn de alinea's die ik al heb geschreven: "{{content}}".
Voeg een alinea toe die relevant is voor de sectie "{{section}}".
Herhaal de informatie niet uit de vorige alinea's.
Formatteer uw antwoord met HTML-tags.`,
                pl: `Oto akapity, które już napisałem: "{{content}}".
Dodaj akapit, który jest istotny dla sekcji "{{section}}".
Nie powtarzaj informacji z poprzednich akapitów.
Sformatuj swoją odpowiedź za pomocą tagów HTML.`,
                pt: `Estes são os parágrafos que já escrevi: "{{content}}".
Adicione um parágrafo que seja relevante para a seção "{{section}}".
Não repita as informações dos parágrafos anteriores.
Formate sua resposta com tags HTML.`,
                ja: `これは私が書いた文章です: "{{content}}".
セクション "{{section}}" に関連する文章を追加してください。
前の文章から情報を繰り返さないでください。
HTMLタグを使って回答をフォーマットしてください。`,
                zh: `这些是我已经写好的段落：{{content}}。
添加一个与章节 "{{section}}" 相关的段落。
不要重复前面段落的信息。
使用HTML标签格式化您的答案。`,
                ko: `이전에 작성한 문단: "{{content}}".
섹션 "{{section}}"과 관련된 문단을 추가하세요.
이전 문단의 정보를 반복하지 마세요.
HTML 태그로 답변을 서식 지정하세요.`,
                ru: `Это абзацы, которые я уже написал: "{{content}}".
Добавьте абзац, который относится к разделу "{{section}}".
Не повторяйте информацию из предыдущих абзацев.
Отформатируйте свой ответ с помощью тегов HTML.`,
                tr: `Bu bölümleri yazdım: "{{content}}".
"{{section}}" bölümüne uygun bir paragraf ekleyin.
Önceki paragraflardan bilgi tekrarlamayın.
Cevabınızı HTML etiketleriyle biçimlendirin.`,
                id: `Ini adalah paragraf yang sudah saya tulis: "{{content}}".
Tambahkan paragraf yang relevan dengan bagian "{{section}}".
Jangan mengulangi informasi dari paragraf sebelumnya.
Format jawaban Anda dengan tag HTML.`,
                hi: `यहाँ पहले से ही लिखे गए अनुच्छेद हैं: "{{content}}".
"{{section}}" अनुभाग के लिए एक अनुच्छेद जोड़ें।
पिछले अनुच्छेदों से जानकारी दोहराने से बचें।
अपना उत्तर HTML टैग के साथ स्वरूपित करें।`,
                th: `นี่คือย่อหน้าที่ฉันเขียนไว้แล้ว: "{{content}}".
เพิ่มย่อหน้าที่เกี่ยวข้องกับส่วน "{{section}}".
อย่าซ้ำข้อมูลจากย่อหน้าก่อนหน้านี้
จัดรูปแบบคำตอบของคุณด้วยแท็ก HTML.`,
                other: `These are the paragraphs I've already written: "{{content}}".
Add a paragraph that is relevant to the section "{{section}}".
Don't reapeat the information from the previous paragraphs.
Format your answer with HTML tags.`,
        },
        'content': {
                en: `I'm writing a blog post with this title: "{{title}}".
These are the paragraphs I've already written: "{{content}}". 
Add a paragraph that is relevant to the section "{{section}}". 
Don't reapeat the information from the previous paragraphs.
Use this tone: {{tone}}.
Evaluate also this additional information: {{additionalInformation}}.`,
                it: `Sto scrivendo un post di blog con questo titolo: "{{title}}".
Questi sono i paragrafi che ho già scritto: "{{content}}".
Aggiungi un paragrafo che sia rilevante per la sezione "{{section}}".
Non ripetere le informazioni dai paragrafi precedenti.
Usa questo tono: {{tone}}.
Valuta anche queste informazioni aggiuntive: {{additionalInformation}}.`,
                fr: `Je rédige un article de blog avec ce titre: "{{title}}".
Voici les paragraphes que j'ai déjà écrits: "{{content}}".
Ajoutez un paragraphe qui est pertinent pour la section "{{section}}".
Ne répétez pas les informations des paragraphes précédents.
Utilisez cette tonalité: {{tone}}.
Évaluez également ces informations supplémentaires: {{additionalInformation}}.`,
                de: `Ich schreibe einen Blog-Artikel mit diesem Titel: "{{title}}".
Das sind die Absätze, die ich bereits geschrieben habe: "{{content}}".
Fügen Sie einen Absatz hinzu, der für den Abschnitt "{{section}}" relevant ist.
Wiederholen Sie nicht die Informationen aus den vorherigen Absätzen.
Verwenden Sie diesen Ton: {{tone}}.
Bewerten Sie auch diese zusätzlichen Informationen: {{additionalInformation}}.`,
                es: `Estoy escribiendo un artículo de blog con este título: "{{title}}".
Estos son los párrafos que ya he escrito: "{{content}}".
Agrega un párrafo que sea relevante para la sección "{{section}}".
No repita la información de los párrafos anteriores.
Usa este tono: {{tone}}.
Evalúa también esta información adicional: {{additionalInformation}}.`,
                nl: `Ik schrijf een blogartikel met deze titel: "{{title}}".
Dit zijn de alinea's die ik al heb geschreven: "{{content}}".
Voeg een alinea toe die relevant is voor de sectie "{{section}}".
Herhaal de informatie niet uit de vorige alinea's.
Gebruik deze toon: {{tone}}.
Evalueer ook deze aanvullende informatie: {{additionalInformation}}.`,
                pl: `Piszę post na blogu z tym tytułem: "{{title}}".
Oto akapity, które już napisałem: "{{content}}".
Dodaj akapit, który jest istotny dla sekcji "{{section}}".
Nie powtarzaj informacji z poprzednich akapitów.
Użyj tego tonu: {{tone}}.
Oceń również te dodatkowe informacje: {{additionalInformation}}.`,
                pt: `Estou escrevendo um artigo de blog com este título: "{{title}}".
Estes são os parágrafos que já escrevi: "{{content}}".
Adicione um parágrafo que seja relevante para a seção "{{section}}".
Não repita as informações dos parágrafos anteriores.
Use esta tonalidade: {{tone}}.
Avalie também esta informação adicional: {{additionalInformation}}.`,
                ja: `私はこのタイトルでブログ記事を書いています: "{{title}}".
これは私が書いた文章です: "{{content}}".
セクション "{{section}}" に関連する文章を追加してください。
前の文章から情報を繰り返さないでください。
このトーンを使用してください: {{tone}}。
この追加情報も評価してください: {{additionalInformation}}。`,
                zh: `我正在用这个标题 {{content}} 写博客文章。
这些是我已经写好的段落：{{content}}。
添加一个与章节 "{{section}}" 相关的段落。
不要重复前面段落的信息。
使用这种语气：{{tone}}。
也评估这些附加信息：{{additionalInformation}}。`,
                ko: `이 제목으로 블로그 글을 쓰고 있습니다: "{{title}}".
이전에 작성한 문단: "{{content}}".
섹션 "{{section}}"과 관련된 문단을 추가하세요.
이전 문단의 정보를 반복하지 마세요.
이 톤을 사용하세요: {{tone}}.
이 추가 정보도 평가하세요: {{additionalInformation}}.`,
                ru: `Я пишу блог-статью с этим заголовком: "{{title}}".
Это абзацы, которые я уже написал: "{{content}}".
Добавьте абзац, который относится к разделу "{{section}}".
Не повторяйте информацию из предыдущих абзацев.
Используйте этот тон: {{tone}}.
Также оцените эту дополнительную информацию: {{additionalInformation}}.`,
                tr: `Bu başlıkla bir blog makalesi yazıyorum: "{{title}}".
Bu bölümleri yazdım: "{{content}}".
"{{section}}" bölümüne uygun bir paragraf ekleyin.
Önceki paragraflardan bilgi tekrarlamayın.
Bu tonu kullanın: {{tone}}.
Ayrıca bu ek bilgiyi de değerlendirin: {{additionalInformation}}.`,
                id: `Saya sedang menulis posting blog dengan judul ini: "{{title}}".
Ini adalah paragraf yang sudah saya tulis: "{{content}}".
Tambahkan paragraf yang relevan dengan bagian "{{section}}".
Jangan ulangi informasi dari paragraf sebelumnya.
Gunakan nada ini: {{tone}}.
Evaluasi juga informasi tambahan ini: {{additionalInformation}}.`,
                hi: `मैं इस शीर्षक के साथ एक ब्लॉग पोस्ट लिख रहा हूं: "{{title}}".
यहाँ पहले से ही लिखे गए अनुच्छेद हैं: "{{content}}".
"{{section}}" अनुभाग के लिए एक अनुच्छेद जोड़ें।
पिछले अनुच्छेदों से जानकारी दोहराने से बचें।
इस टोन का उपयोग करें: {{tone}}.
इस अतिरिक्त जानकारी को भी मूल्यांकन करें: {{additionalInformation}}.`,
                th: `ฉันกำลังเขียนบทความบล็อกด้วยชื่อนี้: "{{title}}".
นี่คือย่อหน้าที่ฉันเขียนไว้แล้ว: "{{content}}".
เพิ่มย่อหน้าที่เกี่ยวข้องกับส่วน "{{section}}".
อย่าซ้ำข้อมูลจากย่อหน้าก่อนหน้านี้
ใช้เสียงนี้: {{tone}}.
ประเมินข้อมูลเพิ่มเติมนี้ด้วย: {{additionalInformation}}.`,
                other: `I'm writing a blog post with this title: "{{title}}".
These are the paragraphs I've already written: "{{content}}".
Add a paragraph that is relevant to the section "{{section}}".
Don't reapeat the information from the previous paragraphs.
Use this tone: {{tone}}.
Evaluate also this additional information: {{additionalInformation}}.`,
        },
        'intro': {
                it: "Devi scrivere un articolo di blog che sia facile da leggere e che aiuti i tuoi lettori a comprendere il topic",
                en: "You must write a blog post that is easy to read and that will help your readers to understand the topic.",
                fr: "Vous devez écrire un article de blog qui est facile à lire et qui aidera vos lecteurs à comprendre le sujet.",
                de: "Sie müssen einen Blog-Artikel schreiben, der leicht zu lesen ist und Ihren Lesern hilft, das Thema zu verstehen.",
                es: "Debes escribir un artículo de blog que sea fácil de leer y que ayude a tus lectores a comprender el tema.",
                nl: "U moet een blogartikel schrijven dat gemakkelijk te lezen is en dat uw lezers helpt het onderwerp te begrijpen.",
                pl: "Musisz napisać blog, który jest łatwy do przeczytania i pomoże twoim czytelnikom zrozumieć temat.",
                pt: "Você deve escrever um artigo de blog que seja fácil de ler e que ajude seus leitores a entender o tópico.",
                ja: "読みやすく、読者がトピックを理解するのに役立つブログ記事を書く必要があります。",
                zh: "您必须写一篇易于阅读的博客文章，帮助读者理解主题。",
                ko: "읽기 쉬운 블로그 글을 작성하고, 독자가 주제를 이해하는 데 도움이 되도록 해야 합니다.",
                ru: "Вы должны написать блог-статью, которая легко читается и помогает вашим читателям понять тему.",
                tr: "Okuyucularınızın konuyu anlamasına yardımcı olacak ve okunması kolay bir blog yazısı yazmalısınız.",
                id: "Anda harus menulis posting blog yang mudah dibaca dan membantu pembaca Anda memahami topik.",
                hi: "आपको एक ब्लॉग पोस्ट लिखना होगा जो आसानी से पढ़ा जा सके और आपके पाठकों को विषय को समझने में मदद करेगा।",
                th: "คุณต้องเขียนบทความบล็อกที่ง่ายต่อการอ่านและจะช่วยให้ผู้อ่านของคุณเข้าใจหัวข้อ",
                other: "You must write a blog post that is easy to read and that will help your readers to understand the topic.",
        },
        'theTitleIs': {
                it: "Stai scrivendo un articolo con questo titolo: {{title}}",
                en: "You are writing a blog post with this title: {{title}}",
                fr: "Vous écrivez un article de blog avec ce titre: {{title}}",
                de: "Sie schreiben einen Blog-Artikel mit diesem Titel: {{title}}",
                es: "Estás escribiendo un artículo de blog con este título: {{title}}",
                pl: "Piszesz blog z tym tytułem: {{title}}",
                nl: "U schrijft een blogartikel met deze titel: {{title}}",
                pt: "Você está escrevendo um artigo de blog com este título: {{title}}",
                ja: "このタイトルでブログ記事を書いています: {{title}}",
                zh: "您正在使用此标题写博客文章：{{title}}",
                ko: "이 제목으로 블로그 글을 쓰고 있습니다: {{title}}",
                ru: "Вы пишете блог-статью с этим заголовком: {{title}}",
                tr: "Bu başlıkla bir blog makalesi yazıyorsunuz: {{title}}",
                id: "Anda sedang menulis posting blog dengan judul ini: {{title}}",
                hi: "आप इस शीर्षक के साथ एक ब्लॉग पोस्ट लिख रहे हैं: {{title}}",
                th: "คุณกำลังเขียนบทความบล็อกด้วยชื่อนี้: {{title}}",
                other: "You are writing a blog post with this title: {{title}}",
        },
        'keepWordLength': {
                it: "È importante che tu consideri un articolo di lunghezza {{content}}",
                en: "It is important that you consider an article of length {{content}}",
                fr: "Il est important que vous considériez un article de longueur {{content}}",
                de: "Es ist wichtig, dass Sie einen Artikel der Länge {{content}} berücksichtigen",
                es: "Es importante que consideres un artículo de longitud {{content}}",
                nl: "Het is belangrijk dat u een artikel van lengte {{content}} overweegt",
                pl: "Ważne jest, abyś rozważył artykuł o długości {{content}}",
                pt: "É importante que você considere um artigo de comprimento {{content}}",
                ja: "長さ{{content}}の記事を考慮することが重要です",
                zh: "考虑一篇{{content}}的文章很重要",
                ko: "길이가 {{content}} 인 기사를 고려하는 것이 중요합니다",
                ru: "Важно, чтобы вы рассмотрели статью длиной {{content}}",
                tr: "Bir makale uzunluğu {{content}} olduğunu düşünmenin önemli olduğunu unutmayın",
                id: "Sangat penting bagi Anda untuk mempertimbangkan artikel dengan panjang {{content}}",
                hi: "एक लंबाई {{content}} के लिए एक लेख विचार करना महत्वपूर्ण है",
                th: "คุณควรพิจารณาบทความที่มีความยาว {{content}}",
                other: "It is important that you consider an article of length {{content}}"
        },
        'keepTone': {
                it: "È importante che tu mantenga questo tono: {{tone}}",
                en: "It is important that you keep this tone: {{tone}}",
                fr: "Il est important que vous gardiez ce ton: {{tone}}",
                de: "Es ist wichtig, dass Sie diesen Ton beibehalten: {{tone}}",
                es: "Es importante que mantengas este tono: {{tone}}",
                nl: "Het is belangrijk dat u deze toon aanhoudt: {{tone}}",
                pl: "Ważne jest, abyś zachował ten ton: {{tone}}",
                pt: "É importante que você mantenha este tom: {{tone}}",
                ja: "このトーンを維持することが重要です: {{tone}}",
                zh: "保持这种语气很重要：{{tone}}",
                ko: "이 톤을 유지하는 것이 중요합니다: {{tone}}",
                ru: "Важно, чтобы вы сохраняли этот тон: {{tone}}",
                tr: "Bu tonu korumanız önemlidir: {{tone}}",
                id: "Sangat penting bagi Anda untuk mempertahankan nada ini: {{tone}}",
                hi: "यह महत्वपूर्ण है कि आप इस टोन को रखें: {{tone}}",
                th: "สำคัญที่คุณจะเก็บเสียงนี้: {{tone}}",
                other: "It is important that you keep this tone: {{tone}}",
        },
        'useKeywords': {
                it: "Utilizza le seguenti parole chiave per poterlo indicizzare nei motori di ricerca: {{content}}",
                en: "Use the following keywords to index it in search engines: {{content}}",
                fr: "Utilisez les mots clés suivants pour l'indexer dans les moteurs de recherche: {{content}}",
                de: "Verwenden Sie die folgenden Schlüsselwörter, um es in Suchmaschinen zu indexieren: {{content}}",
                es: "Utiliza las siguientes palabras clave para indexarlo en los motores de búsqueda: {{content}}",
                nl: "Gebruik de volgende trefwoorden om het te indexeren in zoekmachines: {{content}}",
                pl: "Użyj następujących słów kluczowych, aby zaindeksować je w wyszukiwarkach: {{content}}",
                id: "Gunakan kata kunci berikut untuk mengindeksnya di mesin pencari: {{content}}",
                pt: "Use as seguintes palavras-chave para indexá-lo nos motores de busca: {{content}}",
                ja: "次のキーワードを使用して、検索エンジンにインデックスを付けてください: {{content}}",
                zh: "使用以下关键字将其索引到搜索引擎中：{{content}}",
                ko: "다음 키워드를 사용하여 검색 엔진에 색인을 만듭니다: {{content}}",
                ru: "Используйте следующие ключевые слова для индексации в поисковых системах: {{content}}",
                tr: "Aşağıdaki anahtar kelimeleri kullanarak arama motorlarında indekslemesini sağlayın: {{content}}",
                hi: "इसे खोज इंजन में इंडेक्स करने के लिए निम्नलिखित कीवर्ड्स का उपयोग करें: {{content}}",
                th: "ใช้คำหลักต่อไปนี้เพื่อดัชนีในเครื่องมือค้นหา: {{content}}",
                other: "Use the following keywords to index it in search engines: {{content}}",
        },
        'useAdditional': {
                it: "Nella scrittura ricorda di considerare anche queste informazioni aggiuntive: {{additionalInformation}}",
                en: "When writing, remember to also consider these additional information: {{additionalInformation}}",
                fr: "Lors de l'écriture, n'oubliez pas de prendre en compte ces informations supplémentaires: {{additionalInformation}}",
                de: "Beim Schreiben denken Sie daran, auch diese zusätzlichen Informationen zu berücksichtigen: {{additionalInformation}}",
                es: "Al escribir, recuerda considerar también esta información adicional: {{additionalInformation}}",
                nl: "Bij het schrijven, vergeet niet ook deze aanvullende informatie te overwegen: {{additionalInformation}}",
                pl: "Podczas pisania pamiętaj, aby również wziąć pod uwagę te dodatkowe informacje: {{additionalInformation}}",
                pt: "Ao escrever, lembre-se de considerar também estas informações adicionais: {{additionalInformation}}",
                ja: "書き込み時に、これらの追加情報も考慮することを忘れないでください: {{additionalInformation}}",
                zh: "在写作时，请记住也要考虑这些附加信息：{{additionalInformation}}",
                ko: "작성할 때 이러한 추가 정보도 고려해야합니다: {{additionalInformation}}",
                ru: "При написании не забудьте также учесть эти дополнительные сведения: {{additionalInformation}}",
                id: "Saat menulis, ingat juga untuk mempertimbangkan informasi tambahan ini: {{additionalInformation}}",
                tr: "Yazarken, ayrıca bu ek bilgileri de göz önünde bulundurmayı unutmayın: {{additionalInformation}}",
                hi: "लिखते समय ये अतिरिक्त जानकारी भी ध्यान में रखें: {{additionalInformation}}",
                th: "ในการเขียนโปรดจำว่าคุณควรพิจารณาข้อมูลเพิ่มเติมดังต่อไปนี้: {{additionalInformation}}",
                other: "When writing, remember to also consider these additional information: {{additionalInformation}}",
        },
        'theCurrentHeadingsAre': {
                it: "I titoli che hai già scritto sono: {{content}}",
                en: "The headings you have already written are: {{content}}",
                fr: "Les titres que vous avez déjà écrits sont: {{content}}",
                de: "Die Überschriften, die Sie bereits geschrieben haben, sind: {{content}}",
                es: "Los títulos que ya has escrito son: {{content}}",
                nl: "De koppen die u al hebt geschreven, zijn: {{content}}",
                pl: "Nagłówki, które już napisałeś, to: {{content}}",
                pt: "Os títulos que você já escreveu são: {{content}}",
                ja: "すでに書いたヘッディングは次のとおりです: {{content}}",
                zh: "您已经写的标题是：{{content}}",
                ko: "이미 작성한 제목은 다음과 같습니다: {{content}}",
                ru: "Заголовки, которые вы уже написали, такие: {{content}}",
                id: "Judul yang sudah Anda tulis adalah: {{content}}",
                tr: "Zaten yazdığınız başlıklar şunlardır: {{content}}",
                hi: "आपने पहले ही लिखे हुए हेडिंग्स हैं: {{content}}",
                th: "หัวข้อที่คุณเขียนไว้แล้วคือ: {{content}}",
                other: "The headings you have already written are: {{content}}",
        },
        'theParagraphsAre': {
                it: "I paragrafi che hai già scritto sotto il titolo: '{{title}}' sono: {{content}}",
                en: "You already have some paragraphs under the header: '{{title}}'. This is it: {{content}}",
                fr: "Vous avez déjà quelques paragraphes sous l'en-tête: '{{title}}'. Voici: {{content}}",
                de: "Sie haben bereits einige Absätze unter der Überschrift: '{{title}}'. Das ist es: {{content}}",
                es: "Ya tienes algunos párrafos bajo el encabezado: '{{title}}'. Esto es: {{content}}",
                nl: "U heeft al enkele paragrafen onder de kop: '{{title}}'. Dit is het: {{content}}",
                pl: "Masz już kilka akapitów pod nagłówkiem: '{{title}}'. To jest to: {{content}}",
                pt: "Você já tem alguns parágrafos sob o título: '{{title}}'. É isso: {{content}}",
                ja: "ヘッダー: '{{title}}'の下にすでにいくつかの段落があります。これはそれです: {{content}}",
                zh: "您已经在标题下有一些段落了：'{{title}}'。这就是：{{content}}",
                ko: "헤더: '{{title}}' 아래에 이미 몇 가지 단락이 있습니다. 이것은 그것입니다: {{content}}",
                ru: "У вас уже есть несколько абзацев под заголовком: '{{title}}'. Вот это: {{content}}",
                id: "Anda sudah memiliki beberapa paragraf di bawah header: '{{title}}'. Ini dia: {{content}}",
                tr: "Başlığın altında zaten bazı paragraflarınız var. Bu: {{content}}",
                hi: "शीर्षक: '{{title}}' के नीचे आपके पास पहले से ही कुछ पैराग्राफ हैं। यह है: {{content}}",
                th: "คุณมีบางส่วนของย่อหน้าอยู่ใต้หัวข้อ: '{{title}}' นี้แล้ว นี่คือ: {{content}}",
                other: "You already have some paragraphs under the header: '{{title}}'. This is it: {{content}}",
        },
        'contentInHeadings': {
                it: "Devi aggiungere il contenuto mancante sotto al titolo: '{{content}}'. Tutto va aggiunto ai paragrafi già esistenti che ti ho descritto nel messaggio precedente. Non aggiungere riferimenti ad altri titoli. Non ripetere contenuti già esistenti. Scrivi solo il risultato con codice HTML. ",
                en: "You need to add the missing content under the header: '{{content}}'. Everything needs to be added to the already existing paragraphs that I described to you in the previous message. Do not add references to other headings. Do not repeat existing content. Write only the result with HTML code. ",
                fr: "Vous devez ajouter le contenu manquant sous le titre : '{{content}}'. Tout doit être ajouté aux paragraphes déjà existants décrits dans le message précédent. N'ajoutez pas de références à d'autres titres. Ne répétez pas le contenu existant. Écrivez uniquement le résultat avec le code HTML.",
                de: "Sie müssen den fehlenden Inhalt unter der Überschrift: '{{content}}' hinzufügen. Alles muss zu den bereits vorhandenen Absätzen hinzugefügt werden, die ich Ihnen in der vorherigen Nachricht beschrieben habe. Fügen Sie keine Verweise auf andere Überschriften hinzu. Wiederholen Sie nicht vorhandene Inhalte. Schreiben Sie nur das Ergebnis mit HTML-Code.",
                es: "Necesitas agregar el contenido faltante bajo el encabezado: '{{content}}'. Todo debe agregarse a los párrafos ya existentes que te describí en el mensaje anterior. No agregue referencias a otros encabezados. No repitas contenido existente. Escribe solo el resultado con código HTML.",
                nl: "U moet de ontbrekende inhoud toevoegen onder de kop: '{{content}}'. Alles moet worden toegevoegd aan de reeds bestaande alinea's die ik u in het vorige bericht heb beschreven. Voeg geen verwijzingen naar andere koppen toe. Herhaal geen bestaande inhoud. Schrijf alleen het resultaat met HTML-code.",
                pl: "Musisz dodać brakującą zawartość pod nagłówkiem: '{{content}}'. Wszystko musi zostać dodane do już istniejących akapitów, które opisałem w poprzedniej wiadomości. Nie dodawaj odwołań do innych nagłówków. Nie powtarzaj istniejącej zawartości. Napisz tylko wynik z kodem HTML.",
                pt: "Você precisa adicionar o conteúdo faltante sob o título: '{{content}}'. Tudo precisa ser adicionado aos parágrafos já existentes que descrevi para você na mensagem anterior. Não adicione referências a outros títulos. Não repita conteúdo existente. Escreva apenas o resultado com código HTML.",
                ja: "ヘッダー: '{{content}}'の下に不足しているコンテンツを追加する必要があります。すべては既存の段落に追加する必要があり、それは前のメッセージで説明した通りです。他の見出しに関する参照を追加しないでください。既存のコンテンツを繰り返さないでください。HTMLコードで結果のみを書き込んでください。",
                zh: "您需要在标题下添加缺失的内容：'{{content}}'。所有内容都需要添加到我在之前的消息中描述给您的现有段落中。不要添加对其他标题的引用。不要重复现有内容。只需用HTML代码编写结果。",
                ko: "헤더: '{{content}}' 아래에 누락 된 콘텐츠를 추가해야합니다. 이미 존재하는 단락에 추가해야하는 모든 내용은 이전 메시지에서 설명한 것입니다. 다른 헤더에 대한 참조를 추가하지 마십시오. 기존 콘텐츠를 반복하지 마십시오. HTML 코드로 결과만 작성하십시오.",
                ru: "Вам нужно добавить отсутствующий контент под заголовком: '{{content}}'. Все должно быть добавлено в уже существующие абзацы, о которых я рассказал вам в предыдущем сообщении. Не добавляйте ссылки на другие заголовки. Не повторяйте существующий контент. Пишите только результат с HTML-кодом.",
                id: "Anda perlu menambahkan konten yang hilang di bawah header: '{{content}}'. Semuanya perlu ditambahkan ke paragraf yang sudah ada yang saya jelaskan kepada Anda dalam pesan sebelumnya. Jangan menambahkan referensi ke judul lain. Jangan mengulangi konten yang sudah ada. Tulis hanya hasilnya dengan kode HTML.",
                tr: "Başlık altına eksik içeriği eklemeniz gerekiyor: '{{content}}'. Her şey, daha önceki mesajda size anlattığım mevcut paragraflara eklenmelidir. Başka başlıklara atıfta bulunmayın. Mevcut içeriği tekrarlamayın. Sadece HTML koduyla sonucu yazın.",
                hi: "शीर्षक: '{{content}}' के नीचे गुम गया सामग्री जोड़ने की आवश्यकता है। सब कुछ पहले से मौजूद पैराग्राफ्स में जोड़ा जाना चाहिए, जिसे मैंने पिछले संदेश में आपको विवरण दिया था। अन्य शीर्षकों के प्रति संदर्भ न जोड़ें। मौजूदा सामग्री को दोहराना नहीं। HTML कोड के साथ केवल परिणाम लिखें।",
                th: "คุณต้องเพิ่มเนื้อหาที่หายไปใต้หัวข้อ: '{{content}}' ทั้งหมดต้องถูกเพิ่มเข้าไปในย่อหน้าที่มีอยู่แล้วที่ฉันได้อธิบายให้คุณในข้อความก่อนหน้า อย่าเพิ่มการอ้างอิงถึงหัวข้ออื่น ๆ อย่าทำซ้ำเนื้อหาที่มีอยู่แล้ว เขียนผลลัพธ์เฉพาะด้วยรหัส HTML.",
                other: "You need to add the missing content under the header: '{{content}}'. Everything needs to be added to the already existing paragraphs that I described to you in the previous message. Do not add references to other headings. Do not repeat existing content. Write only the result with HTML code."
        },
        'translateIntro': {
                en: "You are an expert English translator. You need to help me to translate some content for a blog post. I will provide you with the original text in a different language and you will need to translate it to your native language. If the message is in HTML keep the same formatting style else use just plain text. Don't translate the links. Don't add any extra text. Just translate the content.",
                it: "Sei un traduttore Italiano esperto. Devi aiutarmi a tradurre del contenuto per un post di blog. Ti fornirò il testo originale in un'altra lingua e dovrai tradurlo nella tua lingua madre. Se il messaggio è in HTML, mantieni lo stesso stile di formattazione, altrimenti usa solo il testo semplice. Non tradurre i link. Non aggiungere alcun testo aggiuntivo. Basta tradurre il contenuto.",
                fr: "Vous êtes un traducteur français expert. Vous devez m'aider à traduire du contenu pour un article de blog. Je vous fournirai le texte original dans une autre langue et vous devrez le traduire dans votre langue maternelle. Si le message est en HTML, conservez le même style de mise en forme, sinon utilisez simplement du texte brut. Ne traduisez pas les liens. Ne rajoutez aucun texte supplémentaire. Il suffit de traduire le contenu.",
                de: "Sie sind ein erfahrener deutscher Übersetzer. Sie müssen mir helfen, den Inhalt eines Blog-Posts zu übersetzen. Ich werde Ihnen den Originaltext in einer anderen Sprache zur Verfügung stellen und Sie müssen ihn in Ihre Muttersprache übersetzen. Wenn die Nachricht in HTML ist, behalten Sie den gleichen Formatierungsstil, andernfalls verwenden Sie nur einfachen Text. Übersetzen Sie keine Links. Fügen Sie keinen zusätzlichen Text hinzu. Übersetzen Sie nur den Inhalt.",
                es: "Eres un traductor español experto. Necesitas ayudarme a traducir algún contenido para una publicación de blog. Te proporcionaré el texto original en un idioma diferente y deberás traducirlo a tu idioma nativo. Si el mensaje está en HTML, mantén el mismo estilo de formato, de lo contrario usa solo texto plano. No traduzcas los enlaces. No agregues ningún texto adicional. Simplemente traduce el contenido.",
                nl: "U bent een ervaren Nederlandse vertaler. U moet me helpen om de inhoud van een blogbericht te vertalen. Ik zal u de originele tekst in een andere taal geven en u moet deze vertalen in uw moedertaal. Als het bericht in HTML is, houdt u hetzelfde opmaakstijl aan, anders gebruikt u alleen platte tekst. Vertaal geen links. Voeg geen extra tekst toe. Vertaal alleen de inhoud.",
                pl: "Jesteś doświadczonym polskim tłumaczem. Musisz mi pomóc przetłumaczyć treść posta na blogu. Przedstawię Ci oryginalny tekst w innym języku, a Ty będziesz musiał go przetłumaczyć na swój ojczysty język. Jeśli wiadomość jest w HTML, zachowaj ten sam styl formatowania, w przeciwnym razie użyj zwykłego tekstu. Nie tłumacz linków. Nie dodawaj żadnego dodatkowego tekstu. Po prostu przetłumacz treść.",
                pt: "Você é um tradutor experiente em Português. Você precisa me ajudar a traduzir algum conteúdo para uma postagem de blog. Eu vou te fornecer o texto original em um idioma diferente e você precisará traduzi-lo para sua língua nativa. Se a mensagem estiver em HTML, mantenha o mesmo estilo de formatação, caso contrário, use apenas texto simples. Não traduza os links. Não adicione nenhum texto extra. Apenas traduza o conteúdo.",
                ja: "あなたは上級英語翻訳者です。ブログ記事のコンテンツを翻訳するのを手伝ってください。私は、他の言語でのオリジナルテキストを提供します。あなたはそれをネイティブ言語に翻訳する必要があります。メッセージがHTMLの場合は、同じ書式設定スタイルを維持します。そうでない場合は、単純なテキストを使用します。リンクを翻訳しないでください。追加のテキストを追加しないでください。コンテンツを翻訳するだけです。",
                zh: "您是一位专业的英语翻译者。您需要帮助我翻译一些内容，以便于博客文章。我将为您提供原始文本，您需要将其翻译成您的母语。如果消息是HTML，请保持相同的格式样式，否则使用纯文本。不要翻译链接。不要添加任何额外的文本。只需翻译内容。",
                ko: "당신은 전문 영어 번역가입니다. 당신은 블로그 게시물의 내용을 번역하는 데 도움이 필요합니다. 원래 텍스트를 다른 언어로 제공하고 당신은 당신의 모국어로 번역해야합니다. 메시지가 HTML 인 경우 동일한 서식 스타일을 유지하십시오. 그렇지 않으면 일반 텍스트 만 사용하십시오. 링크를 번역하지 마십시오. 추가 텍스트를 추가하지 마십시오. 단지 내용을 번역하십시오.",
                ru: "Вы экспертный английский переводчик. Вам нужно помочь мне перевести некоторый контент для поста в блоге. Я предоставлю вам оригинальный текст на другом языке, и вам нужно будет перевести его на ваш родной язык. Если сообщение в HTML, сохраните тот же стиль форматирования, иначе используйте простой текст. Не переводите ссылки. Не добавляйте дополнительный текст. Просто переведите содержание.",
                id: "Anda adalah penerjemah bahasa Inggris yang ahli. Anda perlu membantu saya menerjemahkan beberapa konten untuk posting blog. Saya akan memberi Anda teks asli dalam bahasa lain dan Anda harus menerjemahkannya ke bahasa ibu Anda. Jika pesan dalam HTML, pertahankan gaya format yang sama, jika tidak gunakan hanya teks biasa. Jangan menerjemahkan tautan. Jangan menambahkan teks tambahan. Hanya menerjemahkan konten.",
                tr: "Uzman İngilizce çevirmenisiniz. Bir blog yazısının içeriğini çevirmem için yardımcı olmanız gerekiyor. Farklı bir dildeki orijinal metni size vereceğim ve sizin anadilinize çevirmeniz gerekiyor. Mesaj HTML ise aynı biçimlendirme stiline sahip kalın, aksi takdirde sadece düz metin kullanın. Bağlantıları çevirmeyin. Ekstra metin eklemeyin. Sadece içeriği çevirin.",
                hi: "आप एक विशेषज्ञ अंग्रेजी अनुवादक हैं। आप मुझे एक ब्लॉग पोस्ट के कुछ सामग्री का अनुवाद करने में मदद करने की आवश्यकता है। मैं आपको एक अन्य भाषा में मूल पाठ प्रदान करूंगा और आपको अपनी मातृभाषा में इसे अनुवादित करना होगा। यदि संदेश HTML है, तो उसी प्रारूप शैली को बनाए रखें, अन्यथा केवल सादे पाठ का उपयोग करें। लिंकों का अनुवाद न करें। कोई अतिरिक्त पाठ न जोड़ें। केवल सामग्री का अनुवाद करें।",
                th: "คุณเป็นผู้แปลภาษาอังกฤษที่มีประสบการณ์ คุณต้องช่วยฉันแปลบางเนื้อหาเพื่อให้เป็นบทความบล็อก ฉันจะให้คุณข้อความต้นฉบับในภาษาอื่นและคุณต้องแปลเป็นภาษาแม่ของคุณ ถ้าข้อความเป็น HTML ให้เก็บรูปแบบการจัดรูปแบบเดียวกัน หากไม่ใช่ให้ใช้ข้อความธรรมดา อย่าแปลลิงก์ อย่าเพิ่มข้อความเพิ่มเติม แปลเฉพาะเนื้อหา",
                other: "You are an experienced English translator. You need to help me to translate some content for a blog post. I will provide you with the original text in another language and you will need to translate it into your native language. If the message is HTML, keep the same formatting style, otherwise use plain text. Do not translate links. Do not add any additional text. Just translate the content."
        },
        'translateContent': {
                en: "Here the original text in another language.\n{{content}}",
                it: "Ecco il testo originale in un'altra lingua.\n{{content}}",
                fr: "Voici le texte original dans une autre langue.\n{{content}}",
                de: "Hier ist der ursprüngliche Text in einer anderen Sprache.\n{{content}}",
                es: "Aquí está el texto original en otro idioma.\n{{content}}",
                nl: "Hier is de originele tekst in een andere taal.\n{{content}}",
                pl: "Oto oryginalny tekst w innym języku.\n{{content}}",
                pt: "Aqui está o texto original em outro idioma.\n{{content}}",
                ja: "別の言語でのオリジナルテキストです。\n{{content}}",
                zh: "这是另一种语言中的原始文本。\n{{content}}",
                ko: "여기에는 다른 언어의 원본 텍스트가 있습니다.\n{{content}}",
                ru: "Вот оригинальный текст на другом языке.\n{{content}}",
                tr: "Burada başka bir dildeki orijinal metin.\n{{content}}",
                id: "Berikut teks asli dalam bahasa lain.\n{{content}}",
                hi: "यहां एक अन्य भाषा में मूल पाठ है।\n{{content}}",
                th: "นี่คือข้อความต้นฉบับในภาษาอื่น\n{{content}}",
                other: "Here the original text in another language.\n{{content}}"
        },
        'rewriteIntro': {
                en: "You are an advanced copywriter. You need to help me to rewrite a blog post",
                it: "Sei un copywriter avanzato. Devi aiutarmi a riscrivere un post di blog",
                fr: "Vous êtes un copywriter avancé. Vous devez m'aider à réécrire un article de blog",
                de: "Sie sind ein fortgeschrittener Copywriter. Sie müssen mir helfen, einen Blog-Post zu überarbeiten",
                es: "Eres un copywriter avanzado. Necesitas ayudarme a reescribir una publicación de blog",
                nl: "U bent een ervaren copywriter. U moet me helpen een blogbericht te herschrijven",
                pl: "Jesteś zaawansowanym copywriterem. Musisz mi pomóc przepisać post na blogu",
                pt: "Você é um copywriter avançado. Você precisa me ajudar a reescrever uma postagem de blog",
                ja: "あなたは上級コピーライターです。ブログ記事を書き直すのを手伝ってください",
                zh: "您是一名高级文案撰稿人。您需要帮助我重写博客文章",
                ko: "고급 사본 작성자입니다. 블로그 게시물을 다시 작성하는 데 도움이 필요합니다",
                ru: "Вы продвинутый копирайтер. Вам нужно помочь мне переписать пост в блоге",
                tr: "Gelişmiş bir kopya yazarısınız. Bir blog yazısını yeniden yazmama yardımcı olmalısınız",
                id: "Anda adalah penulis salinan lanjutan. Anda perlu membantu saya menulis ulang pos blog",
                hi: "आप एक उन्नत कॉपीराइटर हैं। मुझे एक ब्लॉग पोस्ट को फिर से लिखने में मदद करने की आवश्यकता है",
                th: "คุณเป็นนักเขียนสำเนาขั้นสูง คุณต้องช่วยฉันเขียนบทความบล็อกใหม่",
                other: "You are an advanced copywriter. You need to help me to rewrite a blog post",
        },
        'rewriteContent': {
                en: "This is the content i need to rewrite: {{content}}",
                it: "Questo è il contenuto che devo riscrivere: {{content}}",
                fr: "Voici le contenu que je dois réécrire: {{content}}",
                de: "Dies ist der Inhalt, den ich neu schreiben muss: {{content}}",
                es: "Este es el contenido que necesito reescribir: {{content}}",
                nl: "Dit is de inhoud die ik opnieuw moet schrijven: {{content}}",
                pl: "To jest treść, którą muszę przepisać: {{content}}",
                pt: "Este é o conteúdo que preciso reescrever: {{content}}",
                ja: "これは私が書き直す必要があるコンテンツです: {{content}}",
                zh: "这是我需要重写的内容：{{content}}",
                ko: "이것은 다시 써야 할 내용입니다: {{content}}",
                ru: "Это контент, который я должен переписать: {{content}}",
                tr: "Bu, yeniden yazmam gereken içeriktir: {{content}}",
                id: "Ini adalah konten yang perlu saya tulis ulang: {{content}}",
                hi: "यह मुझे फिर से लिखने की आवश्यकता है: {{content}}",
                th: "นี่คือเนื้อหาที่ฉันต้องเขียนใหม่: {{content}}",
                other: "This is the content i need to rewrite: {{content}}",
        },
        'rewriteInstructions': {
                en: "This are the rewriting instructions: {{content}}. Format the result as HTML",
                it: "Queste sono le istruzioni per la riscrittura: {{content}}. Formatta il risultato come HTML",
                fr: "Voici les instructions de réécriture: {{content}}. Formatez le résultat en HTML",
                de: "Dies sind die Überarbeitungsanweisungen: {{content}}. Formatieren Sie das Ergebnis als HTML",
                es: "Estas son las instrucciones de reescritura: {{content}}. Formatea el resultado como HTML",
                nl: "Dit zijn de instructies voor herschrijven: {{content}}. Formatteer het resultaat als HTML",
                pl: "Oto instrukcje przepisywania: {{content}}. Sformatuj wynik jako HTML",
                pt: "Estas são as instruções de reescrita: {{content}}. Formate o resultado como HTML",
                ja: "これは書き直しの手順です: {{content}}. 結果をHTMLとしてフォーマットします",
                zh: "这是重写说明：{{content}}. 将结果格式化为HTML",
                ko: "이것은 다시 쓰는 지침입니다: {{content}}. 결과를 HTML로 형식 지정하십시오",
                ru: "Это инструкции по переписыванию: {{content}}. Форматируйте результат как HTML",
                tr: "Bu, yeniden yazma talimatlarıdır: {{content}}. Sonucu HTML olarak biçimlendirin",
                id: "Ini adalah instruksi penulisan ulang: {{content}}. Format hasil sebagai HTML",
                hi: "यह फिर से लिखने के निर्देश हैं: {{content}}. परिणाम को HTML के रूप में स्वरूपित करें",
                th: "นี่คือคำแนะนำในการเขียนใหม่: {{content}}. จัดรูปแบบผลลัพธ์เป็น HTML",
                other: "This are the rewriting instructions: {{content}}. Format the result as HTML",
        }
}

// TODO: instructions, toc, content

export const DEFAULT_PROMPTS = {
        'tldr': {
                title: 'PROMPTS.TITLE.TLDR',
                description: 'PROMPTS.DESCRIPTION.TLDR',
                content: ``,
                temperature: 0.7,
                maxTokens: 2048,
                requiredParams: ['content'],
                isList: false,
                isToc: false
        },
        'description': {
                title: 'PROMPTS.TITLE.DESCRIPTION',
                description: 'PROMPTS.DESCRIPTION.DESCRIPTION',
                content: ``,
                temperature: 0.7,
                maxTokens: 2048,
                requiredParams: ['content'],
                isList: false,
                isToc: false
        },
        'keywords': {
                title: 'PROMPTS.TITLE.KEYWORDS',
                description: 'PROMPTS.DESCRIPTION.KEYWORDS',
                content: ``,
                temperature: 0.7,
                maxTokens: 2048,
                requiredParams: ['content'],
                isList: true,
                isToc: false
        },
        'instructions': {
                title: 'PROMPTS.TITLE.INSTRUCTIONS',
                description: 'PROMPTS.DESCRIPTION.INSTRUCTIONS',
                content: ``,
                temperature: 0.7,
                maxTokens: 1024,
                requiredParams: ['content'],
                isList: false,
                isToc: false
        },
        'topics': {
                title: 'PROMPTS.TITLE.TOPICS',
                description: 'PROMPTS.DESCRIPTION.TOPICS',
                content: ``,
                temperature: 0.7,
                maxTokens: 256,
                requiredParams: ['content'],
                isList: true,
                isToc: false
        },
        'titles': {
                title: 'PROMPTS.TITLE.TITLES',
                description: 'PROMPTS.DESCRIPTION.TITLES',
                content: ``,
                temperature: 0.7,
                maxTokens: 256,
                requiredParams: ['content'],
                isList: true,
                isToc: false
        },
        'toc': {
                title: 'PROMPTS.TITLE.TOC',
                description: 'PROMPTS.DESCRIPTION.TOC',
                content: ``,
                temperature: 0.7,
                maxTokens: 1024,
                requiredParams: ['content'],
                isList: true,
                isToc: true
        },
        'content': {
                title: 'PROMPTS.TITLE.CONTENT',
                description: 'PROMPTS.DESCRIPTION.CONTENT',
                content: ``,
                temperature: 0.7,
                maxTokens: 1024,
                requiredParams: ['content', 'section', 'title'],
                isList: false,
                isToc: false
        },
        'contentChat': {
                title: 'PROMPTS.TITLE.CONTENT_CHAT',
                description: 'PROMPTS.DESCRIPTION.CONTENT_CHAT',
                content: ``,
                temperature: 0.7,
                maxTokens: 1024,
                requiredParams: ['content', 'section', 'title'],
                isList: false,
                isToc: false
        },
        'translateIntro': {
                title: 'PROMPTS.TITLE.TRANSLATE_INTRO',
                description: 'PROMPTS.DESCRIPTION.TRANSLATE_INTRO',
                content: ``,
                temperature: 0.7,
                maxTokens: 512,
                requiredParams: [],
                isList: false,
                isToc: false
        },
        'translateContent': {
                title: 'PROMPTS.TITLE.TRANSLATE_CONTENT',
                description: 'PROMPTS.DESCRIPTION.TRANSLATE_CONTENT',
                content: ``,
                temperature: 0.7,
                maxTokens: 1024,
                requiredParams: ['content'],
                isList: false,
                isToc: false
        },
        'rewriteIntro': {
                title: 'PROMPTS.TITLE.REWRITE_INTRO',
                description: 'PROMPTS.DESCRIPTION.REWRITE_INTRO',
                content: ``,
                temperature: 0.7,
                maxTokens: 512,
                requiredParams: [],
                isList: false,
                isToc: false
        },
        'rewriteContent': {
                title: 'PROMPTS.TITLE.REWRITE_CONTENT',
                description: 'PROMPTS.DESCRIPTION.REWRITE_CONTENT',
                content: ``,
                temperature: 0.7,
                maxTokens: 1024,
                requiredParams: ['content'],
                isList: false,
                isToc: false
        },
        'rewriteInstructions': {
                title: 'PROMPTS.TITLE.REWRITE_INSTRUCTIONS',
                description: 'PROMPTS.DESCRIPTION.REWRITE_INSTRUCTIONS',
                content: ``,
                temperature: 0.7,
                maxTokens: 1024,
                requiredParams: ['content'],
                isList: false,
                isToc: false
        },
        'intro': {
                title: 'PROMPTS.TITLE.INTRO',
                description: 'PROMPTS.DESCRIPTION.INTRO',
                content: ``,
                temperature: 0.7,
                maxTokens: 512,
                requiredParams: [],
                isList: false,
                isToc: false,
        },
        'theTitleIs': {
                title: 'PROMPTS.TITLE.THE_TITLE_IS',
                description: 'PROMPTS.DESCRIPTION.THE_TITLE_IS',
                content: ``,
                temperature: 0.7,
                maxTokens: 256,
                requiredParams: ['title'],
                isList: false,
                isToc: false
        },
        'keepTone': {
                title: 'PROMPTS.TITLE.KEEP_TONE',
                description: 'PROMPTS.DESCRIPTION.KEEP_TONE',
                content: ``,
                temperature: 0.7,
                maxTokens: 256,
                requiredParams: ['tone'],
                isList: false,
                isToc: false
        },
        'useKeywords': {
                title: 'PROMPTS.TITLE.USE_KEYWORDS',
                description: 'PROMPTS.DESCRIPTION.USE_KEYWORDS',
                content: ``,
                temperature: 0.7,
                maxTokens: 1024,
                requiredParams: ['keywords'],
                isList: false,
                isToc: false
        },
        'keepWordLength': {
                title: 'PROMPTS.TITLE.KEEP_WORDS',
                description: 'PROMPTS.DESCRIPTION.KEEP_WORDS',
                content: ``,
                temperature: 0.7,
                maxTokens: 256,
                requiredParams: ['content'],
                isList: false,
                isToc: false
        },
        'useAdditional': {
                title: 'PROMPTS.TITLE.USE_ADDITIONAL',
                description: 'PROMPTS.DESCRIPTION.USE_ADDITIONAL',
                content: ``,
                temperature: 0.7,
                maxTokens: 1024,
                requiredParams: ['additionalInformation'],
                isList: false,
                isToc: false
        },
        'theCurrentHeadingsAre': {
                title: 'PROMPTS.TITLE.THE_CURRENT_HEADINGS_ARE',
                description: 'PROMPTS.DESCRIPTION.THE_CURRENT_HEADINGS_ARE',
                content: ``,
                temperature: 0.7,
                maxTokens: 1024,
                requiredParams: ['content'],
                isList: false,
                isToc: false
        },
        'theParagraphsAre': {
                title: 'PROMPTS.TITLE.THE_PARAGRAPHS_ARE',
                description: 'PROMPTS.DESCRIPTION.THE_PARAGRAPHS_ARE',
                content: ``,
                temperature: 0.7,
                maxTokens: 1024,
                requiredParams: ['content', "title"],
                isList: false,
                isToc: false
        },
        'contentInHeadings': {
                title: 'PROMPTS.TITLE.CONTENT_IN_HEADINGS',
                description: 'PROMPTS.DESCRIPTION.CONTENT_IN_HEADINGS',
                content: ``,
                temperature: 0.7,
                maxTokens: 2048,
                requiredParams: ['content'],
                isList: false,
                isToc: false
        },
        'tocChat': {
                title: 'PROMPTS.TITLE.TOC_CHAT',
                description: 'PROMPTS.DESCRIPTION.TOC_CHAT',
                content: ``,
                temperature: 0.7,
                maxTokens: 1024,
                requiredParams: ['content'],
                isList: true,
                isToc: true
        },

}
