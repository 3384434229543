//
// ───────────────────────────────────────────────── DEPENDENCIES AND COSTANT ─────
//
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root',
})
export class TranslateCustomService {
  static instance: TranslateCustomService;

  constructor(public translateService: TranslateService) {
    TranslateCustomService.instance = this;
  }
}
