import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BasicPresenter } from '../classes/presenters/basicPresenter';
import { UserPresenter } from '../classes/presenters/userPresenter';
import { AiManager } from '../modules/shared/AiManager';
import { CbGlobalLoaderService } from '../widgets/cb-global-loader/cb-global-loader.service';
import { CbToastService } from '../widgets/cb-toast/cb-toast.service';
import { HttpService } from './http.service';
import { TranslateCustomService } from './translate-custom.service';
import { UploaderService } from './uploader.service';

@Injectable()
export class InitializerService {
  public userPresenter: UserPresenter;
  public basicPresenter: BasicPresenter;

  constructor(private readonly http: HttpClient,
    private readonly injector: Injector
  ) {
    /// CONTENT
    new HttpService(http);
    new CbToastService();
    new UploaderService(http, new CbGlobalLoaderService());
    this.userPresenter = new UserPresenter();
    this.basicPresenter = new BasicPresenter();
  }

  load() {
    return new Promise((resolve, reject) => {

      const browserLang = this.injector.get(TranslateService).getBrowserLang() ?? 'en';
      this.injector.get(TranslateService).use(browserLang.match(/en|it/) ? (browserLang) : 'en');
      new TranslateCustomService(this.injector.get(TranslateService));

      // Check if accessToken is expired
      if (this.userPresenter.isTokenExpired()) {
        return this.userPresenter.tokenRequested({ refreshToken: this.userPresenter.getRefreshToken() }).toPromise().then((data) => {
          if (this.basicPresenter.getSelectedRequested()) {
            return AiManager.loadBasic().then(() => {
              const lang = AiManager.basic?.lang ?? 'en';
              this.injector.get(TranslateService).use(lang == "Italian" ? 'it' : 'en');
              resolve(true);
            }).catch((err) => {
              console.error(err);
              this.basicPresenter.setSelectedRequested("");
              resolve(true);
            });
          } else {
            return resolve(true);
          }
        }).catch((err) => {
          if (this.basicPresenter.getSelectedRequested()) {
            return AiManager.loadBasic().then(() => {
              const lang = AiManager.basic?.lang ?? 'en';
              this.injector.get(TranslateService).use(lang == "Italian" ? 'it' : 'en');
              resolve(true);
            }).catch((err) => {
              console.error(err);
              this.basicPresenter.setSelectedRequested("");
              resolve(true);
            });
          } else {
            return resolve(true);
          }
        }
        );
      } else {
        if (this.basicPresenter.getSelectedRequested()) {
          return AiManager.loadBasic().then(() => {
            const lang = AiManager.basic?.lang ?? 'en';
            this.injector.get(TranslateService).use(lang == "Italian" ? 'it' : 'en');
            resolve(true);
          }).catch((err) => {
            console.error(err);
            this.basicPresenter.setSelectedRequested("");
            resolve(true);
          });
        } else {
          return resolve(true);
        }

      }

    });
  }
}
