import { Component, Input, OnInit } from '@angular/core';
import { CbToastMessage, CbToastService } from '../cb-toast.service';
import * as uuid from 'uuid';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'cb-toast-message',
  templateUrl: './cb-toast-message.component.html',
  styleUrls: ['./cb-toast-message.component.scss'],
  animations: [
    trigger('toastAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(60%)' }),
        animate('0.2s ease-in', style({ 'opacity': '1', transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0%)' }),
        animate('0.3s ease-out', style({ 'opacity': '0', transform: 'translateY(-40%)' }))
      ])
    ])
  ]
})
export class CbToastMessageComponent implements OnInit {

  @Input() public key = "*";
  @Input() public xPosition: "left" | "center" | "right" = "right";
  @Input() public yPosition: "top" | "bottom" = "bottom";
  @Input() public multiple = false;
  @Input() public defaultDuration = 3000;
  @Input() public maxToasts = 3;

  public toasts: {
    id: string,
    message: CbToastMessage
  }[] = [];

  constructor(
    private readonly cbToastService: CbToastService
  ) {
    cbToastService.cbToastSubject.subscribe((message: CbToastMessage) => {
      setTimeout(() => {
        if (this.key == "*" || this.key == message.key) {
          if (!this.multiple && this.toasts.length > 0) {
            this.toasts = [];
          } else if (this.toasts.length == this.maxToasts) {
            this.toasts.shift();
          }

          const messageId: string = uuid.v1();
          this.toasts.push({
            id: messageId,
            message: message
          });

          setTimeout(() => {
            this.removeToast(messageId);
          }, message.duration ?? this.defaultDuration);
        }
      })
    });
  }

  ngOnInit(): void {
  }

  public removeToast(id: string): void {
    this.toasts = this.toasts.filter(t => t.id != id);
  }
}
