import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CbToastMessageComponent } from './cb-toast-message/cb-toast-message.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';



@NgModule({
  declarations: [
    CbToastMessageComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule
  ],
  exports: [
    CbToastMessageComponent
  ],
  providers: [TranslateService]
})
export class CbToastModule { }
