import { Component, Input, OnInit } from '@angular/core';
import { ICONS } from 'src/app/icons';

@Component({
  selector: 'cb-icon',
  templateUrl: './cb-icon.component.html',
  styleUrls: ['./cb-icon.component.scss']
})
export class CbIconComponent implements OnInit {

  @Input("name") public name!: string;

  constructor() { }

  ngOnInit(): void {
  }

  public get mask(): string {
    if (ICONS[this.name])
      return `url('${ICONS[this.name]}')`;

    return "";
  }
}
