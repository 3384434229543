/// IMPORTS
import {
  WorkspaceCompletitionPostBody,
  WorkspaceCompletitionPostResponse,
} from 'generatedInterfaces';
import { CompletitionOfflineEndpoint } from 'src/app/classes/offlineEndpoints/completitionOfflineEndpoint';
import { CompletitionOnlineEndpoint } from 'src/app/classes/onlineEndpoints/completitionOnlineEndpoint';
import { HttpErrorResponse } from '@angular/common/http';
import { Workspace } from '../workspace';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

export class CompletitionModel {
  /// PROPERTIES
  private onlineEndpoint: CompletitionOnlineEndpoint =
    new CompletitionOnlineEndpoint();
  private offlineEndpoint: CompletitionOfflineEndpoint =
    new CompletitionOfflineEndpoint();

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  // workspaceInputCompletitionPost
  public postOne(
    obj: WorkspaceCompletitionPostBody
  ): Observable<WorkspaceCompletitionPostResponse> {
    return this.onlineEndpoint.postOne(obj).pipe(
      map(
        (response: WorkspaceCompletitionPostResponse) => {
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  /// CUSTOM METHODS

  ///
}
