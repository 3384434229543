import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CbTitleSkeletonComponent } from './cb-title-skeleton/cb-title-skeleton.component';
import { CbTableSkeletonComponent } from './cb-table-skeleton/cb-table-skeleton.component';



@NgModule({
  declarations: [
    CbTitleSkeletonComponent,
    CbTableSkeletonComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CbTitleSkeletonComponent,
    CbTableSkeletonComponent
  ]
})
export class CbSkeletonModule { }
