import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CbSettingsPopoverComponent } from './cb-settings-popover/cb-settings-popover.component';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { CbIconModule } from '../cb-icon/cb-icon.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    CbSettingsPopoverComponent
  ],
  imports: [
    CommonModule,
    OverlayPanelModule,
    CbIconModule,
    TranslateModule
  ],
  exports: [
    CbSettingsPopoverComponent
  ]
})
export class CbPopoverModule { }
