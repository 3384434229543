import { environment } from 'src/environments/environment';
export const formatDataContact = (date: Date) => {
  return (
    new Date(date).getFullYear() +
    '/' +
    (new Date(date).getMonth() + 1) +
    '/' +
    new Date(date).getDate() +
    ' ' +
    new Date(date).getHours() +
    ':' +
    new Date(date).getMinutes()
  );
};
const apiUrl = environment.baseEndpoint;
export const authApiFile = function (
  bool: boolean,
  token: string,
  workspace: string
) {
  const headers = {
    Authless: '' + !bool,
    workspace: workspace,
    Authorization: `Bearer ${token}`,
  };
  const options = {
    params: {},
    headers: headers,
    reportProgress: true,
    observe: <any>'events',
  };
  return options;
};
export const authApi = function (bool: boolean) {
  const headers = {
    Authless: '' + !bool,
    'Content-Type': 'application/json',
    Authorization: `Bearer`,
  };
  const options = { params: {}, headers: headers };
  return options;
};
export const deleteAuthApi = function (bool: boolean, obj: any) {
  const headers = {
    Authless: '' + !bool,
    'Content-Type': 'application/json',
    Authorization: `Bearer`,
  };
  const options = { params: {}, headers: headers, body: obj };
  return options;
};
export const getUrl = function (path: string) {
  return apiUrl + path;
  if (!environment.production) {
    return 'http://localhost:3000' + path;
  } else {
    return apiUrl + path;
  }
};

export const objectToQueryString = function (obj: any) {
  return '?' + new URLSearchParams(obj).toString();
};

const chars: string =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
const atobCustom = (input: string) => {
  var str = String(input).replace(/=+$/, '');
  if (str.length % 4 == 1) {
    throw new Error(
      "'atob' failed: The string to be decoded is not correctly encoded."
    );
  }
  for (
    // initialize result and counters
    var bc = 0, bs, buffer, idx = 0, output = '';
    // get next character
    (buffer = str.charAt(idx++));
    // character found in table? initialize bit storage and add its ascii value;
    ~buffer &&
    ((bs = bc % 4 ? <any>bs * 64 + buffer : buffer),
    // and if not first of each 4 characters,
    // convert the first 8 bits to one ascii character
    bc++ % 4)
      ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
      : 0
  ) {
    // try to find character in table (0-63, not found => -1)
    buffer = chars.indexOf(buffer);
  }
  return output;
};

export const parseJwt = (token: string) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atobCustom(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};
