/// IMPORTS
import {
  WorkspaceLinkGetQuery,
  WorkspaceLinkGetResponse,
  WorkspaceLinkPostBody,
  WorkspaceLinkPostResponse,
  WorkspaceLinkPutBody,
  WorkspaceLinkPutResponse,
  WorkspaceLinkDeleteQuery,
  WorkspaceLinkDeleteResponse,
  WorkspaceLinksGetQuery,
  WorkspaceLinksGetResponse,
  WorkspaceLinkPostsBody,
  WorkspaceLinkPostsResponse,
  WorkspaceLinkPutsBody,
  WorkspaceLinkPutsResponse,
  WorkspaceLinkDeletesQuery,
  WorkspaceLinkDeletesResponse,
  WorkspaceLinkOrderPutBody,
  WorkspaceLinkOrderPutResponse,
} from 'generatedInterfaces';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { getUrl, authApi, deleteAuthApi, objectToQueryString } from '../utils';
import { HttpService } from 'src/app/services/http.service';

export class LinksOnlineEndpoint {
  /// PROPERTIES
  private http: HttpClient = HttpService.instance.http;

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  // workspaceLinkOrderPut
  public changeOrder(
    obj: WorkspaceLinkOrderPutBody
  ): Observable<WorkspaceLinkOrderPutResponse> {
    return this.http.put<WorkspaceLinkOrderPutResponse>(
      getUrl('/workspace/links/order'),
      obj,
      authApi(true)
    );
  }

  // workspaceLinkDeletes
  public deletesMany(
    obj: WorkspaceLinkDeletesQuery
  ): Observable<WorkspaceLinkDeletesResponse> {
    return this.http.delete<WorkspaceLinkDeletesResponse>(
      getUrl('/workspace/links') + objectToQueryString(obj),
      deleteAuthApi(true, obj)
    );
  }

  // workspaceLinkPuts
  public putsMany(
    obj: WorkspaceLinkPutsBody
  ): Observable<WorkspaceLinkPutsResponse> {
    return this.http.put<WorkspaceLinkPutsResponse>(
      getUrl('/workspace/links'),
      obj,
      authApi(true)
    );
  }

  // workspaceLinkPosts
  public postsMany(
    obj: WorkspaceLinkPostsBody
  ): Observable<WorkspaceLinkPostsResponse> {
    return this.http.post<WorkspaceLinkPostsResponse>(
      getUrl('/workspace/links'),
      obj,
      authApi(true)
    );
  }

  // workspaceLinksGet
  public getMany(
    obj: WorkspaceLinksGetQuery
  ): Observable<WorkspaceLinksGetResponse> {
    return this.http.get<WorkspaceLinksGetResponse>(
      getUrl('/workspace/links') + objectToQueryString(obj),
      authApi(true)
    );
  }

  // workspaceLinkDelete
  public deleteOne(
    obj: WorkspaceLinkDeleteQuery
  ): Observable<WorkspaceLinkDeleteResponse> {
    return this.http.delete<WorkspaceLinkDeleteResponse>(
      getUrl('/workspace/link') + objectToQueryString(obj),
      deleteAuthApi(true, obj)
    );
  }

  // workspaceLinkPut
  public putOne(
    obj: WorkspaceLinkPutBody
  ): Observable<WorkspaceLinkPutResponse> {
    return this.http.put<WorkspaceLinkPutResponse>(
      getUrl('/workspace/link'),
      obj,
      authApi(true)
    );
  }

  // workspaceLinkPost
  public postOne(
    obj: WorkspaceLinkPostBody
  ): Observable<WorkspaceLinkPostResponse> {
    return this.http.post<WorkspaceLinkPostResponse>(
      getUrl('/workspace/link'),
      obj,
      authApi(true)
    );
  }

  // workspaceLinkGet
  public getOne(
    obj: WorkspaceLinkGetQuery
  ): Observable<WorkspaceLinkGetResponse> {
    return this.http.get<WorkspaceLinkGetResponse>(
      getUrl('/workspace/link') + objectToQueryString(obj),
      authApi(true)
    );
  }

  /// CUSTOM METHODS

  ///
}
