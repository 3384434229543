import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = () => { };

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CbDateInputComponent),
  multi: true,
};

@Component({
  selector: 'cb-date-input',
  templateUrl: './cb-date-input.component.html',
  styleUrls: ['./cb-date-input.component.scss', '../cb-form-style.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class CbDateInputComponent implements ControlValueAccessor, OnInit {
  @Input() public appendTo?: string;
  @Input() public placeholder?: string = '';
  @Input() public label?: string = '';
  @Input() public autofocus?: boolean;
  @Input() public disabled = false;
  @Input() public error = false;

  public showPassword = false;

  @Output('change') public onChange: EventEmitter<Date> =
    new EventEmitter<Date>();

  @ViewChild('input') private inputRef?: ElementRef;

  //The internal data model
  private innerValue?: Date;

  constructor(private readonly cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (this.autofocus) this.focusInput();
  }

  ngAfterViewInit(): void {
    this.setInputListener();
  }

  private setInputListener(): void { }

  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  // get accessor
  public get value(): Date | undefined {
    return this.innerValue;
  }

  // Set accessor including call the onchange callback
  public set value(v: Date | undefined) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  public togglePassword(): void {
    this.showPassword = !this.showPassword;
    this.cdr.detectChanges();
  }

  // From ControlValueAccessor interface
  public writeValue(value: Date | string | undefined) {
    if (value !== this.innerValue) {
      if (typeof value === 'string') {
        const split: string[] = value.split('/');
        if (split.length === 3) {
          this.innerValue = new Date(
            parseInt(split[2]),
            parseInt(split[1]) - 1,
            parseInt(split[0])
          );
        }
      } else if (value instanceof Date) {
        this.innerValue = value;
      }
      this.onChangeCallback(this.value);
      this.cdr.detectChanges();
    }
  }

  public change(): void {
    this.onChange.emit(this.value);
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public focusInput(): void {
    setTimeout(() => {
      this.inputRef?.nativeElement.focus();
    }, 0);
  }
}
