<div class="cb-input-wrapper" [class.disabled]="disabled" [class.error]="error">
  <label>{{label}}</label>
  <div class="cb-input-container">
    <div #prefix class="cb-input-prefix" [class.has-children]="prefix.children.length">
      <ng-content select="[prefix]"></ng-content>
    </div>
    <div class="cb-input">
      <p-chips [(ngModel)]="value" [separator]="separator" [allowDuplicate]="allowDuplicates"
        [placeholder]="placeholder" [max]="max"></p-chips>
    </div>
    <div #suffix class="cb-input-suffix" [class.has-children]="suffix.children.length">
      <ng-content select="[suffix]"></ng-content>
    </div>

  </div>
</div>