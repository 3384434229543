import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { CbGlobalLoaderService, GlobalLoaderData } from '../cb-global-loader.service';

@Component({
  selector: 'cb-global-loader',
  templateUrl: './cb-global-loader.component.html',
  styleUrls: ['./cb-global-loader.component.scss'],
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [
        style({ opacity: 0, bottom: '-2rem' }),
        animate('0.2s ease-in', style({ 'opacity': '1', bottom: '0' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, bottom: '0' }),
        animate('0.1s ease-out', style({ 'opacity': '0', bottom: '-2rem' }))
      ])
    ])
  ]
})
export class CbGlobalLoaderComponent implements OnInit {

  public show = false;
  public percentage = 20;
  public isInfiniteLoading = false;

  private percentageTexts: { afterPercentage: number, text: string }[] = [];
  private defaultText = "";

  constructor(
    private readonly globalLoaderService: CbGlobalLoaderService
  ) {
    this.globalLoaderService.toggleSubscribe((data: GlobalLoaderData) => {
      this.show = data.show ?? false;
      this.isInfiniteLoading = data.isInfiniteLoading ?? false;
      this.defaultText = data.defaultText ?? "";
      this.percentageTexts = data.percentageTexts?.sort((value: { afterPercentage: number, text: string }) => {
        return value.afterPercentage;
      }) ?? [];
    });

    this.globalLoaderService.percentageSubscribe((perc: number) => {
      this.percentage = perc;
    });
  }

  ngOnInit(): void {
  }

  public get text(): string {
    if (this.isInfiniteLoading) {
      return this.defaultText;
    } else {
      for (const p of this.percentageTexts) {
        if (this.percentage < p.afterPercentage) {
          return p.text;
        }
      }
      return this.defaultText;
    }
  }
}
