<div class="cb-global-loader-container" *ngIf="show" @toggleAnimation>
  <ng-container *ngIf="!isInfiniteLoading">
    <div class="cb-global-loader-progress" [style.width]="percentage+'%'"></div>
  </ng-container>
  <div class="cb-global-loader-content">
    <ng-container *ngIf="isInfiniteLoading; else PercentageTemplate">
      <div class="cb-global-loader-infinite">
        <cb-loader></cb-loader>
        <p>{{text | translate}}</p>
      </div>
    </ng-container>
    <ng-template #PercentageTemplate>
      <div class="cb-global-loader-percentage">
        <p>{{percentage}}%</p>
        <span>{{text | translate}}</span>
      </div>
    </ng-template>
  </div>
</div>
