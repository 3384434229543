import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface CbToastMessage {
  key?: string;
  title: string;
  icon?: string;
  message: string;
  duration?: number;
  type?: "primary" | "success" | "danger" | "warning" | "info";
}

@Injectable({
  providedIn: 'root'
})
export class CbToastService {
  public cbToastSubject: Subject<CbToastMessage> = new Subject<CbToastMessage>();

  static instance: CbToastService;

  constructor() {
    CbToastService.instance = this;
  }

  public showMessage(message: CbToastMessage): void {
    if (message && message.message && message.message !== "Cannot read properties of null (reading 'id')")
      this.cbToastSubject.next(message);
  }
}
