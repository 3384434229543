import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = () => {};

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CbSliderComponent),
  multi: true,
};

@Component({
  selector: 'cb-slider',
  templateUrl: './cb-slider.component.html',
  styleUrls: ['./cb-slider.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class CbSliderComponent implements ControlValueAccessor {
  @Input() public label?: string = '';
  @Input() public disabled = false;
  @Input() public min = 0;
  @Input() public max = 100;
  @Input() public step = 1;

  @Input() public stepLabels: string[] = [];

  @Output('change') public onChange: EventEmitter<number> =
    new EventEmitter<number>();

  //The internal data model
  private innerValue = 0;

  constructor() {}

  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  // get accessor
  public get value(): number {
    return this.innerValue;
  }

  // Set accessor including call the onchange callback
  public set value(v: number) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  public get currentStepLabel(): string {
    return this.stepLabels ? this.stepLabels[this.value] ?? '' : '';
  }

  // From ControlValueAccessor interface
  public writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  public change(): void {
    this.onChange.emit(this.value);
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
