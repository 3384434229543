import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = () => { };

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CbCheckboxComponent),
  multi: true,
};

@Component({
  selector: 'cb-checkbox',
  templateUrl: './cb-checkbox.component.html',
  styleUrls: ['./cb-checkbox.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class CbCheckboxComponent
  implements ControlValueAccessor, OnInit, OnChanges {
  private innerValue = false;

  @Input() public checked = false;
  @Input() public disabled = false;
  @Input() public error = false;
  @Input() public alignCenter = false;

  @Output('change') private onChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    this.value = this.checked;
  }

  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  // get accessor
  public get value(): boolean {
    return this.innerValue;
  }

  // Set accessor including call the onchange callback
  public set value(v: boolean) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  // From ControlValueAccessor interface
  public writeValue(value: boolean) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public toggleCheckbox(): void {
    this.value = !this.value;
    this.onChange.emit(this.value);
  }
}
