import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CbTopbarUserComponent } from './cb-topbar-user/cb-topbar-user.component';
import { CbUserComponent } from './cb-user/cb-user.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RouterModule } from '@angular/router';
import { CbTopbarComponent } from './cb-topbar/cb-topbar.component';
import { CbTabComponent, CbTabsComponent } from './cb-tabs/cb-tabs.component';
import { CbSkeletonModule } from '../cb-skeleton/cb-skeleton.module';
import { CbFormWidgetsModule } from '../cb-form-widgets/cb-form-widgets.module';
import { CbTextFormatterComponent } from './cb-text-formatter/cb-text-formatter.component';
import { CommonPipesModule } from 'src/app/pipes/common-pipes/common-pipes.module';
import { CbLoaderComponent } from './cb-loader/cb-loader.component';

@NgModule({
  declarations: [
    CbTopbarUserComponent,
    CbUserComponent,
    CbTopbarComponent,
    CbTabsComponent,
    CbTabComponent,
    CbTextFormatterComponent,
    CbLoaderComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    IonicModule,
    OverlayPanelModule,
    RouterModule,
    CbSkeletonModule,
    CbFormWidgetsModule,
    CommonPipesModule
  ],
  exports: [
    CbTopbarUserComponent,
    CbUserComponent,
    CbTopbarComponent,
    CbTabsComponent,
    CbTabComponent,
    CbTextFormatterComponent,
    CbLoaderComponent],
})
export class CbUtilityWidgetsModule { }
