/// IMPORTS
import { UserModel } from 'src/app/classes/models/userModel';
import { Observable } from 'rxjs/internal/Observable';
import { User } from '../user';

export class UserPresenter {
  /// PROPERTIES
  private model: UserModel = new UserModel();

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  public logoutRequested(): void {
    return this.model.logout();
  }

  // Is token expired
  public isTokenExpired(): boolean {
    return this.model.isTokenExpired();
  }

  // Is authenticathed
  public isAuthenticated(): boolean {
    return this.model.isAuthenticated();
  }

  // Login the user
  public loginRequested(obj: {
    username: string;
    password: string;
    email: string;
  }): Observable<{ accessToken: string; refreshToken: string }> {
    return this.model.login(obj);
  }

  // Register the user
  public registerRequested(obj: {
    username: string;
    password: string;
    email: string;
  }): Observable<{ accessToken: string; refreshToken: string }> {
    return this.model.register(obj);
  }

  // Update additional information
  public additionalInformationRequested(obj: {
    additionalInformation: any;
  }): Observable<{ message: string }> {
    return this.model.additionalInformation(obj);
  }

  // Change username
  public changeUsernameRequested(obj: {
    newUsername: string;
  }): Observable<{ message: string }> {
    return this.model.changeUsername(obj);
  }

  // Change lang
  public changeLangRequested(obj: {
    lang: string;
  }): Observable<{ message: string }> {
    return this.model.changeLang(obj);
  }

  // Change newsletter
  public changeNewsletterRequested(obj: {
    newsletter: boolean;
  }): Observable<{ accessToken: string; refreshToken: string }> {
    return this.model.changeNewsletter(obj);
  }

  // Bind workspace
  public bindWorkspaceRequested(obj: {
    workspaceId: string;
    invitationCode: string;
  }): Observable<{ accessToken: string; refreshToken: string }> {
    return this.model.bindWorkspace(obj);
  }

  // Complete onboarding
  public completeOnboardingRequested(obj: {
    bool: boolean;
  }): Observable<{ accessToken: string; refreshToken: string }> {
    return this.model.completeOnboarding(obj);
  }

  // Confirm pincode
  public confirmPincodeRequested(obj: {
    code: string;
  }): Observable<{ accessToken: string; refreshToken: string }> {
    return this.model.confirmPincode(obj);
  }

  // Send pincode
  public sendPincodeRequested(obj: {
    email: string;
  }): Observable<{ message: string }> {
    return this.model.sendPincode(obj);
  }

  // Change password the user
  public changePasswordRequested(obj: {
    password: string;
    passwordConfirm: string;
  }): Observable<{ message: string }> {
    return this.model.changePassword(obj);
  }

  // Forgot password the user
  public forgotPasswordRequested(obj: {
    username: string;
    email: string;
  }): Observable<{ message: string }> {
    return this.model.forgotPassword(obj);
  }

  // Recover password the user
  public recoverPasswordRequested(obj: {
    password: string;
    code: string;
  }): Observable<{ message: string }> {
    return this.model.recoverPassword(obj);
  }

  // Update token for the user
  public tokenRequested(obj: {
    refreshToken: string;
  }): Observable<{ accessToken: string; refreshToken: string }> {
    return this.model.token(obj);
  }

  // Get decoded token
  public getDecodedAccessToken(): User | null {
    return this.model.getDecodedAccessToken();
  }

  // Tokens for the user
  public getAccessToken(): string {
    return this.model.getAccessToken();
  }

  public getRefreshToken(): string {
    return this.model.getRefreshToken();
  }

  /// CUSTOM METHODS

  ///
}
