/// IMPORTS
import {
  WorkspaceEditPostBody,
  WorkspaceEditPostResponse,
} from 'generatedInterfaces';
import { EditOfflineEndpoint } from 'src/app/classes/offlineEndpoints/editOfflineEndpoint';
import { EditOnlineEndpoint } from 'src/app/classes/onlineEndpoints/editOnlineEndpoint';
import { HttpErrorResponse } from '@angular/common/http';
import { Workspace } from '../workspace';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

export class EditModel {
  /// PROPERTIES
  private onlineEndpoint: EditOnlineEndpoint = new EditOnlineEndpoint();
  private offlineEndpoint: EditOfflineEndpoint = new EditOfflineEndpoint();

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  // workspaceInputEditPost
  public postOne(
    obj: WorkspaceEditPostBody
  ): Observable<WorkspaceEditPostResponse> {
    return this.onlineEndpoint.postOne(obj).pipe(
      map(
        (response: WorkspaceEditPostResponse) => {
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  /// CUSTOM METHODS

  ///
}
