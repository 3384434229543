import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

export interface GlobalLoaderData {
  show?: boolean;
  isInfiniteLoading?: boolean;
  percentageTexts?: {
    afterPercentage: number;
    text: string;
  }[],
  defaultText?: string;
}

@Injectable({
  providedIn: 'root'
})
export class CbGlobalLoaderService {

  private readonly toggleLoader: Subject<GlobalLoaderData> = new Subject();
  private readonly loaderPercentage: Subject<number> = new Subject();

  constructor() { }

  public toggleSubscribe(f: (data: GlobalLoaderData) => void): Subscription {
    return this.toggleLoader.subscribe(f);
  }

  public percentageSubscribe(f: (perc: number) => void): Subscription {
    return this.loaderPercentage.subscribe(f);
  }

  public showLoader(isInfiniteLoading: boolean, defaultText: string, percentageTexts?: { afterPercentage: number, text: string }[]): void {
    this.toggleLoader.next({
      show: true,
      isInfiniteLoading: isInfiniteLoading,
      defaultText: defaultText,
      percentageTexts: percentageTexts
    });
  }

  public hideLoader(): void {
    this.toggleLoader.next({
      show: false
    });
  }

  public updatePercentage(perc: number): void {
    this.loaderPercentage.next(perc);
  }
}
