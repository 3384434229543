/// IMPORTS
import {
  WorkspaceCompletitionPostBody,
  WorkspaceCompletitionPostResponse,
} from 'generatedInterfaces';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Workspace } from '../workspace';

export class CompletitionOfflineEndpoint {
  /// PROPERTIES

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  /// CUSTOM METHODS

  ///
}
