import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BgTopbarComponent } from './bg-topbar/bg-topbar.component';
import { BgTabsComponent } from './bg-tabs/bg-tabs.component';
import { CbUtilityWidgetsModule } from '../cb-utility-widgets/cb-utility-widgets.module';
import { BgSearchbarComponent } from './bg-searchbar/bg-searchbar.component';
import { CbIconModule } from '../cb-icon/cb-icon.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { CbFormWidgetsModule } from '../cb-form-widgets/cb-form-widgets.module';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CbPopoverModule } from '../cb-popover/cb-popover.module';
import { SettingsModule } from 'src/app/modules/settings/settings.module';
import { KnobModule } from 'primeng/knob';
import { BgLangButtonComponent } from './bg-lang-button/bg-lang-button.component';


@NgModule({
  declarations: [
    BgTopbarComponent,
    BgTabsComponent,
    BgSearchbarComponent,
    BgLangButtonComponent
  ],
  imports: [
    CommonModule,
    CbUtilityWidgetsModule,
    CbIconModule,
    TranslateModule,
    FormsModule,
    TooltipModule,
    CbFormWidgetsModule,
    OverlayPanelModule,
    CbPopoverModule,
    KnobModule
  ],
  exports: [
    BgTopbarComponent,
    BgTabsComponent,
    BgSearchbarComponent,
    BgLangButtonComponent
  ]
})
export class BgUtilityWidgetsModule { }
