/// IMPORTS
import {
  WorkspaceBasicGetQuery,
  WorkspaceBasicGetResponse,
  WorkspaceBasicPostBody,
  WorkspaceBasicPostResponse,
  WorkspaceBasicPutBody,
  WorkspaceBasicPutResponse,
  WorkspaceBasicDeleteQuery,
  WorkspaceBasicDeleteResponse,
  WorkspaceBasicsGetQuery,
  WorkspaceBasicsGetResponse,
  WorkspaceBasicOrderPutBody,
  WorkspaceBasicOrderPutResponse,
} from 'generatedInterfaces';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Workspace } from '../workspace';

export class BasicOfflineEndpoint {
  /// PROPERTIES

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS
  public setSelected(id: string): void {
    localStorage.setItem('selected', id);
  }
  public getSelected(): string {
    return localStorage.getItem('selected') ?? '';
  }
  public removeSelected(): void {
    localStorage.removeItem('selected');
  }

  // Delete a local entity
  public delete(obj: Workspace['basic']) {
    const basic = this.getMany();
    basic.splice(
      basic.findIndex((entity) => {
        if (entity.id === obj.id) {
          return true;
        }
        return false;
      }),
      1
    );
    this.setEntities(basic);
  }

  // Push or update an entity
  public pushOrUpdate(obj: Workspace['basic']): void {
    const basic = this.getMany();
    const index = basic.findIndex((entity) => entity.id === obj.id);
    if (index === -1) {
      basic.push(obj);
    } else {
      basic[index] = obj;
    }
    this.setEntities(basic);
  }

  // Get all the local entities
  public getMany(obj?: any): Workspace['basic'][] {
    if (localStorage.getItem('basic') === null) {
      return [];
    }
    let entities = JSON.parse(
      localStorage.getItem('basic') as any
    ) as Workspace['basic'][];

    if (obj) {
      return entities.filter((entity) => {
        let hasProperties = false;
        for (const key in obj) {
          hasProperties = true;
          if (obj.hasOwnProperty(key) && entity.hasOwnProperty(key)) {
            if ((<any>entity)[key] === (<any>obj)[key]) {
              return true;
            }
          }
        }
        if (!hasProperties) return true;
        return false;
      });
    }

    return entities;
  }

  // Get one of the local entities
  public getOne(obj: any): Workspace['basic'] {
    let basic = this.getMany();
    // Filter for all the value of obj
    basic = basic.filter((entity) => {
      let hasProperties = false;
      for (const key in obj) {
        hasProperties = true;
        if (obj.hasOwnProperty(key) && entity.hasOwnProperty(key)) {
          if ((<any>entity)[key] === (<any>obj)[key]) {
            return true;
          }
        }
      }
      if (!hasProperties) return true;
      return false;
    });

    return basic[0];
  }

  // Set the entire entities array
  public setEntities(obj: Workspace['basic'][]): void {
    localStorage.setItem('basic', JSON.stringify(obj));
  }

  /// CUSTOM METHODS

  ///
}
