export const ICONS: { [key: string]: string } = {
    ".DS_Store": "/assets/icons/.DS_Store.svg",
    "add-circle": "/assets/icons/add-circle.svg",
    "add": "/assets/icons/add.svg",
    "alert-circle": "/assets/icons/alert-circle.svg",
    "alert-triangle": "/assets/icons/alert-triangle.svg",
    "arrow-back": "/assets/icons/arrow-back.svg",
    "arrow-down-left": "/assets/icons/arrow-down-left.svg",
    "arrow-down-right": "/assets/icons/arrow-down-right.svg",
    "arrow-down": "/assets/icons/arrow-down.svg",
    "arrow-forward": "/assets/icons/arrow-forward.svg",
    "arrow-up-left": "/assets/icons/arrow-up-left.svg",
    "arrow-up-right": "/assets/icons/arrow-up-right.svg",
    "arrow-up": "/assets/icons/arrow-up.svg",
    "attachment": "/assets/icons/attachment.svg",
    "calendar": "/assets/icons/calendar.svg",
    "card": "/assets/icons/card.svg",
    "change": "/assets/icons/change.svg",
    "checklist": "/assets/icons/checklist.svg",
    "checkmark-circle": "/assets/icons/checkmark-circle.svg",
    "checkmark": "/assets/icons/checkmark.svg",
    "chevron-back": "/assets/icons/chevron-back.svg",
    "chevron-down": "/assets/icons/chevron-down.svg",
    "chevron-forward": "/assets/icons/chevron-forward.svg",
    "chevron-up": "/assets/icons/chevron-up.svg",
    "circle": "/assets/icons/circle.svg",
    "close-circle": "/assets/icons/close-circle.svg",
    "close": "/assets/icons/close.svg",
    "color-fill": "/assets/icons/color-fill.svg",
    "copy": "/assets/icons/copy.svg",
    "cut": "/assets/icons/cut.svg",
    "document": "/assets/icons/document.svg",
    "download": "/assets/icons/download.svg",
    "drag-handle": "/assets/icons/drag-handle.svg",
    "edit": "/assets/icons/edit.svg",
    "ellipsis-horizontal": "/assets/icons/ellipsis-horizontal.svg",
    "ellipsis-vertical": "/assets/icons/ellipsis-vertical.svg",
    "eye-off": "/assets/icons/eye-off.svg",
    "eye": "/assets/icons/eye.svg",
    "globe": "/assets/icons/globe.svg",
    "help": "/assets/icons/help.svg",
    "info": "/assets/icons/info.svg",
    "input-form": "/assets/icons/input-form.svg",
    "link": "/assets/icons/link.svg",
    "list": "/assets/icons/list.svg",
    "loader": "/assets/icons/loader.svg",
    "logo-html5": "/assets/icons/logo-html5.svg",
    "logo-markdown": "/assets/icons/logo-markdown.svg",
    "logout": "/assets/icons/logout.svg",
    "magic": "/assets/icons/magic.svg",
    "move": "/assets/icons/move.svg",
    "note": "/assets/icons/note.svg",
    "party": "/assets/icons/party.svg",
    "password": "/assets/icons/password.svg",
    "play": "/assets/icons/play.svg",
    "plus": "/assets/icons/plus.svg",
    "priority": "/assets/icons/priority.svg",
    "redo": "/assets/icons/redo.svg",
    "refresh": "/assets/icons/refresh.svg",
    "route": "/assets/icons/route.svg",
    "save": "/assets/icons/save.svg",
    "search": "/assets/icons/search.svg",
    "send": "/assets/icons/send.svg",
    "settings": "/assets/icons/settings.svg",
    "sort": "/assets/icons/sort.svg",
    "support": "/assets/icons/support.svg",
    "target-off": "/assets/icons/target-off.svg",
    "target": "/assets/icons/target.svg",
    "template": "/assets/icons/template.svg",
    "test-tube": "/assets/icons/test-tube.svg",
    "trash": "/assets/icons/trash.svg",
    "trial": "/assets/icons/trial.svg",
    "undo": "/assets/icons/undo.svg",
    "upload": "/assets/icons/upload.svg",
    "user": "/assets/icons/user.svg",
    "users": "/assets/icons/users.svg",
    "workspace": "/assets/icons/workspace.svg"
}