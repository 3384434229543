<div class="cb-input-wrapper" [class.disabled]="disabled" [class.error]="error">
  <label>{{label}}</label>
  <div class="cb-input-container">
    <div class="cb-input-prefix">
      <ng-content select="[prefix]"></ng-content>
    </div>
    <div class="cb-input">
      <textarea #textarea autosize [maxRows]="maxRows ?? 999" [placeholder]="placeholder" [(ngModel)]="value" [rows]="rows" (input)="input()"
        (change)="change()" (keyup)="updateCaretPosition()" (focus)="updateCaretPosition()"></textarea>
    </div>
    <div class="cb-input-suffix">
      <ng-content select="[suffix]"></ng-content>
    </div>
  </div>
</div>
