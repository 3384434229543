/// IMPORTS
import {
  WorkspaceUserGetQuery,
  WorkspaceUserGetResponse,
  WorkspaceUserPostBody,
  WorkspaceUserPostResponse,
  WorkspaceUserPutBody,
  WorkspaceUserPutResponse,
  WorkspaceUserDeleteQuery,
  WorkspaceUserDeleteResponse,
  WorkspaceUsersGetQuery,
  WorkspaceUsersGetResponse,
  WorkspaceUserOrderPutBody,
  WorkspaceUserOrderPutResponse,
} from 'generatedInterfaces';
import { UsersModel } from 'src/app/classes/models/usersModel';
import { Workspace } from '../workspace';
import { Observable } from 'rxjs/internal/Observable';

export class UsersPresenter {
  /// PROPERTIES
  private model: UsersModel = new UsersModel();

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  // workspaceUserOrderPut
  public changeOrderRequested(
    obj: WorkspaceUserOrderPutBody
  ): Observable<WorkspaceUserOrderPutResponse> {
    return this.model.changeOrder(obj);
  }

  // workspaceUsersGet
  public getManyRequested(obj: WorkspaceUsersGetQuery): {
    local: Workspace['users'];
    promise: Observable<WorkspaceUsersGetResponse>;
  } {
    return this.model.getMany(obj);
  }

  // workspaceUserDelete
  public deleteOneRequested(
    obj: WorkspaceUserDeleteQuery
  ): Observable<WorkspaceUserDeleteResponse> {
    return this.model.deleteOne(obj);
  }

  // workspaceUserPut
  public putOneRequested(
    obj: WorkspaceUserPutBody
  ): Observable<WorkspaceUserPutResponse> {
    return this.model.putOne(obj);
  }

  // workspaceUserPost
  public postOneRequested(
    obj: WorkspaceUserPostBody
  ): Observable<WorkspaceUserPostResponse> {
    return this.model.postOne(obj);
  }

  // workspaceUserGet
  public getOneRequested(obj: WorkspaceUserGetQuery): {
    local: Workspace['users'][0];
    promise: Observable<WorkspaceUserGetResponse>;
  } {
    return this.model.getOne(obj);
  }

  /// CUSTOM METHODS

  ///
}
