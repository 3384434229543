<div class="cb-input-wrapper" [class.disabled]="disabled" [class.disabled]="disabled">
  <label>{{label}}</label>
  <div class="cb-input-container">
    <div class="cb-input-prefix">
      <ng-content select="[prefix]"></ng-content>
    </div>
    <div class="cb-input">
      <p-inputMask [placeholder]="placeholder ?? ''" mask="99 : 99" [(ngModel)]="stringValue" (change)="change()">
      </p-inputMask>
    </div>
    <div class="cb-input-suffix">
      <ng-content select="[suffix]"></ng-content>
    </div>
  </div>
</div>
