import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CbDialogConfirmComponent } from '../cb-dialog-confirm/cb-dialog-confirm.component';

export interface CbDialogData {
  component: any;
  data?: any;
  backdropDismissable?: boolean;
}

export interface CbDialogOptions {
  width: string;
}

@Injectable({
  providedIn: 'root',
})
export class CbDialogService {
  constructor(public readonly dialogService: MatDialog) { }

  public openDialog(
    data: CbDialogData,
    { afterClose }: { afterClose?: (result: any) => void } = {}
  ): void {
    this.dialogService
      .open(data.component, {
        data: data.data,
        disableClose: !data.backdropDismissable || false,
      })
      .afterClosed()
      .subscribe((result) => {
        if (afterClose) afterClose(result);
      });
  }

  public openConfirmDialog(
    title: string,
    message: string,
    { afterClose }: { afterClose?: (result: any) => void } = {}
  ): void {
    this.openDialog(
      {
        component: CbDialogConfirmComponent,
        data: { title: title, message: message },
      },
      {
        afterClose: (result) => {
          if (afterClose) afterClose(result ?? false);
        },
      }
    );
  }
}
