/// IMPORTS
import { UserOfflineEndpoint } from 'src/app/classes/offlineEndpoints/userOfflineEndpoint';
import { UserOnlineEndpoint } from 'src/app/classes/onlineEndpoints/userOnlineEndpoint';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { User } from '../user';
import { parseJwt } from '../utils';
import { BasicOfflineEndpoint } from '../offlineEndpoints/basicOfflineEndpoint';

export class UserModel {
  /// PROPERTIES
  private onlineEndpoint: UserOnlineEndpoint = new UserOnlineEndpoint();
  private offlineEndpoint: UserOfflineEndpoint = new UserOfflineEndpoint();
  private basicOfflineEndpoint: BasicOfflineEndpoint =
    new BasicOfflineEndpoint();

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  // Is token expired
  public isTokenExpired(): boolean {
    const token = this.getAccessToken();
    try {
      const decoded = parseJwt(token);
      return Math.floor(new Date().getTime() / 1000) >= decoded.expiry;
    } catch (error) {
      return false;
    }
  }

  // Get decoded token
  public getDecodedAccessToken(): User | null {
    const token = this.getAccessToken();
    try {
      return parseJwt(token);
    } catch (error) {
      return null;
    }
  }

  // Is authenticathed
  public isAuthenticated(): boolean {
    return this.getAccessToken() && this.getAccessToken().length > 0
      ? true
      : false;
  }

  public getAccessToken(): string {
    return this.offlineEndpoint.getAccessToken();
  }

  public getRefreshToken(): string {
    return this.offlineEndpoint.getRefreshToken();
  }

  public logout(): void {
    localStorage.clear();
    this.offlineEndpoint.setAccessToken('');
    this.offlineEndpoint.setRefreshToken('');
    this.basicOfflineEndpoint.removeSelected();
  }

  // Login the user
  public login(obj: {
    username: string;
    password: string;
    email: string;
  }): Observable<{ accessToken: string; refreshToken: string }> {
    return this.onlineEndpoint.login(obj).pipe(
      map(
        (response: any) => {
          this.offlineEndpoint.setAccessToken(response.accessToken);
          this.offlineEndpoint.setRefreshToken(response.refreshToken);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // Register the user
  public register(obj: {
    username: string;
    password: string;
    email: string;
  }): Observable<{ accessToken: string; refreshToken: string }> {
    return this.onlineEndpoint.register(obj).pipe(
      map(
        (response: any) => {
          this.offlineEndpoint.setAccessToken(response.accessToken);
          this.offlineEndpoint.setRefreshToken(response.refreshToken);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // ChangePassword the user
  public changePassword(obj: {
    password: string;
    passwordConfirm: string;
  }): Observable<{ message: string }> {
    return this.onlineEndpoint.changePassword(obj).pipe(
      map(
        (response: any) => {
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // AdditionalInformation the user
  public additionalInformation(obj: {
    additionalInformation: any;
  }): Observable<{ message: string }> {
    return this.onlineEndpoint.additionalInformation(obj).pipe(
      map(
        (response: any) => {
          this.offlineEndpoint.setAccessToken(response.accessToken);
          this.offlineEndpoint.setRefreshToken(response.refreshToken);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // Change username the user
  public changeUsername(obj: {
    newUsername: string;
  }): Observable<{ message: string }> {
    return this.onlineEndpoint.changeUsername(obj).pipe(
      map(
        (response: any) => {
          this.offlineEndpoint.setAccessToken(response.accessToken);
          this.offlineEndpoint.setRefreshToken(response.refreshToken);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // Change lang of the user
  public changeLang(obj: { lang: string }): Observable<{ message: string }> {
    return this.onlineEndpoint.changeLang(obj).pipe(
      map(
        (response: any) => {
          this.offlineEndpoint.setAccessToken(response.accessToken);
          this.offlineEndpoint.setRefreshToken(response.refreshToken);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // Change newsletter of the user
  public changeNewsletter(obj: {
    newsletter: boolean;
  }): Observable<{ accessToken: string; refreshToken: string }> {
    return this.onlineEndpoint.changeNewsletter(obj).pipe(
      map(
        (response: any) => {
          this.offlineEndpoint.setAccessToken(response.accessToken);
          this.offlineEndpoint.setRefreshToken(response.refreshToken);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // Bind workspace
  public bindWorkspace(obj: {
    workspaceId: string;
    invitationCode: string;
  }): Observable<{ accessToken: string; refreshToken: string }> {
    return this.onlineEndpoint.bindWorkspace(obj).pipe(
      map(
        (response: any) => {
          this.offlineEndpoint.setAccessToken(response.accessToken);
          this.offlineEndpoint.setRefreshToken(response.refreshToken);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // Change onboarding of the user
  public completeOnboarding(obj: {
    bool: boolean;
  }): Observable<{ accessToken: string; refreshToken: string }> {
    return this.onlineEndpoint.completeOnboarding(obj).pipe(
      map(
        (response: any) => {
          this.offlineEndpoint.setAccessToken(response.accessToken);
          this.offlineEndpoint.setRefreshToken(response.refreshToken);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // Confirm pincode of the user
  public confirmPincode(obj: {
    code: string;
  }): Observable<{ accessToken: string; refreshToken: string }> {
    return this.onlineEndpoint.confirmPincode(obj).pipe(
      map(
        (response: any) => {
          this.offlineEndpoint.setAccessToken(response.accessToken);
          this.offlineEndpoint.setRefreshToken(response.refreshToken);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // Send pincode of the user
  public sendPincode(obj: { email: string }): Observable<{ message: string }> {
    return this.onlineEndpoint.sendPincode(obj).pipe(
      map(
        (response: any) => {
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // Forgot password the user
  public forgotPassword(obj: {
    email: string;
    username: string;
  }): Observable<{ message: string }> {
    return this.onlineEndpoint.forgotPassword(obj).pipe(
      map(
        (response: any) => {
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // Recover password the user
  public recoverPassword(obj: {
    code: string;
    password: string;
  }): Observable<{ message: string }> {
    return this.onlineEndpoint.recoverPassword(obj).pipe(
      map(
        (response: any) => {
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // Update token for the user
  public token(obj: {
    refreshToken: string;
  }): Observable<{ accessToken: string; refreshToken: string }> {
    return this.onlineEndpoint.token(obj).pipe(
      map(
        (response: any) => {
          this.offlineEndpoint.setAccessToken(response.accessToken);
          this.offlineEndpoint.setRefreshToken(response.refreshToken);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  /// CUSTOM METHODS

  ///
}
