/// IMPORTS
import {
  WorkspaceBasicGetQuery,
  WorkspaceBasicGetResponse,
  WorkspaceBasicPostBody,
  WorkspaceBasicPostResponse,
  WorkspaceBasicPutBody,
  WorkspaceBasicPutResponse,
  WorkspaceBasicDeleteQuery,
  WorkspaceBasicDeleteResponse,
  WorkspaceBasicsGetQuery,
  WorkspaceBasicsGetResponse,
  WorkspaceBasicOrderPutBody,
  WorkspaceBasicOrderPutResponse,
} from 'generatedInterfaces';
import { BasicOfflineEndpoint } from 'src/app/classes/offlineEndpoints/basicOfflineEndpoint';
import { BasicOnlineEndpoint } from 'src/app/classes/onlineEndpoints/basicOnlineEndpoint';
import { HttpErrorResponse } from '@angular/common/http';
import { Workspace } from '../workspace';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

export class BasicModel {
  /// PROPERTIES
  private onlineEndpoint: BasicOnlineEndpoint = new BasicOnlineEndpoint();
  private offlineEndpoint: BasicOfflineEndpoint = new BasicOfflineEndpoint();

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS
  public setSelected(id: string): void {
    this.offlineEndpoint.setSelected(id);
  }
  public getSelected(): string {
    return this.offlineEndpoint.getSelected();
  }
  public removeSelected(): void {
    this.offlineEndpoint.removeSelected();
  }

  // workspaceBasicOrderPut
  public changeOrder(
    obj: WorkspaceBasicOrderPutBody
  ): Observable<WorkspaceBasicOrderPutResponse> {
    return this.onlineEndpoint.changeOrder(obj).pipe(
      map(
        (response: WorkspaceBasicOrderPutResponse) => {
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceBasicsGet
  public getMany(obj: WorkspaceBasicsGetQuery): {
    local: Workspace['basic'][];
    promise: Observable<WorkspaceBasicsGetResponse>;
  } {
    return {
      local: this.offlineEndpoint.getMany(obj).filter((item) => {
        for (let key of Object.keys(obj)) {
          if ((<any>item)[key] != (<any>obj)[key]) {
            return false;
          }
        }
        return true;
      }),
      promise: this.onlineEndpoint.getMany(obj).pipe(
        map(
          (response: WorkspaceBasicsGetResponse) => {
            let mains = response.main;
            if (mains)
              this.offlineEndpoint.setEntities(mains as Workspace['basic'][]);

            return response;
          },
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
        )
      ),
    };
  }

  // workspaceBasicDelete
  public deleteOne(
    obj: WorkspaceBasicDeleteQuery
  ): Observable<WorkspaceBasicDeleteResponse> {
    return this.onlineEndpoint.deleteOne(obj).pipe(
      map(
        (response: WorkspaceBasicDeleteResponse) => {
          this.offlineEndpoint.delete(obj as Workspace['basic']);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceBasicPut
  public putOne(
    obj: WorkspaceBasicPutBody
  ): Observable<WorkspaceBasicPutResponse> {
    return this.onlineEndpoint.putOne(obj).pipe(
      map(
        (response: WorkspaceBasicPutResponse) => {
          this.offlineEndpoint.pushOrUpdate(obj as Workspace['basic']);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceBasicPost
  public postOne(
    obj: WorkspaceBasicPostBody
  ): Observable<WorkspaceBasicPostResponse> {
    return this.onlineEndpoint.postOne(obj).pipe(
      map(
        (response: WorkspaceBasicPostResponse) => {
          this.offlineEndpoint.pushOrUpdate(obj as Workspace['basic']);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceBasicGet
  public getOne(obj: WorkspaceBasicGetQuery): {
    local: Workspace['basic'];
    promise: Observable<WorkspaceBasicGetResponse>;
  } {
    return {
      local: this.offlineEndpoint.getOne(obj),
      promise: this.onlineEndpoint.getOne(obj).pipe(
        map(
          (response: WorkspaceBasicGetResponse) => {
            this.offlineEndpoint.pushOrUpdate(
              response.main as Workspace['basic']
            );

            return response;
          },
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
        )
      ),
    };
  }

  /// CUSTOM METHODS

  ///
}
