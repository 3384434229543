/// IMPORTS
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Workspace } from '../workspace';

export class UserOfflineEndpoint {
  /// PROPERTIES

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  /// CUSTOM METHODS

  // set accessToken
  public setAccessToken(accessToken: string) {
    localStorage.setItem('accessToken', accessToken);
  }

  // set refreshToken
  public setRefreshToken(refreshToken: string) {
    localStorage.setItem('refreshToken', refreshToken);
  }

  public getAccessToken(): string {
    return localStorage.getItem('accessToken') || '';
  }

  public getRefreshToken(): string {
    return localStorage.getItem('refreshToken') || '';
  }

  ///
}
