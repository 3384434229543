import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cb-dialog',
  templateUrl: './cb-dialog.component.html',
  styleUrls: ['./cb-dialog.component.scss'],
})
export class CbDialogComponent implements OnInit {
  @Input() public title = '';
  @Input() public width = '';
  @Input() public closeButton: boolean = true;

  constructor(private readonly dialogRef: MatDialogRef<CbDialogComponent>) { }

  ngOnInit(): void { }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
