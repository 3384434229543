import { CbFormRow } from 'src/app/widgets/cb-form/cb-form-interfaces';

export const createRows: CbFormRow[] = [
  // { columns: [{ field: "name", placeholder: "PRODUCTS.FIELDS.PLACEHOLDERS.NAME", label: "PRODUCTS.FIELDS.LABELS.NAME", type: "text", required: true }]},
  // ID FORM CREATE
  {
    columns: [
      {
        field: 'id',
        placeholder: 'PROMPT.FIELDS.PLACEHOLDERS.ID',
        label: 'PROMPT.FIELDS.LABELS.ID',
        type: 'id',
        defaultValue: undefined,
        disabled: true,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: false,
        required: true,
      },
    ],
  },
  // TITLE FORM CREATE
  {
    columns: [
      {
        field: 'title',
        placeholder: 'PROMPT.FIELDS.PLACEHOLDERS.TITLE',
        label: 'PROMPT.FIELDS.LABELS.TITLE',
        type: 'text',
        defaultValue: undefined,
        disabled: false,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: true,
        required: true,
      },
    ],
  },
  // CONTENT FORM CREATE
  {
    columns: [
      {
        field: 'content',
        placeholder: 'PROMPT.FIELDS.PLACEHOLDERS.CONTENT',
        label: 'PROMPT.FIELDS.LABELS.CONTENT',
        type: 'textarea',
        defaultValue: undefined,
        disabled: false,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: true,
        required: true,
      },
    ],
  },
  // PROPERTIES FORM CREATE
  {
    columns: [
      // TODO MANAGE PROPERTIES ARRAY
    ],
  },
  // TEMPERATURE FORM CREATE
  {
    columns: [
      {
        field: 'temperature',
        placeholder: 'PROMPT.FIELDS.PLACEHOLDERS.TEMPERATURE',
        label: 'PROMPT.FIELDS.LABELS.TEMPERATURE',
        type: 'number',
        defaultValue: undefined,
        disabled: false,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: true,
        required: false,
      },
    ],
  },
  // MAXTOKENS FORM CREATE
  {
    columns: [
      {
        field: 'maxTokens',
        placeholder: 'PROMPT.FIELDS.PLACEHOLDERS.MAXTOKENS',
        label: 'PROMPT.FIELDS.LABELS.MAXTOKENS',
        type: 'number',
        defaultValue: undefined,
        disabled: false,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: true,
        required: false,
      },
    ],
  },
  // LANGUAGE FORM CREATE
  {
    columns: [
      {
        field: 'language',
        placeholder: 'PROMPT.FIELDS.PLACEHOLDERS.LANGUAGE',
        label: 'PROMPT.FIELDS.LABELS.LANGUAGE',
        type: 'enum',
        defaultValue: undefined,
        disabled: false,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: true,
        required: true,
        options: [
          { value: 'English', label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.ENGLISH' },
          { value: 'French', label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.FRENCH' },
          { value: 'Spanish', label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.SPANISH' },
          { value: 'German', label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.GERMAN' },
          { value: 'Dutch', label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.DUTCH' },
          { value: 'Italian', label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.ITALIAN' },
          {
            value: 'Portuguese',
            label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.PORTUGUESE',
          },
          {
            value: 'Japanese',
            label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.JAPANESE',
          },
          { value: 'Korean', label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.KOREAN' },
          { value: 'Russian', label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.RUSSIAN' },
          { value: 'Chinese', label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.CHINESE' },
          { value: 'Thai', label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.THAI' },
          { value: 'Hindi', label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.HINDI' },
          { value: 'Arabic', label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.ARABIC' },
          { value: 'Turkish', label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.TURKISH' },
        ],
      },
    ],
  },
  // TYPE FORM CREATE
  {
    columns: [
      {
        field: 'type',
        placeholder: 'PROMPT.FIELDS.PLACEHOLDERS.TYPE',
        label: 'PROMPT.FIELDS.LABELS.TYPE',
        type: 'enum',
        defaultValue: undefined,
        disabled: false,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: true,
        required: true,
        options: [
          { value: 'tldr', label: 'PROMPT.FIELDS.OPTIONS.TYPE.TLDR' },
          {
            value: 'description',
            label: 'PROMPT.FIELDS.OPTIONS.TYPE.DESCRIPTION',
          },
          { value: 'keywords', label: 'PROMPT.FIELDS.OPTIONS.TYPE.KEYWORDS' },
          { value: 'rewrite', label: 'PROMPT.FIELDS.OPTIONS.TYPE.REWRITE' },
          {
            value: 'instructions',
            label: 'PROMPT.FIELDS.OPTIONS.TYPE.INSTRUCTIONS',
          },
          {
            value: 'synthesize',
            label: 'PROMPT.FIELDS.OPTIONS.TYPE.SYNTHESIZE',
          },
          { value: 'topics', label: 'PROMPT.FIELDS.OPTIONS.TYPE.TOPICS' },
          { value: 'content', label: 'PROMPT.FIELDS.OPTIONS.TYPE.CONTENT' },
          { value: 'toc', label: 'PROMPT.FIELDS.OPTIONS.TYPE.TOC' },
          { value: 'titles', label: 'PROMPT.FIELDS.OPTIONS.TYPE.TITLES' },
        ],
      },
    ],
  },
  // ORDER FORM CREATE
  {
    columns: [
      {
        field: 'order',
        placeholder: 'PROMPT.FIELDS.PLACEHOLDERS.ORDER',
        label: 'PROMPT.FIELDS.LABELS.ORDER',
        type: 'number',
        defaultValue: undefined,
        disabled: true,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: false,
        required: false,
      },
    ],
  },
  // CREATEDAT FORM CREATE
  {
    columns: [
      {
        field: 'createdAt',
        placeholder: 'PROMPT.FIELDS.PLACEHOLDERS.CREATEDAT',
        label: 'PROMPT.FIELDS.LABELS.CREATEDAT',
        type: 'text',
        defaultValue: undefined,
        disabled: true,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: false,
        required: false,
      },
    ],
  },
  // UPDATEDAT FORM CREATE
  {
    columns: [
      {
        field: 'updatedAt',
        placeholder: 'PROMPT.FIELDS.PLACEHOLDERS.UPDATEDAT',
        label: 'PROMPT.FIELDS.LABELS.UPDATEDAT',
        type: 'text',
        defaultValue: undefined,
        disabled: true,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: false,
        required: false,
      },
    ],
  },
  // DELETEDAT FORM CREATE
  {
    columns: [
      {
        field: 'deletedAt',
        placeholder: 'PROMPT.FIELDS.PLACEHOLDERS.DELETEDAT',
        label: 'PROMPT.FIELDS.LABELS.DELETEDAT',
        type: 'text',
        defaultValue: undefined,
        disabled: true,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: false,
        required: false,
      },
    ],
  },
  // ATTRIBUTES FOR CREATE FORM:
];

export const editRows: CbFormRow[] = [
  // { columns: [{ field: "name", placeholder: "PRODUCTS.FIELDS.PLACEHOLDERS.NAME", label: "PRODUCTS.FIELDS.LABELS.NAME", type: "text", required: true }]},
  // ID FORM UPDATE
  {
    columns: [
      {
        field: 'id',
        placeholder: 'PROMPT.FIELDS.PLACEHOLDERS.ID',
        label: 'PROMPT.FIELDS.LABELS.ID',
        type: 'id',
        defaultValue: undefined,
        disabled: true,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: false,
        required: true,
      },
    ],
  },
  // TITLE FORM UPDATE
  {
    columns: [
      {
        field: 'title',
        placeholder: 'PROMPT.FIELDS.PLACEHOLDERS.TITLE',
        label: 'PROMPT.FIELDS.LABELS.TITLE',
        type: 'text',
        defaultValue: undefined,
        disabled: false,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: true,
        required: true,
      },
    ],
  },
  // CONTENT FORM UPDATE
  {
    columns: [
      {
        field: 'content',
        placeholder: 'PROMPT.FIELDS.PLACEHOLDERS.CONTENT',
        label: 'PROMPT.FIELDS.LABELS.CONTENT',
        type: 'textarea',
        defaultValue: undefined,
        disabled: false,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: true,
        required: true,
      },
    ],
  },
  // PROPERTIES FORM UPDATE
  {
    columns: [
      // TODO MANAGE PROPERTIES ARRAY
    ],
  },
  // TEMPERATURE FORM UPDATE
  {
    columns: [
      {
        field: 'temperature',
        placeholder: 'PROMPT.FIELDS.PLACEHOLDERS.TEMPERATURE',
        label: 'PROMPT.FIELDS.LABELS.TEMPERATURE',
        type: 'number',
        defaultValue: undefined,
        disabled: false,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: true,
        required: false,
      },
    ],
  },
  // MAXTOKENS FORM UPDATE
  {
    columns: [
      {
        field: 'maxTokens',
        placeholder: 'PROMPT.FIELDS.PLACEHOLDERS.MAXTOKENS',
        label: 'PROMPT.FIELDS.LABELS.MAXTOKENS',
        type: 'number',
        defaultValue: undefined,
        disabled: false,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: true,
        required: false,
      },
    ],
  },
  // LANGUAGE FORM UPDATE
  {
    columns: [
      {
        field: 'language',
        placeholder: 'PROMPT.FIELDS.PLACEHOLDERS.LANGUAGE',
        label: 'PROMPT.FIELDS.LABELS.LANGUAGE',
        type: 'enum',
        defaultValue: undefined,
        disabled: false,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: true,
        required: true,
        options: [
          { value: 'English', label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.ENGLISH' },
          { value: 'French', label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.FRENCH' },
          { value: 'Spanish', label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.SPANISH' },
          { value: 'German', label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.GERMAN' },
          { value: 'Dutch', label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.DUTCH' },
          { value: 'Italian', label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.ITALIAN' },
          {
            value: 'Portuguese',
            label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.PORTUGUESE',
          },
          {
            value: 'Japanese',
            label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.JAPANESE',
          },
          { value: 'Korean', label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.KOREAN' },
          { value: 'Russian', label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.RUSSIAN' },
          { value: 'Chinese', label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.CHINESE' },
          { value: 'Thai', label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.THAI' },
          { value: 'Hindi', label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.HINDI' },
          { value: 'Arabic', label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.ARABIC' },
          { value: 'Turkish', label: 'PROMPT.FIELDS.OPTIONS.LANGUAGE.TURKISH' },
        ],
      },
    ],
  },
  // TYPE FORM UPDATE
  {
    columns: [
      {
        field: 'type',
        placeholder: 'PROMPT.FIELDS.PLACEHOLDERS.TYPE',
        label: 'PROMPT.FIELDS.LABELS.TYPE',
        type: 'enum',
        defaultValue: undefined,
        disabled: false,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: true,
        required: true,
        options: [
          { value: 'tldr', label: 'PROMPT.FIELDS.OPTIONS.TYPE.TLDR' },
          {
            value: 'description',
            label: 'PROMPT.FIELDS.OPTIONS.TYPE.DESCRIPTION',
          },
          { value: 'keywords', label: 'PROMPT.FIELDS.OPTIONS.TYPE.KEYWORDS' },
          { value: 'rewrite', label: 'PROMPT.FIELDS.OPTIONS.TYPE.REWRITE' },
          {
            value: 'instructions',
            label: 'PROMPT.FIELDS.OPTIONS.TYPE.INSTRUCTIONS',
          },
          {
            value: 'synthesize',
            label: 'PROMPT.FIELDS.OPTIONS.TYPE.SYNTHESIZE',
          },
          { value: 'topics', label: 'PROMPT.FIELDS.OPTIONS.TYPE.TOPICS' },
          { value: 'content', label: 'PROMPT.FIELDS.OPTIONS.TYPE.CONTENT' },
          { value: 'toc', label: 'PROMPT.FIELDS.OPTIONS.TYPE.TOC' },
          { value: 'titles', label: 'PROMPT.FIELDS.OPTIONS.TYPE.TITLES' },
        ],
      },
    ],
  },
  // ORDER FORM UPDATE
  {
    columns: [
      {
        field: 'order',
        placeholder: 'PROMPT.FIELDS.PLACEHOLDERS.ORDER',
        label: 'PROMPT.FIELDS.LABELS.ORDER',
        type: 'number',
        defaultValue: undefined,
        disabled: true,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: false,
        required: false,
      },
    ],
  },
  // CREATEDAT FORM UPDATE
  {
    columns: [
      {
        field: 'createdAt',
        placeholder: 'PROMPT.FIELDS.PLACEHOLDERS.CREATEDAT',
        label: 'PROMPT.FIELDS.LABELS.CREATEDAT',
        type: 'text',
        defaultValue: undefined,
        disabled: true,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: false,
        required: false,
      },
    ],
  },
  // UPDATEDAT FORM UPDATE
  {
    columns: [
      {
        field: 'updatedAt',
        placeholder: 'PROMPT.FIELDS.PLACEHOLDERS.UPDATEDAT',
        label: 'PROMPT.FIELDS.LABELS.UPDATEDAT',
        type: 'text',
        defaultValue: undefined,
        disabled: true,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: false,
        required: false,
      },
    ],
  },
  // DELETEDAT FORM UPDATE
  {
    columns: [
      {
        field: 'deletedAt',
        placeholder: 'PROMPT.FIELDS.PLACEHOLDERS.DELETEDAT',
        label: 'PROMPT.FIELDS.LABELS.DELETEDAT',
        type: 'text',
        defaultValue: undefined,
        disabled: true,
        validator: undefined,
        validatorError: undefined,
        data: undefined,
        show: false,
        required: false,
      },
    ],
  },
  // ATTRIBUTES FOR UPDATE FORM:
];
