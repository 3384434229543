import { lastValueFrom, Subject } from "rxjs";
import { BasicPresenter } from "src/app/classes/presenters/basicPresenter";
import { CompletitionPresenter } from "src/app/classes/presenters/completitionPresenter";
import { EditPresenter } from "src/app/classes/presenters/editPresenter";
import { PromptsPresenter } from "src/app/classes/presenters/promptsPresenter";
import { UserPresenter } from "src/app/classes/presenters/userPresenter";
import { UsersPresenter } from "src/app/classes/presenters/usersPresenter";
import { Workspace, WorkspaceArticle } from "src/app/classes/workspace";
import { TranslateCustomService } from "src/app/services/translate-custom.service";
import * as uuid from "uuid";
import { CreateEditPromptService } from "../prompts/create-edit-prompt.service";
import { defaultPromptsContent, DEFAULT_PROMPTS } from "./AiDefault";
import { LanguageType } from "./dialogs/select-generation-dialog/select-generation-dialog.interfaces";
import { LANGUAGES } from "./utils";
import { emptyBlock, fromBlockGetHtml, fromHtmlGetBlocks } from "src/app/widgets/cb-classic-editor/cb-ngx-editor/ngx-utils";
const showdown = require('showdown');

export const basicPrompt = `Use this information to write better contents. When it comes to writing content, two factors are crucial, "perplexity" and "burstiness." Perplexity measures the complexity of text. Separately, burstiness compares the variations of sentences. Humans tend to write with greater burstiness, for example, with some longer or complex sentences alongside shorter ones. AI sentences tend to be more uniform. Therefore, when writing the following content I am going to ask you to create, I need it to have a good amount of perplexity and burstiness. `

export const basicPropertiesMap: { [key: string]: keyof Workspace["basic"] } = {
    "language": "lang",
    "businessType": "type",
    "businessName": "title",
    "businessDescription": "description",
}
export type promptType = "tldr"
    | "description"
    | "keywords"
    | "rewrite"
    | "instructions"
    | "synthesize"
    | "topics"
    | "content"
    | "contentChat"
    | "toc"
    | "tocChat"
    | "titles"
    | "intro"
    | "theTitleIs"
    | "keepTone"
    | "keepWordLength"
    | "useKeywords"
    | "useAdditional"
    | "theCurrentHeadingsAre"
    | "theParagraphsAre"
    | "contentInHeadings"
    | "translateIntro"
    | "translateContent"
    | "rewriteIntro"
    | "rewriteContent"
    | "rewriteInstructions";

export type promptParam = { content?: string, section?: string, topic?: string, type?: string, title?: string, tone?: string, additionalInformation?: string, keywords?: string, wordLength?: number };

export type aiReturnObj = Promise<{ result: string | { id: string, title: string, level: number }[], tokenUsed: number }>;
export type generateFromTypeBody = { type: promptType | promptType[]; data: promptParam | promptParam[]; customContent?: string; };
export class AiManager {

    private presenter = new PromptsPresenter();

    public static items: Workspace["prompts"] = [];

    public static basic: Workspace["basic"] | null = null;

    public static users: Workspace["users"] | null = null;

    public static myUsername: string | null = null;

    public static admin: boolean = false;

    public static lang: LanguageType = "English";

    public static fromAuthorToUsername(author: string): string {
        if (AiManager.users) {
            const user = AiManager.users.find(u => u.username === author);
            if (user)
                return user.username;
        }
        const user = AiManager.users?.find(u => u.privileges["admin"]);
        const username = user?.username ?? author;
        return username;
    };

    public static subjectToken: Subject<number> = new Subject<number>();

    public promptFromType = (type: promptType): Workspace["prompts"][0] => {
        const prompt = this.promptFromItems(type);
        return (prompt ? prompt : this.getDefaultPrompt(type)) as Workspace["prompts"][0];
    }

    private promptFromItems(type: promptType): Workspace["prompts"][0] | null {
        for (let i = 0; i < AiManager.items.length; i++) {
            if (AiManager.items[i].type === type && AiManager.lang === AiManager.items[i].language) {
                return AiManager.items[i];
            }
        }

        return null
    }

    public getDefaultPrompt(key: string): { content: string, title: string, description: string, temperature: number, maxTokens: number, requiredParams: string[], isList: boolean } {
        let prompt = this.defaultPrompts[key];

        const currentLang = AiManager.lang;
        const code = LANGUAGES.find(l => l.value === currentLang)?.code ?? "other";
        const langCode = code;

        console.log("langCode", langCode, currentLang);

        if (defaultPromptsContent[key][langCode])
            prompt.content = defaultPromptsContent[key][langCode];
        else
            prompt.content = defaultPromptsContent[key]["other"];

        return prompt;
    }

    public defaultPrompts: { [key: string]: { content: string, title: string, description: string, temperature: number, maxTokens: number, requiredParams: string[], isList: boolean, isToc: boolean } } = DEFAULT_PROMPTS;

    public async testPrompt(content: string, type: promptType, data: promptParam): aiReturnObj {
        // Find from default prompts
        return this.generateFromType({ type, data, customContent: content });
    }

    private async sendRequest(input: { model: string, prompt?: string, messages?: { role: string, content: string }[], top_p: number, frequency_penalty: number, presence_penalty: number }, isList: boolean): aiReturnObj {
        const completitionPresenter = new CompletitionPresenter();
        try {
            let response = await lastValueFrom(completitionPresenter.postOneRequested({ input: input }));
            if (response && response.result) {
                const text = response.result?.replace(/.*\n\n/, '').replace(input.prompt ?? "", '');
                const tokenUsed = response.tokenUsed ?? 0;
                AiManager.subjectToken.next(tokenUsed);
                if (AiManager.basic) {
                    if (AiManager.basic.monthlyGPTTokenUsed === undefined)
                        AiManager.basic.monthlyGPTTokenUsed = 0;
                    AiManager.basic.monthlyGPTTokenUsed = AiManager.basic.monthlyGPTTokenUsed + tokenUsed;
                }
                if (isList) {
                    const json = await this.convertInJson({ list: text });
                    return { result: json, tokenUsed: tokenUsed };
                } else
                    return { result: text, tokenUsed: tokenUsed };
            } else {
                throw new Error("Error in the response");
            }
        } catch (error) {
            console.error(error);
            throw new Error("Error in the api");
        }
    }

    private createMessage({ type, data, customContent }: { type: promptType, data: promptParam, customContent?: string }): { prompt: any, defaultPrompt: any } {

        // Find from default prompts
        const defaultPrompt = this.getDefaultPrompt(type);

        // Find from items
        const item = this.promptFromItems(type);

        const prompt = (customContent) ? { ...(item ?? defaultPrompt), content: customContent, maxTokens: 3900 } : (item ?? defaultPrompt);

        const limitToken = 3000;

        if (prompt.maxTokens && prompt.maxTokens + (prompt.content.length / 4) > limitToken) {
            prompt.maxTokens = Math.ceil(limitToken - (prompt.content.length / 4));
        }


        const basic = AiManager.basic;

        if (!basic)
            throw new Error("Error in loading the workspace");

        const keys = Object.keys(basicPropertiesMap);
        const values = Object.values(basicPropertiesMap);


        const properties2 = prompt.content.match(/{{(.*?)}}/g);

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const value = basicPropertiesMap[key];
            if (!(<any>data)[key])
                prompt.content = prompt.content.replace("{{" + key + "}}", <string>basic[value] ?? '');
        }

        const properties = prompt.content.match(/{{(.*?)}}/g);

        if (properties) {
            for (let i = 0; i < properties.length; i++) {
                // Replace in the prompt content the {{property}} with the value of the property
                const property = properties[i].replace("{{", "").replace("}}", "");
                prompt.content = prompt.content.replace("{{" + property + "}}", (<any>data)[property] ?? '');
            }
            for (let i = 0; i < properties.length; i++) {
                // Replace in the prompt content the {{}} with ""
                const property = properties[i].replace("{{", "").replace("}}", "");
                prompt.content = prompt.content.replace("{{" + property + "}}", "");
            }
        }

        return { prompt: prompt, defaultPrompt: defaultPrompt };
    }

    public async generateFromType({ type, data, customContent }: generateFromTypeBody): aiReturnObj {

        try {
            await AiManager.loadBasic();
        } catch (error) {
            console.error(error);
            throw new Error("Error in loading the workspace");
        }
        try {
            const currentModel = AiManager.basic?.model;
            if (currentModel === "gpt-3.5-turbo" || currentModel === "gpt-4") {

                let newType: promptType[] = [];
                let newData: promptParam[] = [];
                const oldData = data as promptParam;
                if (type == "content") {
                    newType.push("intro")
                    newData.push({});
                    newType.push("theTitleIs")
                    newData.push({ title: oldData.title ?? "" });
                    if (oldData.tone) {
                        newType.push("keepTone");
                        newData.push({ tone: oldData.tone });
                    }
                    if (oldData.wordLength) {
                        newType.push("keepWordLength");
                        newData.push({ content: oldData.wordLength.toString() });
                    }
                    if (oldData.additionalInformation) {
                        newType.push("useAdditional");
                        newData.push({ additionalInformation: oldData.additionalInformation });
                    }
                    if (oldData.keywords) {
                        newType.push("useKeywords");
                        newData.push({ keywords: oldData.keywords });
                    }
                    newType.push("contentChat");
                    newData.push({ content: oldData.content ?? "", section: oldData.section ?? "" });
                    type = newType;
                    data = newData;

                } else if (type == "toc") {
                    newType.push("theTitleIs")
                    newData.push({ title: oldData.content ?? "" });
                    if (oldData.tone) {
                        newType.push("keepTone");
                        newData.push({ tone: oldData.tone });
                    }
                    if (oldData.wordLength) {
                        newType.push("keepWordLength");
                        newData.push({ content: oldData.wordLength.toString() });
                    }
                    if (oldData.additionalInformation) {
                        newType.push("useAdditional");
                        newData.push({ additionalInformation: oldData.additionalInformation });
                    }
                    if (oldData.keywords) {
                        newType.push("useKeywords");
                        newData.push({ keywords: oldData.keywords });
                    }
                    newType.push("tocChat");
                    newData.push({});
                    type = newType;
                    data = newData;
                }
            }

            if (!(type instanceof Array) && !(data instanceof Array)) {
                const { prompt, defaultPrompt } = this.createMessage({ type, data: data, customContent });
                const input = (AiManager.basic?.model == "gpt-3.5-turbo" || AiManager.basic?.model == "gpt-4") ? {
                    model: AiManager.basic?.model,
                    messages: [{ role: "user", content: prompt.content }],
                    temperature: prompt.temperature,
                    max_tokens: prompt.maxTokens,
                    top_p: 1,
                    frequency_penalty: 0,
                    presence_penalty: 0,
                } : {
                    model: "text-davinci-003",
                    prompt: prompt.content,
                    temperature: prompt.temperature,
                    max_tokens: prompt.maxTokens,
                    top_p: 1,
                    frequency_penalty: 0,
                    presence_penalty: 0
                }
                return this.sendRequest(input, defaultPrompt.isList);
            } else if (type instanceof Array && data instanceof Array) {
                const messages = [];
                let prompt = null;
                let defaultPrompt = null;
                // Load basic if not exist 

                for (let i = 0; i < type.length; i++) {
                    const result = this.createMessage({ type: type[i], data: data[i], customContent });
                    prompt = result.prompt;
                    defaultPrompt = result.defaultPrompt;
                    messages.push({ role: i == type.length - 1 ? "user" : "system", content: prompt.content });
                }
                const input = {
                    model: AiManager.basic?.model === "gpt-4" ? "gpt-4" : "gpt-3.5-turbo",
                    messages: messages,
                    temperature: prompt.temperature,
                    max_tokens: prompt.maxTokens,
                    top_p: 1,
                    frequency_penalty: 0,
                    presence_penalty: 0,
                }
                return this.sendRequest(input, defaultPrompt.isList);
            }
        } catch (error) {
            console.error(error);
            throw new Error("Error in the parameters");
        }

        throw new Error("Error in the parameters");
    }

    public async updatePrompt(type: promptType, content: string, temperature: number, maxTokens: number) {
        if (temperature > 1 || temperature < 0)
            throw new Error("Temperature must be between 0 and 1");
        if (maxTokens < 0)
            throw new Error("Max tokens must be greater than 0");

        // Find from default prompts
        const defaultPrompt = this.getDefaultPrompt(type);
        const defaultPromptRequiredParams = defaultPrompt.requiredParams;

        for (let i = 0; i < defaultPromptRequiredParams.length; i++) {
            if (!content.includes(`{{${defaultPromptRequiredParams[i]}}}`))
                throw new Error(`The prompt must contain the parameter {{${defaultPromptRequiredParams[i]}}}`);
        }

        // Find from items
        const item = this.promptFromItems(type);

        if (item) {
            // Update the element 
            const putBody = CreateEditPromptService.putValuesToBody({ ...item, content, temperature, maxTokens, language: AiManager.lang });
            try {
                await lastValueFrom(this.presenter.putOneRequested(putBody));
                this.loadPrompts(true);
            } catch (error) {
                console.error(error);
                throw new Error("Error in the api");
            }
        } else {
            // Create the element
            const properties = content.match(/{{(.*?)}}/g) ?? [];
            const postBody = CreateEditPromptService.postValuesToBody({ ...defaultPrompt, type, content, temperature, maxTokens, properties, language: AiManager.lang });
            try {
                await lastValueFrom(this.presenter.postOneRequested(postBody));
                this.loadPrompts(true);
            }
            catch (error) {
                console.error(error);
                throw new Error("Error in the api");
            }
        }
    }

    public async loadPrompts(force: boolean = false) {
        if (!force && AiManager.items && AiManager.items.length > 0)
            return;

        const prompts = await lastValueFrom(this.presenter.getManyRequested({}).promise);
        if (prompts && prompts.main) {
            AiManager.items = prompts.main as Workspace["prompts"];
        }
    }

    private async convertInJson({ list }: { list: string }): Promise<{ id: string, title: string, level: number }[]> {
        let input = {
            model: "text-davinci-edit-001",
            input: list,
            instruction: `Rewrite in a json with the following format: title: title, id: id`,
            temperature: 0.7,
            top_p: 1
        }

        // Try to parse the list in json first manually by splitting the list and if you got errors ask the AI to do it

        try {
            // Divide for every new line
            const splitted = list.split(/\r?\n/);
            let json = splitted.map((item) => {
                return { id: uuid.v4(), title: item, level: 1 };
            }).filter((item) => item.title !== "");

            for (let i = 0; i < json.length; i++) {
                const item = json[i];
                // Get all the text before the first special character                  
                const textBeforeChar = item.title.match(/^[a-zA-Z0-9 ]*/g) ?? ["1"];
                const isNumber = !isNaN(Number(textBeforeChar[0]));
                const isRoman = textBeforeChar[0].match(/^[ivxlcdm]+$/i) !== null;
                // Check if textBeforeChar is a string
                if (textBeforeChar !== null && textBeforeChar.length > 0 && textBeforeChar[0].length > 0) {
                    item.title = item.title.substring(textBeforeChar[0].length).trim();
                }

                // Replace all the text before the first white space
                const firstWhiteSpaceIndex = item.title.indexOf(" ");
                if (firstWhiteSpaceIndex !== -1) {
                    item.title = item.title.substring(firstWhiteSpaceIndex).trim();
                }

                if (isNumber) {
                    json[i] = { id: uuid.v4(), title: item.title, level: 1 };
                }
                else if (isRoman) {
                    json[i] = { id: uuid.v4(), title: item.title, level: 3 };
                }
                else {
                    json[i] = { id: uuid.v4(), title: item.title, level: 2 };
                }
            }

            json = json.filter((item) => item.title !== "");

            return json;
        } catch (error) {
            console.error("Unable to parse the list manually with regex", list);
            const editPresenter = new EditPresenter();
            const response = await lastValueFrom(editPresenter.postOneRequested({ input: input }));
            if (response.result == null) throw new Error("Error in the response");

            try {
                const jsonParsed = JSON.parse(response.result);
                const formatter = jsonParsed.map((item: { id: string, title: string }) => {
                    return { id: uuid.v4(), title: item.title };
                });
                return formatter;
            } catch (error) {
                return [{ id: uuid.v4(), title: list, level: 1 }];
            }

        }
    }

    public static async loadBasic(force: boolean = false) {
        if (AiManager.basic != null && !force)
            return;
        const basicPresenter = new BasicPresenter();
        const workspace = await lastValueFrom(basicPresenter.getOneRequested({ id: basicPresenter.getSelectedRequested() }).promise);
        if (workspace && workspace.main) {
            AiManager.basic = workspace.main as Workspace["basic"];
            AiManager.lang = AiManager.basic.lang;
            if (!AiManager.basic.model)
                AiManager.basic.model = "gpt-3.5-turbo";
            this.subjectToken.next(AiManager.basic.monthlyGPTTokenUsed ?? 0);
        }

        const userPresenter = new UserPresenter();
        const usersPresenter = new UsersPresenter();
        const users = await lastValueFrom(usersPresenter.getManyRequested({}).promise);
        if (users && users.main) {
            AiManager.users = users.main as Workspace["users"];
            const user = AiManager.users.find(x => x.id == userPresenter.getDecodedAccessToken()?.id);
            if (user) {
                const privileges = user.privileges;
                let isAdmin = false;
                if (privileges)
                    isAdmin = privileges["admin"] ?? false;
                AiManager.myUsername = user.username;
                AiManager.admin = isAdmin;
            }
        }
    }

    public static getCostFromTokenUsed(token: number) {
        const model = AiManager.basic?.model;
        if (model == "text-davinci-003")
            return token * 0.02 / 1000;
        else if (model == "gpt-4")
            return token * 0.06 / 1000;
        else
            return token * 0.002 / 1000;
    }

    public static isParallelAiAvailable() {
        return AiManager.basic?.parallelModeOn;
    }

    public static isBetaAiAvailable() {
        const beta = AiManager.basic?.betaModeOn;
        return true; //beta ?? false;
    }

}


export function normalizeResultForHtml(result: string): string {
    let text = result;
    if (text.toLowerCase().includes("html:") || text.toLowerCase().includes("html :")) {
        // Get the text after the html: or html : or HTML: or HTML :
        text = text.split(/html:|html :|HTML:|HTML :/)[1];
    }

    if (text.includes("```")) {
        // Remove content after the last ``` included
        text = text.split("```").slice(0, -1).join("```");
        // Remove content before the first ``` included
        text = text.split("```").slice(1).join("```");
    }

    // The text has inside html use regex to check 
    const regex = /<[^>]*>/g;
    const found = text.match(regex);

    if (found) {
        // Remove all the text outside the html tags
        const firstIndexOfHtml = text.indexOf("<");
        const lastIndexOfHtml = text.lastIndexOf(">");
        text = text.substring(firstIndexOfHtml, lastIndexOfHtml + 1);
    } else {
        // Markdown to html
        let converter = new showdown.Converter();
        let html = converter.makeHtml(text);
        text = html;
    }

    return text;
}
export async function fromHtmlToBlocks(content: string): Promise<RewriteBlock[]> {

    try {

        // Replace everything inside <!-- --> 
        content = content.replace(/<!--[\s\S]*?-->/g, "");
        content = `<div>${content}</div>`;

        // index of the first h1 or h2
        const index = content.search(/<h[1-2]/);
        if (index != -1) {
            // Remove everything before the index
            content = content.substring(index);
        }

        // Parse content 
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, "text/html");
        const body = doc.body;

        const defaultText = body.textContent?.trim() ?? "";

        try {
            // Get all the h1, h2, h3, h4, h5, h6

            // Remove from the body all the image, iframe, script, style, video, audio, canvas, svg, object, embed, noscript, map, area, form, input, textarea, select, button, label, fieldset, legend, output, progress, meter, details, summary, menu, menuitem, dialog, and template elements.
            const elementsToRemove = body.querySelectorAll(querySelectorToRemove);
            for (let i = 0; i < elementsToRemove.length; i++) {
                elementsToRemove[i].remove();
            }

            // Remove and replace <a> with the text content
            const links = body.querySelectorAll("a");
            for (let i = 0; i < links.length; i++) {
                const link = links[i];
                const text = link.textContent?.trim() ?? "";
                const span = document.createElement("span");
                span.innerHTML = "<i>" + text + "</i>";
                link.replaceWith(span);
            }

            // Remove all the possible attributes from each tag on the body
            const tags = body.querySelectorAll("*");
            for (let i = 0; i < tags.length; i++) {
                const tag = tags[i];
                let attributes = tag.attributes;
                for (let j = 0; j < attributes.length; j++) {
                    tag.removeAttribute(attributes[j].name);
                }
            }

            let hBlocks = fromHtmlGetBlocks(body.innerHTML)
            let blocks: RewriteBlock[] = [];
            for (let block of hBlocks) {
                blocks.push({
                    id: uuid.v4(),
                    block: block,
                    selected: false,
                    html: fromBlockGetHtml(block)
                })
            }

            return blocks;

        } catch (error) {
            console.error(error);
            // Divide the content in paragraphs split by <br> and \n and \r
            const paragraphs = defaultText.split(/<br\s*\/?>/gi).join("\n").split(/\r?\n/);

            let blocks: RewriteBlock[] = [];
            for (let i = 0; i < paragraphs.length; i++) {
                const paragraph = paragraphs[i];
                if (paragraph.trim() !== "") {
                    const block = emptyBlock
                    blocks.push({
                        id: uuid.v4(),
                        selected: false,
                        block: block,
                        html: fromBlockGetHtml(block)
                    })
                }
            }

            return blocks;
        }
    } catch (error) {
        console.error(error);
        // Divide the content in paragraphs split by <br> and \n and \r
        const paragraphs = content.split(/<br\s*\/?>/gi).join("\n").split(/\r?\n/);

        let blocks: RewriteBlock[] = [];
        for (let i = 0; i < paragraphs.length; i++) {
            const paragraph = paragraphs[i];
            if (paragraph.trim() !== "") {
                const block = emptyBlock;
                blocks.push({
                    id: uuid.v4(),
                    selected: false,
                    block: block,
                    html: fromBlockGetHtml(block)
                })
            }
        }

        return blocks;
    }
}

const querySelectorToRemove = "img,comment, br, iframe, script, style, video, audio, canvas, svg, object, embed, noscript, map, area, form, input, textarea, select, button, label, fieldset, legend, output, progress, meter, details, summary, menu, menuitem, dialog, template[style*='display: none'], [style*='visibility: hidden'],[style*='position: fixed'], [style*='position: absolute'][style*='top: 0'], [style*='position: absolute'][style*='left: 0'], [style*='position: absolute'][style*='right: 0'], [style*='position: absolute'][style*='bottom: 0'][style*='position: fixed'], [style*='position: absolute'], [style*='width: 100%'], [style*='position: absolute'][style*='height: 100%'"
interface RewriteBlock {
    id: string;
    html: string;
    selected: boolean;
    block: any
}
