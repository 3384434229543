<div class="cb-toast-group-wrapper">
  <div class="cb-toast-group-container">
    <ng-container *ngFor="let toast of toasts">
      <div class="cb-toast" @toastAnimation [ngClass]="(toast.message.type ?? 'primary')+'-toast'"
        (click)="removeToast(toast.id)">
        <ng-container *ngIf="toast.message.icon">
          <div class="cb-toast-icon">
            <ion-icon [name]="toast.message.icon"></ion-icon>
          </div>
        </ng-container>
        <div class="cb-toast-body">
          <h6>{{toast.message.title | translate}}</h6>
          <p>{{toast.message.message | translate}}</p>
        </div>
      </div>
    </ng-container>
  </div>
</div>