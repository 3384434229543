import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CbPasswordComponent } from './cb-password/cb-password.component';
import { CbInputComponent } from './cb-input/cb-input.component';
import { CbButtonComponent } from './cb-button/cb-button.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { CbColorPickerComponent } from './cb-color-picker/cb-color-picker.component';
import { CbFileInputComponent } from './cb-file-input/cb-file-input.component';
import { CbIconButtonComponent } from './cb-icon-button/cb-icon-button.component';
import { CbSelectComponent } from './cb-select/cb-select.component';
import { CbMultiSelectComponent } from './cb-multi-select/cb-multi-select.component';
import { CbCheckboxComponent } from './cb-checkbox/cb-checkbox.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { CbLangSelectComponent } from './cb-lang-select/cb-lang-select.component';
import { CbTranslateInputComponent } from './cb-translate-input/cb-translate-input.component';
import { AutosizeModule } from 'ngx-autosize';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { SliderModule } from 'primeng/slider';
import { CbTextareaComponent } from './cb-textarea/cb-textarea.component';
import { CbSliderComponent } from './cb-slider/cb-slider.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { CbTemplateInputComponent } from './cb-template-input/cb-template-input.component';
import { CbTimeInputComponent } from './cb-time-input/cb-time-input.component';
import { CbDateInputComponent } from './cb-date-input/cb-date-input.component';
import { InputMaskModule } from 'primeng/inputmask';
import { CalendarModule } from 'primeng/calendar';
import { CbSearchComponent } from './cb-search/cb-search.component';
import { CbPinInputComponent } from './cb-pin-input/cb-pin-input.component';
import { CbButtonLoadingComponent } from './cb-button-loading/cb-button-loading.component';
import { CbCodeInputComponent } from './cb-code-input/cb-code-input.component';
import { CbEmailInputComponent } from './cb-email-input/cb-email-input.component';
import { CbMonthInputComponent } from './cb-month-input/cb-month-input.component';
import { CbUrlInputComponent } from './cb-url-input/cb-url-input.component';
import { CbPhoneInputComponent } from './cb-phone-input/cb-phone-input.component';
import { CbIdInputComponent } from './cb-id-input/cb-id-input.component';
import { CbChipsInputComponent } from './cb-chips-input/cb-chips-input.component';
import { TooltipModule } from 'primeng/tooltip';
import { CbColorInputComponent } from './cb-color-input/cb-color-input.component';
import { ColorPickerModule } from 'primeng/colorpicker';
import { Chips, ChipsModule } from 'primeng/chips';
import { CbIconModule } from '../cb-icon/cb-icon.module';
import { CbIconsPickerComponent } from './cb-icons-picker/cb-icons-picker.component';
import { VirtualScrollerModule } from 'primeng/virtualscroller';

@NgModule({
  declarations: [
    CbInputComponent,
    CbButtonComponent,
    CbPasswordComponent,
    CbColorPickerComponent,
    CbFileInputComponent,
    CbIconButtonComponent,
    CbSelectComponent,
    CbMultiSelectComponent,
    CbCheckboxComponent,
    ClickOutsideDirective,
    CbLangSelectComponent,
    CbTranslateInputComponent,
    CbTextareaComponent,
    CbSliderComponent,
    CbTemplateInputComponent,
    CbTimeInputComponent,
    CbDateInputComponent,
    CbSearchComponent,
    CbPinInputComponent,
    CbButtonLoadingComponent,
    CbCodeInputComponent,
    CbEmailInputComponent,
    CbMonthInputComponent,
    CbUrlInputComponent,
    CbPhoneInputComponent,
    CbIdInputComponent,
    CbChipsInputComponent,
    CbColorInputComponent,
    CbIconsPickerComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    IonicModule,
    TranslateModule,
    RouterModule,
    AutosizeModule,
    DropdownModule,
    MultiSelectModule,
    SliderModule,
    NgxFileDropModule,
    InputMaskModule,
    CalendarModule,
    TooltipModule,
    ColorPickerModule,
    ChipsModule,
    CbIconModule,
    VirtualScrollerModule
  ],
  exports: [
    CbInputComponent,
    CbButtonComponent,
    CbPasswordComponent,
    CbColorPickerComponent,
    CbFileInputComponent,
    CbIconButtonComponent,
    CbSelectComponent,
    CbMultiSelectComponent,
    CbCheckboxComponent,
    CbTranslateInputComponent,
    CbTextareaComponent,
    CbLangSelectComponent,
    CbSliderComponent,
    CbTemplateInputComponent,
    CbTimeInputComponent,
    CbDateInputComponent,
    CbSearchComponent,
    CbPinInputComponent,
    CbButtonLoadingComponent,
    CbCodeInputComponent,
    CbEmailInputComponent,
    CbMonthInputComponent,
    CbUrlInputComponent,
    CbPhoneInputComponent,
    CbIdInputComponent,
    CbChipsInputComponent,
    CbColorInputComponent,
    CbIconsPickerComponent,
  ],
})
export class CbFormWidgetsModule { }
