import { ErrorHandler, Injectable } from '@angular/core';
declare const window: any;
@Injectable({
  providedIn: 'root'
})
export class HawkErrorHandlerService extends ErrorHandler {
  constructor() { 
    super();
  }

  override handleError(error:any) {
    if(window.hawk){
      window.hawk.send(error);
    }
    super.handleError(error);
  }
}
