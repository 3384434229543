import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cb-loader',
  templateUrl: './cb-loader.component.html',
  styleUrls: ['./cb-loader.component.scss']
})
export class CbLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
