/// IMPORTS
import {
  WorkspacePromptGetQuery,
  WorkspacePromptGetResponse,
  WorkspacePromptPostBody,
  WorkspacePromptPostResponse,
  WorkspacePromptPutBody,
  WorkspacePromptPutResponse,
  WorkspacePromptDeleteQuery,
  WorkspacePromptDeleteResponse,
  WorkspacePromptsGetQuery,
  WorkspacePromptsGetResponse,
  WorkspacePromptPostsBody,
  WorkspacePromptPostsResponse,
  WorkspacePromptPutsBody,
  WorkspacePromptPutsResponse,
  WorkspacePromptDeletesQuery,
  WorkspacePromptDeletesResponse,
  WorkspacePromptOrderPutBody,
  WorkspacePromptOrderPutResponse,
} from 'generatedInterfaces';
import { PromptsModel } from 'src/app/classes/models/promptsModel';
import { Workspace } from '../workspace';
import { Observable } from 'rxjs/internal/Observable';

export class PromptsPresenter {
  /// PROPERTIES
  private model: PromptsModel = new PromptsModel();

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  // workspacePromptOrderPut
  public changeOrderRequested(
    obj: WorkspacePromptOrderPutBody
  ): Observable<WorkspacePromptOrderPutResponse> {
    return this.model.changeOrder(obj);
  }

  // workspacePromptDeletes
  public deletesManyRequested(
    obj: WorkspacePromptDeletesQuery
  ): Observable<WorkspacePromptDeletesResponse> {
    return this.model.deletesMany(obj);
  }

  // workspacePromptPuts
  public putsManyRequested(
    obj: WorkspacePromptPutsBody
  ): Observable<WorkspacePromptPutsResponse> {
    return this.model.putsMany(obj);
  }

  // workspacePromptPosts
  public postsManyRequested(
    obj: WorkspacePromptPostsBody
  ): Observable<WorkspacePromptPostsResponse> {
    return this.model.postsMany(obj);
  }

  // workspacePromptsGet
  public getManyRequested(obj: WorkspacePromptsGetQuery): {
    local: Workspace['prompts'];
    promise: Observable<WorkspacePromptsGetResponse>;
  } {
    return this.model.getMany(obj);
  }

  // workspacePromptDelete
  public deleteOneRequested(
    obj: WorkspacePromptDeleteQuery
  ): Observable<WorkspacePromptDeleteResponse> {
    return this.model.deleteOne(obj);
  }

  // workspacePromptPut
  public putOneRequested(
    obj: WorkspacePromptPutBody
  ): Observable<WorkspacePromptPutResponse> {
    return this.model.putOne(obj);
  }

  // workspacePromptPost
  public postOneRequested(
    obj: WorkspacePromptPostBody
  ): Observable<WorkspacePromptPostResponse> {
    return this.model.postOne(obj);
  }

  // workspacePromptGet
  public getOneRequested(obj: WorkspacePromptGetQuery): {
    local: Workspace['prompts'][0];
    promise: Observable<WorkspacePromptGetResponse>;
  } {
    return this.model.getOne(obj);
  }

  /// CUSTOM METHODS

  ///
}
