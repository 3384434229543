/// IMPORTS
import {
  WorkspaceLinkGetQuery,
  WorkspaceLinkGetResponse,
  WorkspaceLinkPostBody,
  WorkspaceLinkPostResponse,
  WorkspaceLinkPutBody,
  WorkspaceLinkPutResponse,
  WorkspaceLinkDeleteQuery,
  WorkspaceLinkDeleteResponse,
  WorkspaceLinksGetQuery,
  WorkspaceLinksGetResponse,
  WorkspaceLinkPostsBody,
  WorkspaceLinkPostsResponse,
  WorkspaceLinkPutsBody,
  WorkspaceLinkPutsResponse,
  WorkspaceLinkDeletesQuery,
  WorkspaceLinkDeletesResponse,
  WorkspaceLinkOrderPutBody,
  WorkspaceLinkOrderPutResponse,
} from 'generatedInterfaces';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Workspace } from '../workspace';

export class LinksOfflineEndpoint {
  /// PROPERTIES

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  // Delete a local entity
  public delete(obj: Workspace['links'][0]) {
    const links = this.getMany();
    links.splice(
      links.findIndex((entity) => {
        if (entity.id === obj.id) {
          return true;
        }
        return false;
      }),
      1
    );
    this.setEntities(links);
  }

  // Push or update an entity
  public pushOrUpdate(obj: Workspace['links'][0]): void {
    const links = this.getMany();
    const index = links.findIndex((entity) => entity.id === obj.id);
    if (index === -1) {
      links.push(obj);
    } else {
      links[index] = obj;
    }
    this.setEntities(links);
  }

  // Get all the local entities
  public getMany(obj?: any): Workspace['links'] {
    if (localStorage.getItem('links') === null) {
      return [];
    }
    let entities = JSON.parse(
      localStorage.getItem('links') as any
    ) as Workspace['links'];

    if (obj) {
      return entities.filter((entity) => {
        let hasProperties = false;
        for (const key in obj) {
          hasProperties = true;
          if (obj.hasOwnProperty(key) && entity.hasOwnProperty(key)) {
            if ((<any>entity)[key] === (<any>obj)[key]) {
              return true;
            }
          }
        }
        if (!hasProperties) return true;
        return false;
      });
    }

    return entities;
  }

  // Get one of the local entities
  public getOne(obj: any): Workspace['links'][0] {
    let links = this.getMany();
    // Filter for all the value of obj
    links = links.filter((entity) => {
      let hasProperties = false;
      for (const key in obj) {
        hasProperties = true;
        if (obj.hasOwnProperty(key) && entity.hasOwnProperty(key)) {
          if ((<any>entity)[key] === (<any>obj)[key]) {
            return true;
          }
        }
      }
      if (!hasProperties) return true;
      return false;
    });

    return links[0];
  }

  // Set the entire entities array
  public setEntities(obj: Workspace['links']): void {
    localStorage.setItem('links', JSON.stringify(obj));
  }

  /// CUSTOM METHODS

  ///
}
