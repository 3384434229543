export const LANGUAGES = [
  {
    label: 'CREATE_WORKSPACE.LANGUAGE.ENGLISH',
    value: 'English',
    emoji: '🇺🇸',
    code: 'en',
  },
  {
    label: 'CREATE_WORKSPACE.LANGUAGE.ITALIAN',
    value: 'Italian',
    emoji: '🇮🇹',
    code: 'it',
  },
  {
    label: 'CREATE_WORKSPACE.LANGUAGE.FRENCH',
    value: 'French',
    emoji: '🇫🇷',
    code: 'fr',
  },
  {
    label: 'CREATE_WORKSPACE.LANGUAGE.SPANISH',
    value: 'Spanish',
    emoji: '🇪🇸',
    code: 'es',
  },
  {
    label: 'CREATE_WORKSPACE.LANGUAGE.GERMAN',
    value: 'German',
    emoji: '🇩🇪',
    code: 'de',
  },
  {
    label: 'CREATE_WORKSPACE.LANGUAGE.DUTCH',
    value: 'Dutch',
    emoji: '🇳🇱',
    code: 'nl',
  },
  {
    label: 'CREATE_WORKSPACE.LANGUAGE.PORTUGUESE',
    value: 'Portuguese',
    emoji: '🇵🇹',
    code: 'pt',
  },
  {
    label: 'CREATE_WORKSPACE.LANGUAGE.POLISH',
    value: 'Polish',
    emoji: '🇵🇱',
    code: 'pl'
  },
  {
    label: 'CREATE_WORKSPACE.LANGUAGE.JAPANESE',
    value: 'Japanese',
    emoji: '🇯🇵',
    code: 'ja',
  },
  {
    label: 'CREATE_WORKSPACE.LANGUAGE.KOREAN',
    value: 'Korean',
    emoji: '🇰🇷',
    code: 'ko',
  },
  {
    label: 'CREATE_WORKSPACE.LANGUAGE.RUSSIAN',
    value: 'Russian',
    emoji: '🇷🇺',
    code: 'ru',
  },
  {
    label: 'CREATE_WORKSPACE.LANGUAGE.CHINESE',
    value: 'Chinese',
    emoji: '🇨🇳',
    code: 'zh',
  },
  {
    label: 'CREATE_WORKSPACE.LANGUAGE.THAI',
    value: 'Thai',
    emoji: '🇹🇭',
    code: 'th',
  },
  {
    label: 'CREATE_WORKSPACE.LANGUAGE.HINDI',
    value: 'Hindi',
    emoji: '🇮🇳',
    code: 'hi',
  },
  {
    label: 'CREATE_WORKSPACE.LANGUAGE.TURKISH',
    value: 'Turkish',
    emoji: '🇹🇷',
    code: 'tr',
  },
  {
    label: 'CREATE_WORKSPACE.LANGUAGE.INDONESIAN',
    value: 'Indonesian',
    emoji: '🇮🇩',
    code: 'id',
  },
];

export const BUSINESS_TYPES = [
  {
    label: 'CREATE_WORKSPACE.BUSINESS_TYPE.MARKETING_AGENCY',
    value: 'Marketing agency',
  },
  {
    label: 'CREATE_WORKSPACE.BUSINESS_TYPE.SOCIAL_MEDIA_AGENCY',
    value: 'Social media agency',
  },
  {
    label: 'CREATE_WORKSPACE.BUSINESS_TYPE.ONLINE_MARKETPLACE',
    value: 'Online marketplace',
  },
  {
    label: 'CREATE_WORKSPACE.BUSINESS_TYPE.WEB_DESIGN_COMPANY',
    value: 'Web design company',
  },
  {
    label: 'CREATE_WORKSPACE.BUSINESS_TYPE.SAAS_PROVIDER',
    value: 'SaaS provider',
  },
  {
    label: 'CREATE_WORKSPACE.BUSINESS_TYPE.AFFILIATE_MARKETING_COMPANY',
    value: 'Affiliate marketing company',
  },
  {
    label: 'CREATE_WORKSPACE.BUSINESS_TYPE.ONLINE_EDUCATION_COMPANY',
    value: 'Online education company',
  },
  {
    label: 'CREATE_WORKSPACE.BUSINESS_TYPE.IT_CONSULTANCY',
    value: 'IT consultancy',
  },
  {
    label: 'CREATE_WORKSPACE.BUSINESS_TYPE.SOFTWARE_DEVELOPMENT',
    value: 'Software development',
  },
  {
    label: 'CREATE_WORKSPACE.BUSINESS_TYPE.CYBER_SECURITY_SERVICE',
    value: 'Cyber security service',
  },
  {
    label: 'CREATE_WORKSPACE.BUSINESS_TYPE.CLOTHING_STORE',
    value: 'Clothing store',
  },
  { label: 'CREATE_WORKSPACE.BUSINESS_TYPE.RESTAURANT', value: 'Restaurant' },
  { label: 'CREATE_WORKSPACE.BUSINESS_TYPE.TECH_STORE', value: 'Tech store' },
  {
    label: 'CREATE_WORKSPACE.BUSINESS_TYPE.JEWELRY_STORE',
    value: 'Jewelry store',
  },
  { label: 'CREATE_WORKSPACE.BUSINESS_TYPE.BOOKSTORE', value: 'Bookstore' },
  { label: 'CREATE_WORKSPACE.BUSINESS_TYPE.BAKERY', value: 'Bakery' },
  { label: 'CREATE_WORKSPACE.BUSINESS_TYPE.COFFEE_SHOP', value: 'Coffee shop' },
  {
    label: 'CREATE_WORKSPACE.BUSINESS_TYPE.TRAVEL_AGENCY',
    value: 'Travel agency',
  },
  {
    label: 'CREATE_WORKSPACE.BUSINESS_TYPE.ELECTRONICS_STORE',
    value: 'Electronics store',
  },
  {
    label: 'CREATE_WORKSPACE.BUSINESS_TYPE.ONLINE_TRAVEL_AGENCY',
    value: 'Online travel agency',
  },
];
