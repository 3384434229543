import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { LinksPresenter } from './classes/presenters/linksPresenter';
import { UserPresenter } from './classes/presenters/userPresenter';
import { Workspace } from './classes/workspace';
import { CreateEditLinkService } from './modules/links/create-edit-link.service';
import { LinkSidebarService } from './services/link-sidebar.service';
import { PrivilegesService } from './services/privileges.service';
import { Translate } from './services/translate.service';
import { CbDialogEvent, CbDialogEventType } from './widgets/cb-dialog-form/abstract/cb-create-edit.service';
import { CbToastService } from './widgets/cb-toast/cb-toast.service';
import * as Fuse from 'fuse.js';
import { BasicPresenter } from './classes/presenters/basicPresenter';

interface SidebarItem {
  title: string,
  icon: string,
  url: string,
  privilege: keyof PrivilegesService["privileges"]
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: '0', }),
        animate(
          "100ms ease-in-out",
          style({ opacity: '1' })
        )
      ]),
      transition(':leave', [
        style({ opacity: '1', }),
        animate(
          "100ms ease-in-out",
          style({ opacity: '0' })
        )
      ]),
    ]),
    trigger('openClose', [
      transition(':enter', [
        style({ right: '-700px', }),
        animate(
          "250ms ease-in-out",
          style({ right: '0px' })
        )
      ]),
      transition(':leave', [
        style({ right: '0px', }),
        animate(
          "250ms ease-in-out",
          style({ right: '-700px' })
        )
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit, AfterViewInit {

  private userPresenter: UserPresenter = new UserPresenter();
  private url: string = "";
  public pageTitle: string = "";
  public isInitView: boolean = false;
  public showLinksSidebar: boolean = false;

  public links: Workspace['links'] = [];
  public linksFiltered: Workspace['links'] = [];

  public isAddingLink: boolean = false;
  public isEditingLink: boolean = false;
  public linkSearch: string = '';

  public currentFormLink?: Workspace['links'][0];

  private readonly pathsWithoutSidebar = ["auth"];

  public readonly sidebarItems: SidebarItem[] = [
    // PRODUCT
    {
      title: "SIDEBAR.PRODUCT",
      icon: "folder-open",
      url: "products",
      privilege: "none"
    },
    // LINK
    {
      title: "SIDEBAR.LINK",
      icon: "folder-open",
      url: "links",
      privilege: "none"
    },
    // PROMPT
    {
      title: "SIDEBAR.PROMPT",
      icon: "folder-open",
      url: "prompts",
      privilege: "none"
    },
    // ARTICLE
    {
      title: "SIDEBAR.ARTICLE",
      icon: "folder-open",
      url: "articles",
      privilege: "none"
    },
    // USER
    {
      title: "SIDEBAR.USER",
      icon: "folder-open",
      url: "users",
      privilege: "none"
    },
    // ADD HERE ENTITIES
  ];

  public get disabledFormLinks(): boolean {
    return this.currentFormLink && (this.currentFormLink.keywords.length === 0 || !this.currentFormLink.url || this.currentFormLink.url.length === 0 || !this.currentFormLink.title || this.currentFormLink.title.length === 0) ? true : false;
  }

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly cbToastService: CbToastService,
    private readonly translate: Translate,
    private readonly linksCreateEditService: CreateEditLinkService,
    private readonly sidebarService: LinkSidebarService
  ) {
    this.sidebarService.showSidebarSubject.subscribe((show: boolean) => {
      this.showLinksSidebar = show;
    });

    this.linksCreateEditService.onCreateEvent.subscribe((event: CbDialogEvent) => {
      if (event.type === CbDialogEventType.success) {
        this.loadLinks().then(() => {
          this.refreshLinks(this.links);
        });
      }
    });

    this.linksCreateEditService.onEditEvent.subscribe((event: CbDialogEvent) => {
      if (event.type === CbDialogEventType.success) {
        this.loadLinks().then(() => {
          this.refreshLinks(this.links);
        });;
      }
    });

    this.linksCreateEditService.onDeleteEvent.subscribe((event: CbDialogEvent) => {
      if (event.type === CbDialogEventType.success) {
        this.loadLinks().then(() => {
          this.refreshLinks(this.links);
        });;
      }
    });
  }

  ngOnInit(): void {
    const userPresenter = new UserPresenter();
    const basicPresenter = new BasicPresenter();

    if (userPresenter.isAuthenticated() && basicPresenter.getSelectedRequested() && basicPresenter.getSelectedRequested().length > 0) {
      this.loadLinks().then(() => {
        this.refreshLinks(this.links);
      });;

    }

  }

  ngAfterViewInit(): void {
  }

  public checkPrivilege(privilege: keyof PrivilegesService["privileges"]): boolean {
    return PrivilegesService.instance.privileges.admin || PrivilegesService.instance.privileges[privilege];
  }

  public get currentRoute(): string {
    return this.url;
  }

  public async loadLinks(): Promise<void> {
    const linksPresenter = new LinksPresenter();

    const response = await lastValueFrom(linksPresenter.getManyRequested({}).promise)
    this.links = response.main as Workspace['links'];
  }

  private lastKeyword: string = '';
  public inputLinkEdit(event: any): void {
    if (event && event.target && event.target.value) {
      const keyword = event.target.value;
      this.lastKeyword = keyword;
    }
  }

  public async saveEditLink(): Promise<void> {
    if (this.lastKeyword) {
      if (this.currentFormLink?.keywords.length == 0) {
        this.currentFormLink.keywords.push(this.lastKeyword);
      } else {
        this.lastKeyword = "";
      }
    }
    this.isEditingLink = false;
    const presenter = new LinksPresenter();
    try {
      this.linkSearch = '';
      await lastValueFrom(presenter.putOneRequested(this.linksCreateEditService.putValuesToBody(this.currentFormLink ?? {})));
      this.loadLinks().then(() => {
        this.refreshLinks(this.links);
      });;
    } catch (error) {
      console.error(error);
    }
  }

  public async saveNewLink(): Promise<void> {
    this.isAddingLink = false;
    const presenter = new LinksPresenter();
    try {
      this.linkSearch = '';
      await lastValueFrom(presenter.postOneRequested(this.linksCreateEditService.postValuesToBody(this.currentFormLink ?? {})));
      this.loadLinks().then(() => {
        this.refreshLinks(this.links);
      });;
    } catch (error) {
      console.error(error);
    }
  }

  public async deleteLink(link: Workspace["links"][0]): Promise<void> {
    this.linkSearch = '';
    this.linksCreateEditService.delete(link.id);
    this.isAddingLink = false;
  }

  public addNewLink(): void {
    this.isAddingLink = true;
    this.currentFormLink = <any>{
      id: '',
      title: '',
      url: '',
      keywords: [],
    };
  }

  public editLink(link: Workspace["links"][0]): void {
    this.isEditingLink = true;
    this.currentFormLink = link;
  }


  public backToLinkList(): void {
    this.isAddingLink = false;
    this.isEditingLink = false;
  }

  public refreshLinks(items: Workspace["links"]): void {
    // Filter by search
    if (this.linkSearch) {
      const fuse = new Fuse.default(items, {
        keys: ['title', 'url', 'keywords'],
        fieldNormWeight: 0.5,
        findAllMatches: true
      });
      items = fuse.search(this.linkSearch).map((result) => result.item);
    } else
      this.linksFiltered = items;
  }

  public updateLinkSearch(): void {
    this.refreshLinks(this.links);
  }

  public logout(): void {
    this.userPresenter.logoutRequested();
    // Redirect to login
    this.router.navigate(["/auth/login"]);
  }
}
