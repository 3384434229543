//
// ───────────────────────────────────────────────── DEPENDENCIES AND COSTANT ─────
//
import { Injectable } from '@angular/core';
import { TranslateService as NgxTranslateService } from '@ngx-translate/core';
@Injectable({
    providedIn: 'root',
})
export class Translate {
    static instance: Translate;

    constructor(public translate: NgxTranslateService) {
        translate.setDefaultLang("it");
        if (!Translate.instance) {
            Translate.instance = this;
        }
    }

    public get service(): NgxTranslateService {
        return this.translate;
    }

    public get currentLang(): string {
        if (!Translate.instance.service.currentLang) {
            this.service.currentLang = "it";
        }

        return Translate.instance.service.currentLang;
    }

    public translateStringInCurrentLang(text: { [key: string]: any }): string {
        if (text && text[this.currentLang]) {
            return text[this.currentLang];
        } else {
            return "";
        }
    }
}
