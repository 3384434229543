<cb-dialog width="350px" [title]="title | translate">
  <ng-container Content>
    <p>{{message | translate}}</p>
  </ng-container>

  <ng-container RightButtons>
    <cb-button type="primary" (click)="confirm()">
      {{'COMMON.CONFIRM' | translate}}
    </cb-button>
  </ng-container>
</cb-dialog>
