<div class="bg-page-wrapper">
  <div class="bg-page-container">
    <router-outlet>
    </router-outlet>
  </div>
</div>


<cb-toast-message [multiple]="true" [defaultDuration]="5000" [maxToasts]="3"></cb-toast-message>
<cb-global-loader></cb-global-loader>

<div class="cb-mobile-overlay">
  <h4>{{"APP.DEVICE_NOT_ALLOWED" | translate}}</h4>
  <p>{{"APP.USE_BIGGER_SCREEN" | translate}}</p>
</div>

<div class="bg-sidebar-dialog">
  <div class="bg-sidebar-backdrop" *ngIf="showLinksSidebar" @fade (click)="showLinksSidebar=false"></div>
  <div class="bg-sidebar-container" *ngIf="showLinksSidebar" @openClose>
    <button class="bg-sidebar-close-btn" (click)="showLinksSidebar=false">
      <cb-icon name="close"></cb-icon>
    </button>
    <div class="bg-sidebar">
      <div class="bg-sidebar-header">
        <div class="bg-sidebar-header-title">
          <ng-container *ngIf="!isEditingLink && !isAddingLink">
            <cb-icon name="link"></cb-icon>
            <h2>{{"HOME.INTERNAL_LINKS.PAGE_TITLE" | translate}}</h2>
          </ng-container>
          <ng-container *ngIf="isEditingLink">
            <button class="bg-sidebar-back-button" (click)="backToLinkList()">
              <cb-icon name="chevron-back"></cb-icon>
            </button>
            <h2>{{"HOME.INTERNAL_LINKS.EDITING_LINK" | translate}}</h2>
          </ng-container>
          <ng-container *ngIf="isAddingLink">
            <button class="bg-sidebar-back-button" (click)="backToLinkList()">
              <cb-icon name="chevron-back"></cb-icon>
            </button>
            <h2>{{"HOME.INTERNAL_LINKS.ADDING_LINK" | translate}}</h2>
          </ng-container>
        </div>
        <div class="bg-sidebar-utility">
          <ng-container *ngIf="!isEditingLink && !isAddingLink">
            <bg-searchbar [placeholder]="'COMMON.SEARCH' | translate" (input)="updateLinkSearch()"
              [(ngModel)]="linkSearch"></bg-searchbar>
          </ng-container>
        </div>
      </div>

      <div class="bg-sidebar-body">
        <ng-container *ngIf="currentFormLink && (isEditingLink || isAddingLink); else LinkListTemplate">
          <div class="bg-sidebar-form">
            <cb-input [autofocus]="true" [label]="'HOME.INTERNAL_LINKS.TITLE' | translate"
              [placeholder]="'HOME.INTERNAL_LINKS.TITLE' | translate" [(ngModel)]="currentFormLink.title"></cb-input>
            <cb-input [label]="'HOME.INTERNAL_LINKS.URL' | translate" [(ngModel)]="currentFormLink.url"
              [placeholder]="'HOME.INTERNAL_LINKS.URL' | translate"></cb-input>
            <span class="bg-sidebar-form-label">{{"HOME.INTERNAL_LINKS.URL_LABEL" | translate}}</span>
            <cb-chips-input [label]="'HOME.INTERNAL_LINKS.KEYWORDS' | translate" [(ngModel)]="currentFormLink.keywords"
              [placeholder]="'HOME.INTERNAL_LINKS.KEYWORDS_PLACEHOLDER' | translate" [separator]="'\n'"
              (change)="inputLinkEdit($event)"></cb-chips-input>
            <span class="bg-sidebar-form-label">{{"HOME.INTERNAL_LINKS.KEYWORDS_LABEL" | translate}}</span>
            <div class="bg-sidebar-form-buttons">
              <cb-button type="neutral" icon="close-circle" (click)="backToLinkList()">{{"COMMON.CANCEL" |
                translate}}</cb-button>
              <cb-button *ngIf="isEditingLink" type="accent"  [disabled]="disabledFormLinks" icon="checkmark-circle"
                (click)="saveEditLink()">{{"COMMON.SAVE" | translate}}</cb-button>
              <cb-button *ngIf="isAddingLink" type="accent" [disabled]="disabledFormLinks" icon="checkmark-circle"
                (click)="saveNewLink()">{{"COMMON.CREATE" | translate}}</cb-button>
            </div>
          </div>
        </ng-container>
        <ng-template #LinkListTemplate>
          <div class="bg-internal-links-list">
            <ng-container *ngIf="links.length === 0; else LinksTemplate">
              <div class="bg-internal-links-empty">
                <h4>{{"HOME.INTERNAL_LINKS.EMPTY" | translate}}</h4>
              </div>
            </ng-container>
            <div class="bg-internal-link-add" (click)="addNewLink()">
              <cb-icon name="add-circle"></cb-icon>
              <h4>{{"HOME.NEW_INTERNAL_LINK" | translate}}</h4>
            </div>
            <p>
              <span>{{"HOME.INTERNAL_LINKS.DESCRIPTION" | translate}}</span>
            </p>
            <ng-template #LinksTemplate>
              <ng-container *ngFor="let link of linksFiltered">
                <div class="bg-internal-link-card">
                  <div class="bg-internal-link-header">
                    <div class="bg-internal-link-title">
                      <h4>{{link.title}}</h4>
                      <span><a href="{{link.url}}" target="_blank">{{link.url}}</a></span>
                    </div>
                    <div class="bg-internal-link-buttons">
                      <cb-icon-button type="accent" icon="edit" (click)="editLink(link)"></cb-icon-button>
                      <cb-icon-button type="danger" icon="trash" (click)="deleteLink(link)"></cb-icon-button>
                    </div>
                  </div>
                  <div class="bg-internal-link-tags">
                    <ng-container *ngFor="let tag of link.keywords">
                      <div class="bg-internal-link-tag">{{tag}}</div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </ng-template>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>