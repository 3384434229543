import { Injectable } from '@angular/core';
import {
  WorkspacePromptPostBody,
  WorkspacePromptPutBody,
} from 'generatedInterfaces';
import { PromptsPresenter } from 'src/app/classes/presenters/promptsPresenter';
import {
  CbCreateEditService,
  CbResolveReject,
} from 'src/app/widgets/cb-dialog-form/abstract/cb-create-edit.service';
import { CbDialogFormService } from 'src/app/widgets/cb-dialog-form/service/cb-dialog-form.service';
import { CbDialogService } from 'src/app/widgets/cb-dialog/cb-dialog-service/cb-dialog.service';
import { CbButtonType } from 'src/app/widgets/cb-form-widgets/cb-button/cb-button.component';
import { CbStandardForm } from 'src/app/widgets/cb-form/cb-form-interfaces';
import { CbToastService } from 'src/app/widgets/cb-toast/cb-toast.service';
import { createRows, editRows } from './create-edit-prompt.const';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import {
  WorkspacePromptPostsBody,
  WorkspacePromptPutsBody,
  WorkspacePromptDeletesQuery,
} from 'generatedInterfaces';

@Injectable({
  providedIn: 'root',
})
export class CreateEditPromptService extends CbCreateEditService {
  public deleteTitle: string = 'PROMPTS.DELETE.TITLE';
  public deleteMessage: string = 'PROMPTS.DELETE.MESSAGE';
  public presenter: PromptsPresenter = new PromptsPresenter();

  constructor(
    public override readonly dialogService: CbDialogService,
    public override readonly dialogFormService: CbDialogFormService
  ) {
    super(dialogService, dialogFormService);
  }

  public beforeCreateOpen(data: any): void { }
  public beforeEditOpen(data: any): void { }

  public initCreateForm(): CbStandardForm {
    return {
      title: 'PROMPTS.CREATE',
      rows: createRows,
      submitButton: {
        label: 'COMMON.CREATE',
        type: CbButtonType.success,
        show: true,
        onClick: (values: { [key: string]: any }) => {
          super.create(values);
        },
      },
    };
  }

  public initEditForm(): CbStandardForm {
    return {
      title: 'PROMPTS.EDIT',
      rows: editRows,
      submitButton: {
        label: 'COMMON.SAVE',
        type: CbButtonType.success,
        show: true,
        onClick: (values: { [key: string]: any }) => {
          super.edit(values);
        },
      },
      deleteButton: {
        label: 'COMMON.DELETE',
        type: CbButtonType.danger,
        show: true,
        onClick: (values: { [key: string]: any }) => {
          super.delete(values['id']);
        },
      },
    };
  }

  static postValuesToBody(values: {
    [key: string]: any;
  }): WorkspacePromptPostBody {
    // Generate uuid v4
    const id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (
      c
    ) {
      const r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
    return {
      id: id,
      title: values['title'],
      content: values['content'],
      properties: values['properties'],
      temperature: values['temperature'],
      maxTokens: values['maxTokens'],
      language: values['language'],
      type: values['type'],
      order: values['order'],
      createdAt: values['createdAt'],
      updatedAt: values['updatedAt'],
      deletedAt: values['deletedAt'],
    };
  }

  static putValuesToBody(values: {
    [key: string]: any;
  }): WorkspacePromptPutBody {
    return {
      id: values['id'],
      title: values['title'],
      content: values['content'],
      properties: values['properties'],
      temperature: values['temperature'],
      maxTokens: values['maxTokens'],
      language: values['language'],
      type: values['type'],
      order: values['order'],
      createdAt: values['createdAt'],
      updatedAt: values['updatedAt'],
      deletedAt: values['deletedAt'],
    };
  }

  public createCallback(
    values: { [key: string]: any },
    { resolve, reject }: CbResolveReject
  ): void {
    let postBody: WorkspacePromptPostBody = CreateEditPromptService.postValuesToBody(values);
    this.presenter.postOneRequested(postBody).subscribe({
      next: (value) => {
        resolve(value);
      },
      error: (error) => {
        CbToastService.instance.showMessage({
          title: 'COMMON.ERROR',
          message: error.error.message,
          icon: 'alert-circle-sharp',
          type: 'danger',
        });
        reject(error);
      },
      complete: () => { },
    });
  }

  public editCallback(
    values: { [key: string]: any },
    { resolve, reject }: CbResolveReject
  ): void {
    let putBody: WorkspacePromptPutBody = CreateEditPromptService.putValuesToBody(values);
    this.presenter.putOneRequested(putBody).subscribe({
      next: (value) => {
        resolve(value);
      },
      error: (error) => {
        CbToastService.instance.showMessage({
          title: 'COMMON.ERROR',
          message: error.error.message,
          icon: 'alert-circle-sharp',
          type: 'danger',
        });
        reject(error);
      },
      complete: () => { },
    });
  }

  public deleteCallback(
    id: string,
    { resolve, reject }: CbResolveReject
  ): void {
    this.presenter.deleteOneRequested({ id: id }).subscribe({
      next: (value) => {
        resolve(value);
      },
      error: (error) => {
        CbToastService.instance.showMessage({
          title: 'COMMON.ERROR',
          message: error.error.message,
          icon: 'alert-circle-sharp',
          type: 'danger',
        });
        reject(error);
      },
      complete: () => { },
    });
  }

  public async createManyPrompts(
    elements: { [key: string]: any }[]
  ): Promise<void> {
    let bodies: WorkspacePromptPostsBody = { bodies: [] };
    for (let values of elements) {
      let putBody: WorkspacePromptPostsBody['bodies'][0] = {
        id: values['id'],
      };
      bodies.bodies.push(putBody);
    }

    try {
      await lastValueFrom(this.presenter.postsManyRequested(bodies));
      CbToastService.instance.showMessage({
        title: 'COMMON.SUCCESS',
        message: 'TRANSLATIONS.CREATE_MANY_SUCCESS',
        icon: 'checkmark-circle-outline',
        type: 'success',
      });
    } catch (error: any) {
      CbToastService.instance.showMessage({
        title: 'COMMON.ERROR',
        message: error && error.error ? error.error.message : 'Error',
        icon: 'alert-circle-sharp',
        type: 'danger',
      });
    }
  }

  public async editManyPrompts(
    elements: { [key: string]: any }[]
  ): Promise<void> {
    let bodies: WorkspacePromptPutsBody = { bodies: [] };
    for (let values of elements) {
      let putBody: WorkspacePromptPutsBody['bodies'][0] = {
        id: values['id'],
      };
      bodies.bodies.push(putBody);
    }

    try {
      await lastValueFrom(this.presenter.putsManyRequested(bodies));
      CbToastService.instance.showMessage({
        title: 'COMMON.SUCCESS',
        message: 'TRANSLATIONS.EDIT_MANY_SUCCESS',
        icon: 'checkmark-circle-outline',
        type: 'success',
      });
    } catch (error: any) {
      CbToastService.instance.showMessage({
        title: 'COMMON.ERROR',
        message: error && error.error ? error.error.message : 'Error',
        icon: 'alert-circle-sharp',
        type: 'danger',
      });
    }
  }

  public async deleteManyPrompts(ids: string[]): Promise<void> {
    let bodies: WorkspacePromptDeletesQuery = { ids: [] };
    for (let id of ids) {
      bodies.ids.push(id);
    }

    try {
      await lastValueFrom(this.presenter.deletesManyRequested(bodies));
      CbToastService.instance.showMessage({
        title: 'COMMON.SUCCESS',
        message: 'TRANSLATIONS.DELETE_MANY_SUCCESS',
        icon: 'checkmark-circle-outline',
        type: 'success',
      });
    } catch (error: any) {
      CbToastService.instance.showMessage({
        title: 'COMMON.ERROR',
        message: error && error.error ? error.error.message : 'Error',
        icon: 'alert-circle-sharp',
        type: 'danger',
      });
    }
  }
}
