/// IMPORTS
import {
  WorkspaceUserGetQuery,
  WorkspaceUserGetResponse,
  WorkspaceUserPostBody,
  WorkspaceUserPostResponse,
  WorkspaceUserPutBody,
  WorkspaceUserPutResponse,
  WorkspaceUserDeleteQuery,
  WorkspaceUserDeleteResponse,
  WorkspaceUsersGetQuery,
  WorkspaceUsersGetResponse,
  WorkspaceUserOrderPutBody,
  WorkspaceUserOrderPutResponse,
} from 'generatedInterfaces';
import { UsersOfflineEndpoint } from 'src/app/classes/offlineEndpoints/usersOfflineEndpoint';
import { UsersOnlineEndpoint } from 'src/app/classes/onlineEndpoints/usersOnlineEndpoint';
import { HttpErrorResponse } from '@angular/common/http';
import { Workspace } from '../workspace';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

export class UsersModel {
  /// PROPERTIES
  private onlineEndpoint: UsersOnlineEndpoint = new UsersOnlineEndpoint();
  private offlineEndpoint: UsersOfflineEndpoint = new UsersOfflineEndpoint();

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  // workspaceUserOrderPut
  public changeOrder(
    obj: WorkspaceUserOrderPutBody
  ): Observable<WorkspaceUserOrderPutResponse> {
    return this.onlineEndpoint.changeOrder(obj).pipe(
      map(
        (response: WorkspaceUserOrderPutResponse) => {
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceUsersGet
  public getMany(obj: WorkspaceUsersGetQuery): {
    local: Workspace['users'];
    promise: Observable<WorkspaceUsersGetResponse>;
  } {
    return {
      local: this.offlineEndpoint.getMany(obj).filter((item) => {
        for (let key of Object.keys(obj)) {
          if ((<any>item)[key] != (<any>obj)[key]) {
            return false;
          }
        }
        return true;
      }),
      promise: this.onlineEndpoint.getMany(obj).pipe(
        map(
          (response: WorkspaceUsersGetResponse) => {
            let mains = response.main;
            if (mains)
              this.offlineEndpoint.setEntities(mains as Workspace['users']);

            return response;
          },
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
        )
      ),
    };
  }

  // workspaceUserDelete
  public deleteOne(
    obj: WorkspaceUserDeleteQuery
  ): Observable<WorkspaceUserDeleteResponse> {
    return this.onlineEndpoint.deleteOne(obj).pipe(
      map(
        (response: WorkspaceUserDeleteResponse) => {
          this.offlineEndpoint.delete(obj as Workspace['users'][0]);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceUserPut
  public putOne(
    obj: WorkspaceUserPutBody
  ): Observable<WorkspaceUserPutResponse> {
    return this.onlineEndpoint.putOne(obj).pipe(
      map(
        (response: WorkspaceUserPutResponse) => {
          this.offlineEndpoint.pushOrUpdate(obj as Workspace['users'][0]);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceUserPost
  public postOne(
    obj: WorkspaceUserPostBody
  ): Observable<WorkspaceUserPostResponse> {
    return this.onlineEndpoint.postOne(obj).pipe(
      map(
        (response: WorkspaceUserPostResponse) => {
          this.offlineEndpoint.pushOrUpdate(obj as Workspace['users'][0]);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceUserGet
  public getOne(obj: WorkspaceUserGetQuery): {
    local: Workspace['users'][0];
    promise: Observable<WorkspaceUserGetResponse>;
  } {
    return {
      local: this.offlineEndpoint.getOne(obj),
      promise: this.onlineEndpoint.getOne(obj).pipe(
        map(
          (response: WorkspaceUserGetResponse) => {
            this.offlineEndpoint.pushOrUpdate(
              response.main as Workspace['users'][0]
            );

            return response;
          },
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
        )
      ),
    };
  }

  /// CUSTOM METHODS

  ///
}
