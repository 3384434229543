/// IMPORTS
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { getUrl, authApi, deleteAuthApi, objectToQueryString } from '../utils';
import { HttpService } from 'src/app/services/http.service';

export class UserOnlineEndpoint {
  /// PROPERTIES
  private http: HttpClient = HttpService.instance.http;

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  // Login the user
  public login(obj: {
    username: string;
    password: string;
    email: string;
  }): Observable<{ accessToken: string; refreshToken: string }> {
    return this.http.post<{ accessToken: string; refreshToken: string }>(
      getUrl('/user/login'),
      obj,
      authApi(false)
    );
  }

  // Register the user
  public register(obj: {
    username: string;
    password: string;
    email: string;
  }): Observable<{ accessToken: string; refreshToken: string }> {
    return this.http.post<{ accessToken: string; refreshToken: string }>(
      getUrl('/user/register'),
      obj,
      authApi(false)
    );
  }

  // Change password the user
  public changePassword(obj: {
    passwordConfirm: string;
    password: string;
  }): Observable<{ message: string }> {
    return this.http.post<{ message: string }>(
      getUrl('/user/changepassword'),
      obj,
      authApi(true)
    );
  }

  // Change additionalInformation the user
  public additionalInformation(obj: {
    additionalInformation: any;
  }): Observable<{ message: string }> {
    return this.http.put<{ message: string }>(
      getUrl('/user/additionalinformation'),
      obj,
      authApi(true)
    );
  }

  // Change username the user
  public changeUsername(obj: {
    newUsername: string;
  }): Observable<{ message: string }> {
    return this.http.put<{ message: string }>(
      getUrl('/user/changeusername'),
      obj,
      authApi(true)
    );
  }

  // Change lang the user
  public changeLang(obj: { lang: string }): Observable<{ message: string }> {
    return this.http.put<{ message: string }>(
      getUrl('/user/changelang'),
      obj,
      authApi(true)
    );
  }

  // Change newsletter the user
  public changeNewsletter(obj: {
    newsletter: boolean;
  }): Observable<{ accessToken: string; refreshToken: string }> {
    return this.http.put<{ accessToken: string; refreshToken: string }>(
      getUrl('/user/changenewsletter'),
      obj,
      authApi(true)
    );
  }

  // Forgot password the user
  public forgotPassword(obj: {
    username: string;
    email: string;
  }): Observable<{ message: string }> {
    return this.http.post<{ message: string }>(
      getUrl('/user/forgotpassword'),
      obj,
      authApi(false)
    );
  }

  // Recover password the user
  public recoverPassword(obj: {
    password: string;
    code: string;
  }): Observable<{ message: string }> {
    return this.http.post<{ message: string }>(
      getUrl('/user/recoverpassword'),
      obj,
      authApi(false)
    );
  }

  // Tokens for the user
  public token(obj: {
    refreshToken: string;
  }): Observable<{ accessToken: string; refreshToken: string }> {
    return this.http.post<{ accessToken: string; refreshToken: string }>(
      getUrl('/user/tokens'),
      obj,
      authApi(true)
    );
  }

  // Confirm pin code
  public confirmPincode(obj: {
    code: string;
  }): Observable<{ accessToken: string; refreshToken: string }> {
    return this.http.post<{ accessToken: string; refreshToken: string }>(
      getUrl('/user/confirmpincode'),
      obj,
      authApi(true)
    );
  }

  // Bind workspace
  public bindWorkspace(obj: {
    workspaceId: string;
    invitationCode: string;
  }): Observable<{ accessToken: string; refreshToken: string }> {
    return this.http.post<{ accessToken: string; refreshToken: string }>(
      getUrl('/user/bind'),
      obj,
      authApi(true)
    );
  }

  // Send pin code
  public sendPincode(obj: { email: string }): Observable<{ message: string }> {
    return this.http.post<{ message: string }>(
      getUrl('/user/sendpincode'),
      obj,
      authApi(true)
    );
  }

  // Complete onboarding
  public completeOnboarding(obj: {
    bool: boolean;
  }): Observable<{ accessToken: string; refreshToken: string }> {
    return this.http.put<{ accessToken: string; refreshToken: string }>(
      getUrl('/user/onboarding'),
      obj,
      authApi(true)
    );
  }

  /// CUSTOM METHODS

  ///
}
