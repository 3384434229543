import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CurrencyPipe } from './currency';
import { DateTimePipe } from './datetime';
import { FileSizePipe } from './file-size';
import { InitialsPipe } from './intials-pipe';
import { SecondsTimePipe } from './secondsTime';
import { SmartDateTimePipe } from './smart-date';



@NgModule({
  declarations: [
    CurrencyPipe,
    DateTimePipe,
    FileSizePipe,
    InitialsPipe,
    SecondsTimePipe,
    SmartDateTimePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CurrencyPipe,
    DateTimePipe,
    FileSizePipe,
    InitialsPipe,
    SecondsTimePipe,
    SmartDateTimePipe
  ],
  providers: [
  ]

})
export class CommonPipesModule { }
