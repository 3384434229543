import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BasicPresenter } from '../classes/presenters/basicPresenter';
import { UserPresenter } from '../classes/presenters/userPresenter';
import { CbGlobalLoaderService } from '../widgets/cb-global-loader/cb-global-loader.service';
import { CbToastService } from '../widgets/cb-toast/cb-toast.service';
import { HttpService } from './http.service';
import { UploaderService } from './uploader.service';

@Injectable()
export class PrivilegesService {
  public userPresenter: UserPresenter;
  public basicPresenter: BasicPresenter;

  public static instance: PrivilegesService;
  public privileges = {
    admin: true,
    none: true
  }

  constructor(private readonly http: HttpClient,
  ) {
    /// CONTENT
    new HttpService(http);
    new CbToastService();
    new UploaderService(http, new CbGlobalLoaderService());
    this.userPresenter = new UserPresenter();
    this.basicPresenter = new BasicPresenter();
    PrivilegesService.instance = this;
  }

  load() {
    return new Promise((resolve, reject) => {

      // Check if accessToken is expired
      if (this.basicPresenter.getSelectedRequested()) {
        return resolve(true);
      } else {
        return resolve(true);
      }

    });
  }
}