import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CbGlobalLoaderComponent } from './cb-global-loader/cb-global-loader.component';
import { TranslateModule } from '@ngx-translate/core';
import { CbUtilityWidgetsModule } from '../cb-utility-widgets/cb-utility-widgets.module';



@NgModule({
  declarations: [
    CbGlobalLoaderComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    CbUtilityWidgetsModule
  ],
  exports: [
    CbGlobalLoaderComponent
  ]
})
export class CbGlobalLoaderModule { }
