/// IMPORTS
import {
  WorkspaceUserGetQuery,
  WorkspaceUserGetResponse,
  WorkspaceUserPostBody,
  WorkspaceUserPostResponse,
  WorkspaceUserPutBody,
  WorkspaceUserPutResponse,
  WorkspaceUserDeleteQuery,
  WorkspaceUserDeleteResponse,
  WorkspaceUsersGetQuery,
  WorkspaceUsersGetResponse,
  WorkspaceUserOrderPutBody,
  WorkspaceUserOrderPutResponse,
} from 'generatedInterfaces';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Workspace } from '../workspace';

export class UsersOfflineEndpoint {
  /// PROPERTIES

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  // Delete a local entity
  public delete(obj: Workspace['users'][0]) {
    const users = this.getMany();
    users.splice(
      users.findIndex((entity) => {
        if (entity.id === obj.id) {
          return true;
        }
        return false;
      }),
      1
    );
    this.setEntities(users);
  }

  // Push or update an entity
  public pushOrUpdate(obj: Workspace['users'][0]): void {
    const users = this.getMany();
    const index = users.findIndex((entity) => entity.id === obj.id);
    if (index === -1) {
      users.push(obj);
    } else {
      users[index] = obj;
    }
    this.setEntities(users);
  }

  // Get all the local entities
  public getMany(obj?: any): Workspace['users'] {
    if (localStorage.getItem('users') === null) {
      return [];
    }
    let entities = JSON.parse(
      localStorage.getItem('users') as any
    ) as Workspace['users'];

    if (obj) {
      return entities.filter((entity) => {
        let hasProperties = false;
        for (const key in obj) {
          hasProperties = true;
          if (obj.hasOwnProperty(key) && entity.hasOwnProperty(key)) {
            if ((<any>entity)[key] === (<any>obj)[key]) {
              return true;
            }
          }
        }
        if (!hasProperties) return true;
        return false;
      });
    }

    return entities;
  }

  // Get one of the local entities
  public getOne(obj: any): Workspace['users'][0] {
    let users = this.getMany();
    // Filter for all the value of obj
    users = users.filter((entity) => {
      let hasProperties = false;
      for (const key in obj) {
        hasProperties = true;
        if (obj.hasOwnProperty(key) && entity.hasOwnProperty(key)) {
          if ((<any>entity)[key] === (<any>obj)[key]) {
            return true;
          }
        }
      }
      if (!hasProperties) return true;
      return false;
    });

    return users[0];
  }

  // Set the entire entities array
  public setEntities(obj: Workspace['users']): void {
    localStorage.setItem('users', JSON.stringify(obj));
  }

  /// CUSTOM METHODS

  ///
}
