/// IMPORTS
import {
  WorkspaceLinkGetQuery,
  WorkspaceLinkGetResponse,
  WorkspaceLinkPostBody,
  WorkspaceLinkPostResponse,
  WorkspaceLinkPutBody,
  WorkspaceLinkPutResponse,
  WorkspaceLinkDeleteQuery,
  WorkspaceLinkDeleteResponse,
  WorkspaceLinksGetQuery,
  WorkspaceLinksGetResponse,
  WorkspaceLinkPostsBody,
  WorkspaceLinkPostsResponse,
  WorkspaceLinkPutsBody,
  WorkspaceLinkPutsResponse,
  WorkspaceLinkDeletesQuery,
  WorkspaceLinkDeletesResponse,
  WorkspaceLinkOrderPutBody,
  WorkspaceLinkOrderPutResponse,
} from 'generatedInterfaces';
import { LinksModel } from 'src/app/classes/models/linksModel';
import { Workspace } from '../workspace';
import { Observable } from 'rxjs/internal/Observable';

export class LinksPresenter {
  /// PROPERTIES
  private model: LinksModel = new LinksModel();

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  // workspaceLinkOrderPut
  public changeOrderRequested(
    obj: WorkspaceLinkOrderPutBody
  ): Observable<WorkspaceLinkOrderPutResponse> {
    return this.model.changeOrder(obj);
  }

  // workspaceLinkDeletes
  public deletesManyRequested(
    obj: WorkspaceLinkDeletesQuery
  ): Observable<WorkspaceLinkDeletesResponse> {
    return this.model.deletesMany(obj);
  }

  // workspaceLinkPuts
  public putsManyRequested(
    obj: WorkspaceLinkPutsBody
  ): Observable<WorkspaceLinkPutsResponse> {
    return this.model.putsMany(obj);
  }

  // workspaceLinkPosts
  public postsManyRequested(
    obj: WorkspaceLinkPostsBody
  ): Observable<WorkspaceLinkPostsResponse> {
    return this.model.postsMany(obj);
  }

  // workspaceLinksGet
  public getManyRequested(obj: WorkspaceLinksGetQuery): {
    local: Workspace['links'];
    promise: Observable<WorkspaceLinksGetResponse>;
  } {
    return this.model.getMany(obj);
  }

  // workspaceLinkDelete
  public deleteOneRequested(
    obj: WorkspaceLinkDeleteQuery
  ): Observable<WorkspaceLinkDeleteResponse> {
    return this.model.deleteOne(obj);
  }

  // workspaceLinkPut
  public putOneRequested(
    obj: WorkspaceLinkPutBody
  ): Observable<WorkspaceLinkPutResponse> {
    return this.model.putOne(obj);
  }

  // workspaceLinkPost
  public postOneRequested(
    obj: WorkspaceLinkPostBody
  ): Observable<WorkspaceLinkPostResponse> {
    return this.model.postOne(obj);
  }

  // workspaceLinkGet
  public getOneRequested(obj: WorkspaceLinkGetQuery): {
    local: Workspace['links'][0];
    promise: Observable<WorkspaceLinkGetResponse>;
  } {
    return this.model.getOne(obj);
  }

  /// CUSTOM METHODS

  ///
}
