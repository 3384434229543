/// IMPORTS
import {
  WorkspaceUserGetQuery,
  WorkspaceUserGetResponse,
  WorkspaceUserPostBody,
  WorkspaceUserPostResponse,
  WorkspaceUserPutBody,
  WorkspaceUserPutResponse,
  WorkspaceUserDeleteQuery,
  WorkspaceUserDeleteResponse,
  WorkspaceUsersGetQuery,
  WorkspaceUsersGetResponse,
  WorkspaceUserOrderPutBody,
  WorkspaceUserOrderPutResponse,
} from 'generatedInterfaces';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { getUrl, authApi, deleteAuthApi, objectToQueryString } from '../utils';
import { HttpService } from 'src/app/services/http.service';

export class UsersOnlineEndpoint {
  /// PROPERTIES
  private http: HttpClient = HttpService.instance.http;

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  // workspaceUserOrderPut
  public changeOrder(
    obj: WorkspaceUserOrderPutBody
  ): Observable<WorkspaceUserOrderPutResponse> {
    return this.http.put<WorkspaceUserOrderPutResponse>(
      getUrl('/workspace/users/order'),
      obj,
      authApi(true)
    );
  }

  // workspaceUsersGet
  public getMany(
    obj: WorkspaceUsersGetQuery
  ): Observable<WorkspaceUsersGetResponse> {
    return this.http.get<WorkspaceUsersGetResponse>(
      getUrl('/workspace/users') + objectToQueryString(obj),
      authApi(true)
    );
  }

  // workspaceUserDelete
  public deleteOne(
    obj: WorkspaceUserDeleteQuery
  ): Observable<WorkspaceUserDeleteResponse> {
    return this.http.delete<WorkspaceUserDeleteResponse>(
      getUrl('/workspace/user') + objectToQueryString(obj),
      deleteAuthApi(true, obj)
    );
  }

  // workspaceUserPut
  public putOne(
    obj: WorkspaceUserPutBody
  ): Observable<WorkspaceUserPutResponse> {
    return this.http.put<WorkspaceUserPutResponse>(
      getUrl('/workspace/user'),
      obj,
      authApi(true)
    );
  }

  // workspaceUserPost
  public postOne(
    obj: WorkspaceUserPostBody
  ): Observable<WorkspaceUserPostResponse> {
    return this.http.post<WorkspaceUserPostResponse>(
      getUrl('/workspace/user'),
      obj,
      authApi(true)
    );
  }

  // workspaceUserGet
  public getOne(
    obj: WorkspaceUserGetQuery
  ): Observable<WorkspaceUserGetResponse> {
    return this.http.get<WorkspaceUserGetResponse>(
      getUrl('/workspace/user') + objectToQueryString(obj),
      authApi(true)
    );
  }

  /// CUSTOM METHODS

  ///
}
