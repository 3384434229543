/// IMPORTS
import {
  WorkspaceCompletitionPostBody,
  WorkspaceCompletitionPostResponse,
} from 'generatedInterfaces';
import { CompletitionModel } from 'src/app/classes/models/completitionModel';
import { Workspace } from '../workspace';
import { Observable } from 'rxjs/internal/Observable';

export class CompletitionPresenter {
  /// PROPERTIES
  private model: CompletitionModel = new CompletitionModel();

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  // workspaceInputCompletitionPost
  public postOneRequested(
    obj: WorkspaceCompletitionPostBody
  ): Observable<WorkspaceCompletitionPostResponse> {
    return this.model.postOne(obj);
  }

  /// CUSTOM METHODS

  ///
}
