import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LinkSidebarService {
  public showSidebarSubject: Subject<boolean> = new Subject<boolean>();

  constructor() { }

  public toggleLinksSidebar(status: boolean): void {
    this.showSidebarSubject.next(status);
  }
}
