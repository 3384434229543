/// IMPORTS
import {
  WorkspaceEditPostBody,
  WorkspaceEditPostResponse,
} from 'generatedInterfaces';
import { EditModel } from 'src/app/classes/models/editModel';
import { Workspace } from '../workspace';
import { Observable } from 'rxjs/internal/Observable';

export class EditPresenter {
  /// PROPERTIES
  private model: EditModel = new EditModel();

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  // workspaceInputEditPost
  public postOneRequested(
    obj: WorkspaceEditPostBody
  ): Observable<WorkspaceEditPostResponse> {
    return this.model.postOne(obj);
  }

  /// CUSTOM METHODS

  ///
}
