/// IMPORTS
import {
  WorkspacePromptGetQuery,
  WorkspacePromptGetResponse,
  WorkspacePromptPostBody,
  WorkspacePromptPostResponse,
  WorkspacePromptPutBody,
  WorkspacePromptPutResponse,
  WorkspacePromptDeleteQuery,
  WorkspacePromptDeleteResponse,
  WorkspacePromptsGetQuery,
  WorkspacePromptsGetResponse,
  WorkspacePromptPostsBody,
  WorkspacePromptPostsResponse,
  WorkspacePromptPutsBody,
  WorkspacePromptPutsResponse,
  WorkspacePromptDeletesQuery,
  WorkspacePromptDeletesResponse,
  WorkspacePromptOrderPutBody,
  WorkspacePromptOrderPutResponse,
} from 'generatedInterfaces';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Workspace } from '../workspace';

export class PromptsOfflineEndpoint {
  /// PROPERTIES

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  // Delete a local entity
  public delete(obj: Workspace['prompts'][0]) {
    const prompts = this.getMany();
    prompts.splice(
      prompts.findIndex((entity) => {
        if (entity.id === obj.id) {
          return true;
        }
        return false;
      }),
      1
    );
    this.setEntities(prompts);
  }

  // Push or update an entity
  public pushOrUpdate(obj: Workspace['prompts'][0]): void {
    const prompts = this.getMany();
    const index = prompts.findIndex((entity) => entity.id === obj.id);
    if (index === -1) {
      prompts.push(obj);
    } else {
      prompts[index] = obj;
    }
    this.setEntities(prompts);
  }

  // Get all the local entities
  public getMany(obj?: any): Workspace['prompts'] {
    if (localStorage.getItem('prompts') === null) {
      return [];
    }
    let entities = JSON.parse(
      localStorage.getItem('prompts') as any
    ) as Workspace['prompts'];

    if (obj) {
      return entities.filter((entity) => {
        let hasProperties = false;
        for (const key in obj) {
          hasProperties = true;
          if (obj.hasOwnProperty(key) && entity.hasOwnProperty(key)) {
            if ((<any>entity)[key] === (<any>obj)[key]) {
              return true;
            }
          }
        }
        if (!hasProperties) return true;
        return false;
      });
    }

    return entities;
  }

  // Get one of the local entities
  public getOne(obj: any): Workspace['prompts'][0] {
    let prompts = this.getMany();
    // Filter for all the value of obj
    prompts = prompts.filter((entity) => {
      let hasProperties = false;
      for (const key in obj) {
        hasProperties = true;
        if (obj.hasOwnProperty(key) && entity.hasOwnProperty(key)) {
          if ((<any>entity)[key] === (<any>obj)[key]) {
            return true;
          }
        }
      }
      if (!hasProperties) return true;
      return false;
    });

    return prompts[0];
  }

  // Set the entire entities array
  public setEntities(obj: Workspace['prompts']): void {
    localStorage.setItem('prompts', JSON.stringify(obj));
  }

  /// CUSTOM METHODS

  ///
}
