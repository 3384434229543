import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = () => { };

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CbTimeInputComponent),
  multi: true,
};

@Component({
  selector: 'cb-time-input',
  templateUrl: './cb-time-input.component.html',
  styleUrls: ['./cb-time-input.component.scss', '../cb-form-style.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class CbTimeInputComponent implements ControlValueAccessor {
  @Input() public placeholder?: string = '';
  @Input() public label?: string = '';
  @Input() public autofocus?: boolean;
  @Input() public disabled = false;
  @Input() public error = false;

  public showPassword = false;

  @Output('change') public onChange: EventEmitter<Date> =
    new EventEmitter<Date>();

  @ViewChild('input') private inputRef?: ElementRef;

  //The internal data model
  private innerValue?: Date;
  private innerStringValue = "";

  constructor(private readonly cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (this.autofocus) this.focusInput();
  }

  ngAfterViewInit(): void {
    this.setInputListener();
  }

  private setInputListener(): void { }

  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  // get accessor
  public get value(): Date | undefined {
    return this.innerValue;
  }

  // Set accessor including call the onchange callback
  public set value(v: Date | undefined) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.innerStringValue = v?.toString().padStart(2, '0') + ' : ' + v?.getMinutes().toString().padStart(2, '0') || "";
      this.onChangeCallback(this.value);
    }
  }

  // get accessor
  public get stringValue(): string {
    return this.innerStringValue;
  }

  // Set accessor including call the onchange callback
  public set stringValue(v: string) {
    if (v !== this.innerStringValue) {
      const split: string[] = v.split(':');
      if (split?.length >= 2) {
        const hours = parseInt(split[0]);
        const minutes = parseInt(split[1]);
        if (hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59) {
          const date = new Date();
          date.setHours(hours);
          date.setMinutes(minutes);
          this.innerStringValue = v;
          this.innerValue = date;
          this.onChangeCallback(this.value);
        }
      }
    }
  }

  public togglePassword(): void {
    this.showPassword = !this.showPassword;
    this.cdr.detectChanges();
  }

  // From ControlValueAccessor interface
  public writeValue(value: Date | string | undefined) {
    if (value !== this.innerValue) {
      if (value instanceof Date) {
        this.value = value;
      } else if (typeof (value) == "string") {
        this.stringValue = value;
      }
      this.onChangeCallback(this.value);
      this.cdr.detectChanges();
    }
  }

  public change(): void {
    this.onChange.emit(this.value);
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public focusInput(): void {
    setTimeout(() => {
      this.inputRef?.nativeElement.focus();
    }, 0);
  }
}
