import { Subject } from 'rxjs';
import { CbDialogService } from '../../cb-dialog/cb-dialog-service/cb-dialog.service';
import * as uuid from 'uuid';
import { CbDialogFormService } from '../service/cb-dialog-form.service';
import { CbStandardForm } from '../../cb-form/cb-form-interfaces';

export interface CbResolveReject {
  resolve: (value?: unknown) => void;
  reject: (reason?: any) => void;
}

export enum CbDialogEventType {
  success = "success",
  error = "error"
}

export interface CbDialogEvent {
  type: CbDialogEventType,
  data?: any
}

export abstract class CbCreateEditService {
  public createFormTemplate!: CbStandardForm | null;
  public editFormTemplate!: CbStandardForm | null;

  public onCreateEvent: Subject<CbDialogEvent> = new Subject<CbDialogEvent>();
  public onEditEvent: Subject<CbDialogEvent> = new Subject<CbDialogEvent>();
  public onDeleteEvent: Subject<CbDialogEvent> = new Subject<CbDialogEvent>();

  public data: any;

  public abstract deleteTitle: string;
  public abstract deleteMessage: string;

  constructor(
    public readonly dialogService: CbDialogService,
    public readonly dialogFormService: CbDialogFormService
  ) {
    this.createFormTemplate = this.initCreateForm();
    this.editFormTemplate = this.initEditForm();
  }

  public abstract initCreateForm(): CbStandardForm | null;

  public abstract initEditForm(): CbStandardForm | null;

  public abstract beforeCreateOpen(data: any): void;

  public abstract beforeEditOpen(data: any): void;

  protected abstract createCallback(values: { [key: string]: any }, { resolve, reject }: CbResolveReject): void;

  protected abstract editCallback(values: { [key: string]: any }, { resolve, reject }: CbResolveReject): void;

  protected abstract deleteCallback(id: string, { resolve, reject }: CbResolveReject, data?: any): void;

  protected beforeCreateCallback(result: { [key: string]: any }): { [key: string]: any } {
    return result;
  }

  protected beforeEditCallback(result: { [key: string]: any }): { [key: string]: any } {
    return result;
  }

  public getNewId(): string {
    return uuid.v4();
  }

  protected cloneObject(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  public create(values: { [key: string]: any }): void {
    new Promise((resolve, reject) => {
      const newValues: { [key: string]: any } = this.beforeCreateCallback(values);
      this.createCallback(newValues, { resolve, reject });
    }).then((result) => {
      this.onCreateEvent.next({
        type: CbDialogEventType.success,
        data: result,
      });
    }).catch((error) => {
      this.onCreateEvent.next({
        type: CbDialogEventType.error,
        data: error,
      });
    });
  }

  public edit(values: { [key: string]: any }): void {
    new Promise((resolve, reject) => {
      const newValues: { [key: string]: any } = this.beforeEditCallback(values);
      this.editCallback(newValues, { resolve, reject });
    }).then((result) => {
      this.onEditEvent.next({
        type: CbDialogEventType.success,
        data: result,
      });
    }).catch((error) => {
      this.onEditEvent.next({
        type: CbDialogEventType.error,
        data: error,
      });
    });
  }

  public delete(id: string, data?: any): void {
    this.dialogService.openConfirmDialog(this.deleteTitle, this.deleteMessage, {
      afterClose: (canDelete: boolean) => {
        if (canDelete)
          new Promise((resolve, reject) => {
            this.deleteCallback(id, { resolve, reject }, data);
          }).then((result) => {
            this.onDeleteEvent.next({
              type: CbDialogEventType.success,
              data: result,
            });
          }).catch((error) => {
            this.onDeleteEvent.next({
              type: CbDialogEventType.error,
              data: error,
            });
          });
      },
    });
  }

  public openSubForm(form: CbStandardForm): void {
    this.dialogFormService.openFormDialog(form);
  }

  public openCreateDialog({
    data,
    afterClose,
    defaultObject,
  }: {
    data?: any;
    afterClose?: (result: { [key: string]: any }) => void;
    defaultObject?: { [key: string]: any };
  } = {}): void {
    if (data) this.data = this.cloneObject(data);

    this.beforeCreateOpen(data);

    if (this.createFormTemplate) {
      if (defaultObject)
        this.createFormTemplate.defaultObj = { ...this.cloneObject(defaultObject) };

      this.dialogFormService.openFormDialog(this.createFormTemplate, {
        options: { width: this.createFormTemplate?.width ?? '450px' },
        data: this.data,
        afterClose: (values: { [key: string]: any }) => {
          if (afterClose) afterClose(values);
        },
      });
    }
  }

  public openEditDialog(
    defaultObject: { [key: string]: any },
    {
      data,
      afterClose,
    }: {
      data?: any;
      afterClose?: (result: { [key: string]: any }) => void;
    } = {}
  ): void {
    if (data) this.data = this.cloneObject(data);

    this.beforeEditOpen(data);

    if (this.editFormTemplate) {
      if (defaultObject)
        this.editFormTemplate.defaultObj = { ...this.cloneObject(defaultObject) };

      this.dialogFormService.openFormDialog(this.editFormTemplate, {
        options: { width: this.editFormTemplate?.width ?? '450px' },
        data: this.data,
        afterClose: (values: { [key: string]: any }) => {
          if (afterClose) afterClose(values);
        },
      });
    }
  }
}
